/* tslint:disable */
/* eslint-disable */
/* @relayHash b8d9ec933f59acb9e46e0a0f3151aa35 */

import { ConcreteRequest } from "relay-runtime";
export type CheckpointEnum = "ACEITE_PROPOSTA" | "ACEITE_TERMOS" | "ACOMPANHAMENTO" | "ANALISE" | "CONTRATO_GERADO" | "DECLINADO" | "DOCUMENTOS" | "PROPOSTA_ESCOLHIDA" | "SOBRE_EMPRESA" | "SOBRE_SOCIOS";
export type InfoQueryVariables = {};
export type InfoQueryResponse = {
    readonly viewer: {
        readonly checkpoints: ReadonlyArray<CheckpointEnum | null> | null;
        readonly me: {
            readonly emailVerified: boolean | null;
        } | null;
        readonly myCompany: {
            readonly bank: {
                readonly account: string | null;
                readonly bankAgency: string | null;
                readonly bankName: string | null;
            } | null;
            readonly phone: {
                readonly fullNumber: string | null;
            } | null;
            readonly representatives: ReadonlyArray<{
                readonly id: string;
                readonly cpf: string;
                readonly name: string | null;
                readonly email: string | null;
            } | null> | null;
        } | null;
    } | null;
};
export type InfoQuery = {
    readonly response: InfoQueryResponse;
    readonly variables: InfoQueryVariables;
};



/*
query InfoQuery {
  viewer {
    checkpoints
    me {
      emailVerified
      id
    }
    myCompany {
      bank {
        account
        bankAgency
        bankName
      }
      phone {
        fullNumber
      }
      representatives {
        id
        cpf
        name
        email
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "checkpoints",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailVerified",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "bank",
  "storageKey": null,
  "args": null,
  "concreteType": "Bank",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "account",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "bankAgency",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "bankName",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "phone",
  "storageKey": null,
  "args": null,
  "concreteType": "Phone",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullNumber",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "representatives",
  "storageKey": null,
  "args": null,
  "concreteType": "Representative",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cpf",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "InfoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "myCompany",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "InfoQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v4/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "myCompany",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/)
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "InfoQuery",
    "id": null,
    "text": "query InfoQuery {\n  viewer {\n    checkpoints\n    me {\n      emailVerified\n      id\n    }\n    myCompany {\n      bank {\n        account\n        bankAgency\n        bankName\n      }\n      phone {\n        fullNumber\n      }\n      representatives {\n        id\n        cpf\n        name\n        email\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '2bb54038486c316a150dd943a5a6023b';
export default node;
