import React, { useMemo } from 'react';
import { formatToBRL } from 'brazilian-values';
import { useHistory } from 'react-router';
import { Text } from '@captalys-platform/core';
import { ROUTES_PATH } from '../../constants';
import { SidebarStepsItemComponent } from '../SidebarStepsItem';
import { getNumberValue, checkpointsIncludes } from '../../Helpers';

import { SidebarStyle } from './style';
import { SidebarFragmentProps } from './interface';

export const SidebarFragment = ({
  checkpoints,
  chosenOffer,
}: SidebarFragmentProps) => {
  const { push } = useHistory();
  const hasCheckpoint = checkpointsIncludes(checkpoints || []);

  const statusProps = {
    chosenOfferStatus: hasCheckpoint('PROPOSTA_ESCOLHIDA'),
    sobreAEmpresaStatus: hasCheckpoint('SOBRE_EMPRESA'),
  };

  const { chosenOfferStatus, sobreAEmpresaStatus } = statusProps;

  const getChosenOffer = useMemo(() => getNumberValue(chosenOffer), [
    chosenOffer,
  ]);

  const steps = {
    proposta: {
      completed: chosenOfferStatus,
      parcelaMensal: getChosenOffer('installmentValue'),
      valorTotal: getChosenOffer('amountDue'),
      quantidade: `${getChosenOffer('deadline')} meses`,
      juros: `${getChosenOffer('tax')}% ao mês`,
      cet: `${getChosenOffer('cet')}% ao mês`,
    },
    informacoes: {
      completed: sobreAEmpresaStatus,
    },
  };

  return (
    <SidebarStyle>
      <SidebarStepsItemComponent
        completed={chosenOfferStatus}
        onClick={() => push(ROUTES_PATH.CREDIT)}
        text="Proposta"
      >
        <SidebarProposalText
          text={`Parcela Mensal - ${formatToBRL(steps.proposta.parcelaMensal)}`}
        />
        <SidebarProposalText
          text={`Valor Total - ${formatToBRL(steps.proposta.valorTotal)}`}
        />
        <SidebarProposalText
          text={`Quantidade - ${steps.proposta.quantidade}`}
        />
        <SidebarProposalText text={`Juros - ${steps.proposta.juros}`} />
        <SidebarProposalText text={`CET - ${steps.proposta.cet}`} />
      </SidebarStepsItemComponent>

      <SidebarStepsItemComponent
        disabled={!chosenOfferStatus}
        completed={steps.informacoes.completed}
        onClick={() => push(ROUTES_PATH.INFO)}
        text="Informações"
      >
        {/* <Text
          component={
            steps.informacoes.sobreAEmpresa
              ? 'StrongTextStyle'
              : 'ItalicTextStyle'
          }
          color={steps.informacoes.sobreAEmpresa ? 'success' : '500'}
          text="Sobre a empresa"
        />
        <Text
          component={
            steps.informacoes.socio ? 'StrongTextStyle' : 'ItalicTextStyle'
          }
          color={steps.informacoes.socio ? 'success' : '500'}
          text="Sócio"
        /> */}
      </SidebarStepsItemComponent>
    </SidebarStyle>
  );
};

const SidebarProposalText = ({ text }: { text: string }) => (
  <Text color="500" component="StrongTextStyle" text={text} />
);
