import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';

import { Environment } from '../../Relay/enviroment';
import { DashboardComponent } from './component';
import { LoadingComponent } from '../../Components/Loading';

import { DashboardQuery } from '../../__generated__/DashboardQuery.graphql';

const dashboardQuery = graphql`
  query DashboardQuery {
    viewer {
      checkpoints
      operationStatus(integration: PAYPAL) {
        ...DashboardSidebar_operationStatus
        ...Table_operationStatus
      }
    }
  }
`;
export const DashboardPage = () => (
  <QueryRenderer<DashboardQuery>
    environment={Environment}
    fetchPolicy="store-and-network"
    variables={{}}
    query={dashboardQuery}
    render={({ error, props }) => {
      if (error) {
        return null;
      }

      if (!props || !props.viewer) return <LoadingComponent />;

      const operationStatus = props?.viewer?.operationStatus || null;
      const checkpoints = props?.viewer?.checkpoints || [];

      return (
        <DashboardComponent
          operationStatus={operationStatus}
          checkpoints={checkpoints}
        />
      );
    }}
  />
);
