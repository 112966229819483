import { graphql, createFragmentContainer } from 'react-relay';
import { DashboardSidebarFragment } from './component';

export const DashboardSidebarComponent = createFragmentContainer(
  DashboardSidebarFragment,
  {
    operationStatus: graphql`
      fragment DashboardSidebar_operationStatus on Operation {
        operationStartDate
        creditValue
        dueValue
        paidValue
        paidInstallments
        totalInstallments
      }
    `,
  },
);
