import { graphql } from 'relay-runtime';

import {
  VerifyOperationInput,
  VerifyOperationMutation,
} from '../../../__generated__/VerifyOperationMutation.graphql';
import { CommitMutation } from '../commitMutation';

const mutation = graphql`
  mutation VerifyOperationMutation($input: VerifyOperationInput!) {
    verifyOperation(input: $input) {
      disbursementStatus
      response {
        status
        statusCode
        ...Response_message
      }
    }
  }
`;

export const verifyOperation = (input: VerifyOperationInput) =>
  CommitMutation<VerifyOperationMutation>({
    mutation,
    variables: { input },
  });
