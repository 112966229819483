import styled, { css } from 'styled-components';
import {
  Paper,
  DefaultTextStyle,
  ColorsTypes,
  IconButton,
} from '@captalys-platform/core';

const toastDesktopCss = css`
  width: auto;
  min-width: 160px;
  right: 10px;
  bottom: 10px;
`;

const toastMobileCss = css`
  width: 98%;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
`;

export const CloseIconButtonStyle = styled(IconButton)`
  right: 3px;
  top: 3px;
  position: absolute;

  &:hover {
    background-color: transparent;
  }
`;

export const ToastStyle = styled(Paper)<{
  color?: ColorsTypes;
  isMobile: boolean;
}>`
  justify-content: flex-start;
  z-index: 100;
  position: fixed;
  ${({ isMobile }) => (isMobile ? toastMobileCss : toastDesktopCss)};
  min-height: 60px;

  background-color: ${({ theme, color }) => theme.colors[color]};

  ${DefaultTextStyle} {
    color: ${({ theme }) => theme.colors.white};
  }
`;

ToastStyle.defaultProps = {
  color: 'success',
};
