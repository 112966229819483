import React from 'react';
import { Table } from '@captalys-platform/core';

import { TableProps } from './interface';
import { TableHeaderProps } from './props';

export const TableComponent = ({ operationStatus }: TableProps) => (
  <Table
    header={TableHeaderProps}
    dataSource={operationStatus && operationStatus.installments}
  />
);
