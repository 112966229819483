import { CheckpointEnum } from '../__generated__/WelcomeQuery.graphql';

const getObjectValue = <K>(defaultValue: K) => <
  T extends Record<string, unknown>
>(
  obj: T,
) => (key: keyof T) => {
  if (obj === undefined || obj === null) return defaultValue;

  if (obj[key] === undefined || obj[key] === null) return defaultValue;

  return obj[key];
};

export const checkpointsIncludes = (
  checkpoints: readonly CheckpointEnum[] = [],
) => (checkpoint: CheckpointEnum) => checkpoints.includes(checkpoint);

export function setItemOnSessionStorage(key: string, value: unknown) {
  const stringfiedValue = JSON.stringify(value);

  if (!stringfiedValue) return;

  sessionStorage.setItem(key, stringfiedValue);
}

/*
  It's already parse
*/
export function getItemOnSessionStorage<T>(key: string): T | null {
  const item = sessionStorage.getItem(key);

  if (item) {
    return JSON.parse(item) as T;
  }

  return null;
}

export const getStringValue = getObjectValue('');
export const getNumberValue = getObjectValue(0);
