import { formatToPhone } from 'brazilian-values';
import { InfoQueryResponse } from '../../__generated__/InfoQuery.graphql';

export function getRepresentativeData(
  myCompany: InfoQueryResponse['viewer']['myCompany'],
) {
  const representative = myCompany.representatives[0]
    ? myCompany.representatives[0]
    : {
        cpf: '',
        email: '',
        id: '',
        name: '',
      };

  const name = representative.name || '';
  const cpf = representative.cpf || '';
  const cellphone =
    myCompany.phone && formatToPhone(myCompany.phone.fullNumber || '');
  const email = representative.email || '';
  const bankInfo = myCompany.bank
    ? {
        agency: myCompany.bank.bankAgency,
        bankName: myCompany.bank.bankName,
        account: myCompany.bank.account,
      }
    : {
        agency: '',
        bankName: '',
        account: '',
      };

  return { name, cpf, cellphone, email, bankInfo };
}
