/* tslint:disable */
/* eslint-disable */
/* @relayHash 9303a2dc4d663905d7432b9f2c781b89 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CheckpointEnum = "ACEITE_PROPOSTA" | "ACEITE_TERMOS" | "ACOMPANHAMENTO" | "ANALISE" | "CONTRATO_GERADO" | "DECLINADO" | "DOCUMENTOS" | "PROPOSTA_ESCOLHIDA" | "SOBRE_EMPRESA" | "SOBRE_SOCIOS";
export type DashboardQueryVariables = {};
export type DashboardQueryResponse = {
    readonly viewer: {
        readonly checkpoints: ReadonlyArray<CheckpointEnum | null> | null;
        readonly operationStatus: {
            readonly " $fragmentRefs": FragmentRefs<"DashboardSidebar_operationStatus" | "Table_operationStatus">;
        } | null;
    } | null;
};
export type DashboardQuery = {
    readonly response: DashboardQueryResponse;
    readonly variables: DashboardQueryVariables;
};



/*
query DashboardQuery {
  viewer {
    checkpoints
    operationStatus(integration: PAYPAL) {
      ...DashboardSidebar_operationStatus
      ...Table_operationStatus
    }
    id
  }
}

fragment DashboardSidebar_operationStatus on Operation {
  operationStartDate
  creditValue
  dueValue
  paidValue
  paidInstallments
  totalInstallments
}

fragment Table_operationStatus on Operation {
  installments {
    index
    paymentStatus
    paymentDate
    installmentValue
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "checkpoints",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "integration",
    "value": "PAYPAL"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "operationStatus",
            "storageKey": "operationStatus(integration:\"PAYPAL\")",
            "args": (v1/*: any*/),
            "concreteType": "Operation",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "DashboardSidebar_operationStatus",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "Table_operationStatus",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "operationStatus",
            "storageKey": "operationStatus(integration:\"PAYPAL\")",
            "args": (v1/*: any*/),
            "concreteType": "Operation",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "operationStartDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "creditValue",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dueValue",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "paidValue",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "paidInstallments",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalInstallments",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "installments",
                "storageKey": null,
                "args": null,
                "concreteType": "Installment",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "index",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "paymentStatus",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "paymentDate",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "installmentValue",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DashboardQuery",
    "id": null,
    "text": "query DashboardQuery {\n  viewer {\n    checkpoints\n    operationStatus(integration: PAYPAL) {\n      ...DashboardSidebar_operationStatus\n      ...Table_operationStatus\n    }\n    id\n  }\n}\n\nfragment DashboardSidebar_operationStatus on Operation {\n  operationStartDate\n  creditValue\n  dueValue\n  paidValue\n  paidInstallments\n  totalInstallments\n}\n\nfragment Table_operationStatus on Operation {\n  installments {\n    index\n    paymentStatus\n    paymentDate\n    installmentValue\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '6aa892c54327810fc29cc97e2dc7aa01';
export default node;
