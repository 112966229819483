import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Text, Flex, createBreakpoints } from '@captalys-platform/core';

import { ThankYouQueryResponse } from '../../__generated__/ThankYouQuery.graphql';
import { DisbursementStatusEnum } from '../../__generated__/VerifyOperationMutation.graphql';
import { OrderCards } from '../../Components/OrderCards/component';
import { useAccessFlow, useWindowWidth } from '../../Utils';
import { verifyOperation } from '../../Relay/Mutations/VerifyOperation';
import { MutationID } from '../../Relay/enviroment';
import { ROUTES_PATH } from '../../constants';

export const ThankYouComponent = ({
  chosenOffer,
  checkpoints,
  paymentMethod,
}: ThankYouQueryResponse['viewer']) => {
  useAccessFlow(checkpoints);
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const { push } = useHistory();

  const disbursementActions: Record<
    DisbursementStatusEnum,
    () => void | null
  > = {
    ACCOMPLISHED: () => push(ROUTES_PATH.STATEMENT),
    REFUSED: () => push(ROUTES_PATH.CREDIT),
    AUTHORIZED: null,
  };

  const verifyOperations = async () => {
    const response = await verifyOperation({
      clientMutationId: MutationID,
    });

    const disbursementStatus = response?.verifyOperation?.disbursementStatus;
    const action = disbursementActions[disbursementStatus];

    action && action();
  };

  useEffect(() => {
    verifyOperations();
  }, []);

  return (
    <Flex width="100%" container gap={16}>
      <Text component="H2TextStyle" text="Obrigado." />
      <Text
        text="Agora que você já assinou o contrato, vamos avaliar suas informações. Em até 72 horas você receberá um e-mail informando se a sua contratação foi efetuada."
        component="DefaultTextStyle"
        width={isMobile ? '100%' : '50%'}
      />
      <Flex direction="column" gap={30} width="100%">
        <OrderCards
          chosenOffer={chosenOffer}
          selectedPaymentMethod={paymentMethod.method}
        />
      </Flex>
    </Flex>
  );
};
