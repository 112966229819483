import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  ConfirmOfferInput,
  ConfirmOfferMutation,
} from '../../../__generated__/ConfirmOfferMutation.graphql';

const mutation = graphql`
  mutation ConfirmOfferMutation($input: ConfirmOfferInput!) {
    confirmOffer(input: $input) {
      clientMutationId
      offer {
        id
      }
      response {
        status
        ...Response_message
      }
    }
  }
`;

export const confirmOffer = async (input: ConfirmOfferInput) =>
  CommitMutation<ConfirmOfferMutation>({
    mutation,
    variables: { input },
    updater: (store, data) => {
      const offer = store.get(data.confirmOffer.offer.id);

      const chosenOffer = store
        .getRoot()
        .getLinkedRecord('viewer')
        .getLinkedRecord('chosenOffer');

      if (!offer) {
        return;
      }

      if (chosenOffer.getDataID() !== offer.getDataID()) {
        chosenOffer.setValue(offer.getValue('dailyCost'), 'dailyCost');
        chosenOffer.setValue(offer.getValue('amountDue'), 'amountDue');
        chosenOffer.setValue(offer.getValue('deadline'), 'deadline');
      }
    },
  });
