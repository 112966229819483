/* tslint:disable */
/* eslint-disable */
/* @relayHash 2c518625555aa260b8f7bc3d59e0a3ee */

import { ConcreteRequest } from "relay-runtime";
export type CheckpointEnum = "ACEITE_PROPOSTA" | "ACEITE_TERMOS" | "ACOMPANHAMENTO" | "ANALISE" | "CONTRATO_GERADO" | "DECLINADO" | "DOCUMENTOS" | "PROPOSTA_ESCOLHIDA" | "SOBRE_EMPRESA" | "SOBRE_SOCIOS";
export type StatusQueryVariables = {};
export type StatusQueryResponse = {
    readonly viewer: {
        readonly checkpoints: ReadonlyArray<CheckpointEnum | null> | null;
        readonly chosenOffer: {
            readonly id: string;
            readonly amountDue: number | null;
            readonly deadline: number | null;
            readonly tax: number | null;
            readonly cet: number | null;
            readonly installmentValue: number | null;
        } | null;
        readonly me: {
            readonly name: string | null;
            readonly emailVerified: boolean | null;
        } | null;
    } | null;
};
export type StatusQuery = {
    readonly response: StatusQueryResponse;
    readonly variables: StatusQueryVariables;
};



/*
query StatusQuery {
  viewer {
    checkpoints
    chosenOffer {
      id
      amountDue
      deadline
      tax
      cet
      installmentValue
    }
    me {
      name
      emailVerified
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "checkpoints",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "chosenOffer",
  "storageKey": null,
  "args": null,
  "concreteType": "Offer",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amountDue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deadline",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tax",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cet",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "installmentValue",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailVerified",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StatusQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StatusQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v1/*: any*/)
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StatusQuery",
    "id": null,
    "text": "query StatusQuery {\n  viewer {\n    checkpoints\n    chosenOffer {\n      id\n      amountDue\n      deadline\n      tax\n      cet\n      installmentValue\n    }\n    me {\n      name\n      emailVerified\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '61cee156dabc26e75e91e1cd885e2b7a';
export default node;
