import { graphql, createFragmentContainer } from 'react-relay';

import { TableComponent } from './component';

export * from './interface';
export * from './props';

export const TableFragment = createFragmentContainer(TableComponent, {
  operationStatus: graphql`
    fragment Table_operationStatus on Operation {
      installments {
        index
        paymentStatus
        paymentDate
        installmentValue
      }
    }
  `,
});
