import { DefaultTextInterface } from '@captalys-platform/core';

export const errorProps = {
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '500px',
  width: '100%',
  height: '100%',
};

export const errorTitleProps: DefaultTextInterface = {
  component: 'H2TextStyle',
  color: '800',
};

export const errorSubtitleProps: DefaultTextInterface = {
  component: 'ParagraphTextStyle',
  align: 'center',
  color: '600',
};

export const errorPaperProps = {
  gap: 16,
  margin: '16px',
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '400px',
  shadow: true,
};
