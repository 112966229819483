import { graphql } from 'relay-runtime';
import {
  IntegratedLoginInput,
  LoginMutation,
} from '../../../__generated__/LoginMutation.graphql';
import { CommitMutation } from '../commitMutation';

const mutation = graphql`
  mutation LoginMutation($input: IntegratedLoginInput!) {
    integratedLogin(input: $input) {
      user {
        sessionToken
        refreshToken
        apiToken
        email
        id
        internalId
        name
      }
      response {
        status
        statusCode
        ...Response_message
      }
    }
  }
`;

export const login = (input: IntegratedLoginInput) =>
  CommitMutation<LoginMutation>({
    mutation,
    variables: { input },
  });
