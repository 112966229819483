/* tslint:disable */
/* eslint-disable */
/* @relayHash d83a71c844c81bc98bf793825ddebb33 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConfirmDataInput = {
    clientMutationId: string;
};
export type ConfirmDataMutationVariables = {
    input: ConfirmDataInput;
};
export type ConfirmDataMutationResponse = {
    readonly confirmData: {
        readonly valid: boolean | null;
        readonly response: {
            readonly status: boolean;
            readonly " $fragmentRefs": FragmentRefs<"Response_message">;
        } | null;
        readonly clientMutationId: string;
    } | null;
};
export type ConfirmDataMutation = {
    readonly response: ConfirmDataMutationResponse;
    readonly variables: ConfirmDataMutationVariables;
};



/*
mutation ConfirmDataMutation(
  $input: ConfirmDataInput!
) {
  confirmData(input: $input) {
    valid
    response {
      status
      ...Response_message
    }
    clientMutationId
  }
}

fragment Response_message on Response {
  status
  statusCode
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ConfirmDataInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "valid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ConfirmDataMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "confirmData",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmDataPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "Response_message",
                "args": null
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ConfirmDataMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "confirmData",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmDataPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "statusCode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ConfirmDataMutation",
    "id": null,
    "text": "mutation ConfirmDataMutation(\n  $input: ConfirmDataInput!\n) {\n  confirmData(input: $input) {\n    valid\n    response {\n      status\n      ...Response_message\n    }\n    clientMutationId\n  }\n}\n\nfragment Response_message on Response {\n  status\n  statusCode\n  message\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'e5b6326d63e0adf810f6748da900402c';
export default node;
