import React from 'react';
import { Text, Flex } from '@captalys-platform/core';
import { useHistory } from 'react-router';
import {
  StatusQueryResponse,
  CheckpointEnum,
} from '../../__generated__/StatusQuery.graphql';
import { ROUTES_PATH } from '../../constants';
import { ButtonStyle } from '../Credit';
import { CreditOfferItem } from '../../Components/CreditOfferItem';
import { useAccessFlow } from '../../Utils';
import { checkpointsIncludes } from '../../Helpers';

const getSteps = (
  checkpoints: Readonly<CheckpointEnum[]>,
  isEmailVerified: boolean,
) => {
  let numberOfSteps = 2;

  const includesOnCheckpoints = checkpointsIncludes(checkpoints);

  if (includesOnCheckpoints('SOBRE_EMPRESA') && isEmailVerified)
    numberOfSteps -= 1;

  return numberOfSteps;
};

export const StatusFragment = ({
  me: { name, emailVerified },
  checkpoints,
  chosenOffer,
}: StatusQueryResponse['viewer']) => {
  const { push } = useHistory();
  const steps = getSteps(checkpoints, emailVerified);

  useAccessFlow(checkpoints);

  const userName = name ? `${name.split(' ')[0]} ${name.split(' ').pop()}` : '';

  return (
    <Flex
      alignItems="center"
      width="100%"
      grow={2}
      padding="0 32px"
      justifyContent="space-between"
    >
      <Flex direction="column" alignItems="center" height="100%">
        <Flex as="hgroup" gap={4} alignItems="baseline" margin="32px 0 0 0">
          <Text component="H3TextStyle" text={`${userName},`} />
          <Text component="DefaultTextStyle">
            Que bom que gostou da proposta! Agora&nbsp;
            {steps === 1 ? 'falta' : 'faltam'}
            &nbsp;apenas mais&nbsp;
            <Text
              text={`${steps} ${steps === 1 ? 'etapa' : 'etapas'}`}
              component="StrongTextStyle"
            />
          </Text>
        </Flex>

        <Flex height="100%" alignItems="center" justifyContent="center">
          <CreditOfferItem
            offer={chosenOffer}
            showCheckIcon
            showLinkToCreditPage
          />
        </Flex>
      </Flex>

      <Flex>
        <ButtonStyle
          text="Continuar"
          color="primary"
          onClick={() => {
            push(ROUTES_PATH[steps === 1 ? 'ORDER_REVIEW' : 'INFO']);
          }}
        />
      </Flex>
    </Flex>
  );
};
