import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import { Redirect } from 'react-router';
import { Environment } from '../../Relay/enviroment';
import { LoadingComponent } from '../../Components/Loading';
import { ErrorComponent } from '../../Components/Error';
import { WelcomePageView } from './component';
import { WelcomeQuery } from '../../__generated__/WelcomeQuery.graphql';
import { ROUTES_PATH } from '../../constants';

export * from './style';
export * from './interface';

const welcomeQuery = graphql`
  query WelcomeQuery {
    viewer {
      checkpoints
      me {
        name
        email
      }
    }
  }
`;

export const WelcomePage = () => {
  return (
    <QueryRenderer<WelcomeQuery>
      environment={Environment}
      fetchPolicy="store-and-network"
      query={welcomeQuery}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <ErrorComponent error={error} />;
        }

        if (!props || !props.viewer) {
          return <LoadingComponent dark />;
        }

        const checkpoints = props.viewer ? props.viewer.checkpoints : [];
        const me = props.viewer ? props.viewer.me : null;

        if (checkpoints === null) return <Redirect to={ROUTES_PATH.ERROR} />;

        return <WelcomePageView checkpoints={checkpoints || []} me={me} />;
      }}
    />
  );
};
