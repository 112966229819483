import React from 'react';
import { Text, Paper } from '@captalys-platform/core';
import {
  feedbackSubtitleProps,
  feedbackTitleProps,
  feedbackPaperProps,
} from './props';
import { FeedbackInterface } from './interface';
import { FeedbackStyle } from './style';

export const FeedbackComponent = ({
  title,
  subtitle,
  children,
  dark,
}: FeedbackInterface) => (
  <FeedbackStyle dark={dark}>
    <Paper {...feedbackPaperProps}>
      {title && <Text {...feedbackTitleProps} text={title} />}
      {subtitle && <Text {...feedbackSubtitleProps} text={subtitle} />}
      {children}
    </Paper>
  </FeedbackStyle>
);
