import React from 'react';
import {
  createBreakpoints,
  Flex,
  Link,
  ArrowLeftIcon,
  Text,
  Paper,
  CloseIcon,
} from '@captalys-platform/core';

import { useHistory } from 'react-router';
import { useWindowWidth } from '../../Utils';
import { ROUTES_PATH } from '../../constants';
import { CloseLinkStyle } from '../SmsTokenConfirmation/style';

export const InfoIncorrect = () => {
  const {
    location: { state },
  } = useHistory<{ text: string }>();
  const isDesktop = useWindowWidth() > createBreakpoints().values.md;

  const textFeedback = state
    ? state.text
    : 'Caso os seus dados estejam desatualizados, por favor, ligue na nossa Central para atualizá-los:';

  const phoneLocationCapital = '4004-4935 (capitais e metrópoles)';
  const phoneLocationInland = '0800 726 4935 (demais localidades)';

  if (isDesktop)
    return (
      <Flex
        margin="0 0 32px 0"
        direction="row"
        alignItems="baseline"
        justifyContent={isDesktop ? 'flex-start' : 'center'}
        gap={12}
      >
        <Link color="blue" to={ROUTES_PATH.INFO}>
          <ArrowLeftIcon width={14} height={14} fill="blue" />
        </Link>
        <Flex direction="column">
          <Text
            margin="0 0 20px 0"
            component="H4TextStyle"
            text={textFeedback}
          />
          <Text
            margin="0 0 6px 0"
            component="H4TextStyle"
            text={phoneLocationCapital}
          />
          <Text component="H4TextStyle" text={phoneLocationInland} />
        </Flex>
      </Flex>
    );

  return (
    <Paper
      shadow={!isDesktop}
      position="relative"
      padding="32px"
      height="100%"
      justifyContent={!isDesktop ? 'space-between' : 'flex-start'}
    >
      <CloseLinkStyle to={ROUTES_PATH.INFO}>
        <CloseIcon width={18} height={18} fill="500" />
      </CloseLinkStyle>
      <Flex margin="20px" direction="column">
        <Text margin="0 0 20px 0" component="H5TextStyle" text={textFeedback} />
        <Text
          margin="0 0 6px 0"
          component="H4TextStyle"
          text={phoneLocationCapital}
        />
        <Text component="H4TextStyle" text={phoneLocationInland} />
      </Flex>
    </Paper>
  );
};
