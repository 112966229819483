import React from 'react';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'relay-runtime';
import { Environment } from '../../Relay/enviroment';
import { ErrorComponent } from '../../Components/Error';
import { LoadingComponent } from '../../Components/Loading';

import { StatementQuery } from '../../__generated__/StatementQuery.graphql';

import { StatementComponent } from './component';

const statementQuery = graphql`
  query StatementQuery {
    viewer {
      checkpoints
      saOperation {
        futureValue
        balanceDue
        installmentsTotalCount
        amountPaid
        installmentsPaidCount
        installments {
          edges {
            node {
              id
              closed
              dueDate
              status
              paymentStatus
              pmt
              nominalValue
            }
          }
        }
      }
    }
  }
`;

export const StatementPage = () => {
  return (
    <QueryRenderer<StatementQuery>
      environment={Environment}
      fetchPolicy="store-and-network"
      variables={{}}
      query={statementQuery}
      render={({ props, error }) => {
        if (error) return <ErrorComponent error={error} />;

        if (!props || !props.viewer) return <LoadingComponent />;

        const checkpoints = props?.viewer?.checkpoints || [];
        const saOperation = props?.viewer?.saOperation || null;

        return (
          <StatementComponent
            saOperation={saOperation}
            checkpoints={checkpoints}
          />
        );
      }}
    />
  );
};
