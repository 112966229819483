import { useHistory } from 'react-router';
import { useEffect } from 'react';

import { checkpointsIncludes } from '../Helpers';
import { ROUTES_PATH } from '../constants';
import { CheckpointEnum } from '../__generated__/CreditQuery.graphql';

type RedirectsRulesType = Partial<
  Record<CheckpointEnum, Array<keyof typeof ROUTES_PATH>>
>;

export const useAccessFlow = (checkpoints: Readonly<CheckpointEnum[]>) => {
  const { push, location } = useHistory();

  const redirectsRules: RedirectsRulesType = {
    ACEITE_TERMOS: ['CREDIT'],
    PROPOSTA_ESCOLHIDA: ['STATUS', 'INFO', 'INFO_INCORRECT', 'CREDIT'],
    SOBRE_EMPRESA: ['STATUS', 'ORDER_REVIEW', 'CREDIT', 'INFO'],
    ACEITE_PROPOSTA: [
      'STATUS',
      'ORDER_REVIEW',
      'ORDER_REVIEW_SMS',
      'CREDIT',
      'INFO',
    ],
    ANALISE: ['THANK_YOU', 'STATEMENT'],
    ACOMPANHAMENTO: ['STATEMENT'],
    DECLINADO: ['DECLINED'],
  };

  useEffect(() => {
    if (checkpoints.length === 0) {
      push(ROUTES_PATH.WELCOME);
      return;
    }

    const lastCheckpoint = checkpoints.slice(-1)[0];
    const isDone = checkpointsIncludes(checkpoints);

    let possibleRoutes = [];
    if (isDone('ANALISE') || isDone('ACOMPANHAMENTO') || isDone('DECLINADO')) {
      possibleRoutes = redirectsRules[lastCheckpoint];
    } else if (isDone('SOBRE_EMPRESA')) {
      possibleRoutes = redirectsRules.SOBRE_EMPRESA;
    } else {
      possibleRoutes = redirectsRules[lastCheckpoint];
    }

    if (!possibleRoutes) return;

    const isInAllowedRoute = possibleRoutes.some(routeName => {
      const strictRoute = ROUTES_PATH[routeName];

      return strictRoute === location.pathname;
    });

    if (isInAllowedRoute) return;

    push(ROUTES_PATH[possibleRoutes[0]]);
  }, []);
};
