/* tslint:disable */
/* eslint-disable */
/* @relayHash 4cffdfb251e522d6bdaf32ff1f88baa4 */

import { ConcreteRequest } from "relay-runtime";
export type CheckpointEnum = "ACEITE_PROPOSTA" | "ACEITE_TERMOS" | "ACOMPANHAMENTO" | "ANALISE" | "CONTRATO_GERADO" | "DECLINADO" | "DOCUMENTOS" | "PROPOSTA_ESCOLHIDA" | "SOBRE_EMPRESA" | "SOBRE_SOCIOS";
export type CreditChooseInstallmentsQueryVariables = {
    chosenVolume?: number | null;
};
export type CreditChooseInstallmentsQueryResponse = {
    readonly viewer: {
        readonly checkpoints: ReadonlyArray<CheckpointEnum | null> | null;
        readonly simulation: {
            readonly offers: ReadonlyArray<{
                readonly id: string;
                readonly amountDue: number | null;
                readonly deadline: number | null;
                readonly tax: number | null;
                readonly cet: number | null;
                readonly installmentValue: number | null;
            } | null> | null;
        } | null;
    } | null;
};
export type CreditChooseInstallmentsQuery = {
    readonly response: CreditChooseInstallmentsQueryResponse;
    readonly variables: CreditChooseInstallmentsQueryVariables;
};



/*
query CreditChooseInstallmentsQuery(
  $chosenVolume: Int
) {
  viewer {
    checkpoints
    simulation {
      offers(chosenVolume: $chosenVolume) {
        id
        amountDue
        deadline
        tax
        cet
        installmentValue
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "chosenVolume",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "checkpoints",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "simulation",
  "storageKey": null,
  "args": null,
  "concreteType": "Simulation",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "offers",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "chosenVolume",
          "variableName": "chosenVolume"
        }
      ],
      "concreteType": "Offer",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "amountDue",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "deadline",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "tax",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cet",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "installmentValue",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreditChooseInstallmentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreditChooseInstallmentsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CreditChooseInstallmentsQuery",
    "id": null,
    "text": "query CreditChooseInstallmentsQuery(\n  $chosenVolume: Int\n) {\n  viewer {\n    checkpoints\n    simulation {\n      offers(chosenVolume: $chosenVolume) {\n        id\n        amountDue\n        deadline\n        tax\n        cet\n        installmentValue\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'c61cf1ec54c5bbc422ba123cb8f2a391';
export default node;
