import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  RepresentativeDeleteInput,
  RepresentativeDeleteMutation,
} from '../../../__generated__/RepresentativeDeleteMutation.graphql';

const mutation = graphql`
  mutation RepresentativeDeleteMutation($input: RepresentativeDeleteInput!) {
    representativeDelete(input: $input) {
      clientMutationId
      response {
        status
        ...Response_message
      }
    }
  }
`;

export const representativeDelete = async (input: RepresentativeDeleteInput) =>
  CommitMutation<RepresentativeDeleteMutation>({
    mutation,
    variables: { input },
  });
