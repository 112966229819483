import React from 'react';
import { Grid, Flex, createBreakpoints } from '@captalys-platform/core';

import { TableFragment } from '../../Components/Table';
import { DashboardSidebarComponent } from '../../Components/DashboardSidebar';
import { DashboardQueryResponse } from '../../__generated__/DashboardQuery.graphql';
import { useWindowWidth, useAccessFlow } from '../../Utils';
import { PageGridProps } from './props';

export const DashboardComponent = ({
  checkpoints,
  operationStatus,
}: DashboardQueryResponse['viewer']) => {
  useAccessFlow(checkpoints);
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;

  return (
    <Flex alignItems={isMobile && 'center'} width="100%">
      <Grid medias={PageGridProps.medias}>
        <DashboardSidebarComponent operationStatus={operationStatus} />
        <Flex area="table" width="100%">
          <TableFragment operationStatus={operationStatus} />
        </Flex>
      </Grid>
    </Flex>
  );
};
