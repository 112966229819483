import React, { useState, useEffect } from 'react';
import { formatToBRL } from 'brazilian-values';
import { useHistory } from 'react-router';
import { Link, Text, Flex } from '@captalys-platform/core';

import { checkpointsIncludes } from '../../Helpers';
import { ArrowLeftIcon } from '../../Icons';
import { ROUTES_PATH } from '../../constants';
import { ButtonStyle } from '../Credit/style';
import { Slider } from '../../Components/Slider';
import { CreditOfferItem } from '../../Components/CreditOfferItem';
import {
  CreditChooseInstallmentsComponentProps,
  OffersType,
} from './interface';
import { confirmOffer } from '../../Relay/Mutations/Credit/ConfirmOffer';
import { saveCheckpoint } from '../../Relay/Mutations/Checkpoints';
import { MutationID } from '../../Relay/enviroment';

const getOfferByDeadline = (offers: OffersType) => (
  chosenInstallments: number,
) => {
  if (offers.length === 1) return offers[0];

  return offers.find(offer => offer.deadline === chosenInstallments);
};

export const CreditChooseInstallmentsComponent = ({
  chosenVolume,
  minInstallments,
  maxInstallments,
  checkpoints,
  offers,
}: CreditChooseInstallmentsComponentProps) => {
  const isDone = checkpointsIncludes(checkpoints);
  const getOffer = getOfferByDeadline(offers);

  const { push } = useHistory();
  const [chosenInstallments, setChosenInstallments] = useState(
    Math.round((minInstallments + maxInstallments) / 2),
  );
  const [chosenOffer, setChosenOffer] = useState(getOffer(chosenInstallments));
  const [isConfirmingOffer, toggleIsConfirmingOffer] = useState(false);

  const onlyOneOffer = offers.length === 1;

  const handleSubmit = async () => {
    try {
      toggleIsConfirmingOffer(true);

      await confirmOffer({
        clientMutationId: MutationID,
        id: chosenOffer.id,
      });

      await saveCheckpoint({
        clientMutationId: MutationID,
        checkpoint: 'PROPOSTA_ESCOLHIDA',
      });

      push(ROUTES_PATH.STATUS);
    } catch (e) {
      toggleIsConfirmingOffer(false);
      console.log(e);
    }
  };

  useEffect(() => {
    const newChosenOffer = getOffer(chosenInstallments);
    if (newChosenOffer) setChosenOffer(newChosenOffer);
  }, [chosenInstallments]);

  return (
    <Flex height="100%" direction="column" justifyContent="space-between">
      <Flex>
        <Flex direction="row" width="100%">
          <Link to={ROUTES_PATH.CREDIT}>
            <ArrowLeftIcon
              style={{ margin: '8px 8px' }}
              width={15}
              height={15}
              fill="blue"
            />
          </Link>
          <Flex direction="column" margin="0 0 0 15px">
            <Text
              component="LabelTextStyle"
              text="Valor do Crédito"
              color="black"
            />
            <Text component="H2TextStyle" text={formatToBRL(chosenVolume)} />
          </Flex>
        </Flex>

        {!onlyOneOffer && (
          <Flex alignItems="center" margin="">
            <Text
              align="center"
              component="H4TextStyle"
              text="Número de Parcelas"
              margin="30px 0"
            />

            <Slider
              step={1}
              minVolume={minInstallments}
              maxVolume={maxInstallments}
              starterValue={chosenInstallments}
              onChangeRangeEnd={setChosenInstallments}
            />
          </Flex>
        )}

        <Flex alignItems="center">
          <CreditOfferItem offer={chosenOffer} />
        </Flex>
      </Flex>
      <Flex alignItems="center" gap={16}>
        <ButtonStyle
          color="primary"
          onClick={handleSubmit}
          disabled={isConfirmingOffer}
          text={isConfirmingOffer ? 'Confirmando...' : 'Seguir com proposta'}
        />
      </Flex>
    </Flex>
  );
};
