import React from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Flex } from '@captalys-platform/core';

import { contentRoutesProps } from './props';
import { AppRoutesStyle, MainRoutesStyle } from './style';
import { PrivateComponentRoutesType } from './interface';

import { ROUTES } from '../../routes';
import { NotFoundPage } from '../../Pages/NotFound';
import { TopbarComponent } from '../Topbar';
import { FooterComponent } from '../Footer';
import { SidebarComponent } from '../Sidebar';
import { HelmetComponent } from '../Helmet';
import {
  ROUTES_PATH,
  USER_REFRESH_TOKEN,
  USER_SESSION_TOKEN,
} from '../../constants';
import { ErrorBoundary } from '../ErrorBoundary';

export const Routes = () => {
  const PrivateRouteComponent = ({
    component: Component,
    sidebar,
    ...props
  }: PrivateComponentRoutesType) => {
    const { push } = useHistory();

    if (!window.localStorage.getItem(USER_SESSION_TOKEN)) {
      window.localStorage.removeItem(USER_REFRESH_TOKEN);

      push(ROUTES_PATH.ERROR);

      return null;
    }
    return (
      <>
        {sidebar && <SidebarComponent />}
        <MainRoutesStyle>
          <Component {...props} />
        </MainRoutesStyle>
      </>
    );
  };

  /**
   * Map over routes config and render Components
   */
  const RenderRoutes = () => {
    const routesContent = () => {
      return (
        <Switch>
          {ROUTES.map(
            ({ component: RouteComponent, requireAuth, sidebar, ...props }) => (
              <Route
                key={props.title}
                {...props}
                render={matchProps => {
                  const Component = !requireAuth
                    ? RouteComponent
                    : PrivateRouteComponent;
                  return (
                    <>
                      <HelmetComponent {...props} />
                      <Component
                        {...matchProps}
                        component={RouteComponent}
                        sidebar={sidebar}
                      />
                    </>
                  );
                }}
              />
            ),
          )}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      );
    };

    return <Flex {...contentRoutesProps}>{routesContent()}</Flex>;
  };

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Route
          path="*"
          render={({ location: { pathname } }) => (
            <AppRoutesStyle dark={false}>
              <Route
                path="/"
                render={() => (
                  <TopbarComponent
                    hideBars={pathname === ROUTES_PATH.ROOT}
                    authenticated={pathname !== ROUTES_PATH.ROOT}
                  />
                )}
              />
              <Route path="*" render={() => <RenderRoutes />} />
              <Route path="/" render={() => <FooterComponent />} />
            </AppRoutesStyle>
          )}
        />
      </ErrorBoundary>
    </BrowserRouter>
  );
};
