import React, { useState, useEffect } from 'react';
import { Flex } from '@captalys-platform/core';

import { InputConfirmationCodeInterface } from './interface';
import { InputTextStyle } from './style';

const removeNonNumbersRegex = /\D/g;

export const InputConfirmationCode = ({
  onCodeChange,
}: InputConfirmationCodeInterface) => {
  const [codes, setCode] = useState(['', '', '', '', '', '']);

  function focusNextInput(ipt: HTMLInputElement) {
    let sibling = ipt.nextElementSibling as HTMLInputElement;

    // Find next sibling with empty value to focus
    while (sibling && sibling.value !== '') {
      sibling = sibling.nextElementSibling as HTMLInputElement;
    }

    if (sibling) {
      sibling.focus();
    }
  }

  function focusPreviousInput(ipt: HTMLInputElement) {
    const sibling = ipt.previousElementSibling as HTMLInputElement;

    if (sibling) {
      sibling.focus();
    }
  }

  function setCodeByIndex(index: number, value: string) {
    const shallowCode = [...codes];

    shallowCode[index] = value;

    setCode(shallowCode);
  }

  function handleCodeChange(
    ipt: EventTarget & HTMLInputElement,
    index: number,
  ) {
    const codeValue = ipt.value.replace(removeNonNumbersRegex, '');

    setCodeByIndex(index, codeValue);

    if (codeValue) {
      focusNextInput(ipt);
    }
  }

  function handleBackspaceKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) {
    if (e.key !== 'Backspace' || index === 0) return;

    const ipt = e.target as HTMLInputElement;

    if (ipt.value.length === 0) {
      setCodeByIndex(index - 1, '');
      focusPreviousInput(ipt);
    }
  }

  function handleOnPaste(
    e: React.ClipboardEvent<HTMLInputElement>,
    index: number,
  ) {
    const pastedValue = e.clipboardData
      .getData('Text')
      .replace(removeNonNumbersRegex, '');

    const shallowCode = [...codes];
    pastedValue.split('').forEach((char, charIndex) => {
      const desiredIndex = charIndex + index;

      if (desiredIndex > codes.length - 1) return;

      shallowCode[desiredIndex] = char;
    });
    setCode(shallowCode);
  }

  useEffect(() => {
    if (onCodeChange) {
      onCodeChange(codes.join(''));
    }
  }, [onCodeChange, codes]);

  return (
    <>
      <Flex gap={12} direction="row">
        {codes.map((code, index) => (
          <InputTextStyle
            key={String(index)}
            onChange={e => handleCodeChange(e.target, index)}
            onKeyDown={e => handleBackspaceKeyDown(e, index)}
            onPaste={e => handleOnPaste(e, index)}
            value={code}
            maxLength={1}
            placeholder="0"
            type="tel"
          />
        ))}
      </Flex>
    </>
  );
};
