import React, { useState } from 'react';

import { Text, Flex, createBreakpoints } from '@captalys-platform/core';

import { formatToBRL } from 'brazilian-values';

import { SelectStyle } from './style';
import { useWindowWidth } from '../../Utils';

import { StatementListInterface, StatusSelectInterface } from './interface';

import { ArrowLeftIcon } from '../../Icons';

import { StatementTable } from '../StatementTable';

import { SAPaymentStatus } from '../../__generated__/StatementTableQuery.graphql';

export function StatementList({
  onBackButtonClick,
  balanceDue,
}: StatementListInterface) {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const [status, setStatus] = useState<SAPaymentStatus>(null);

  return (
    <>
      {!isMobile && (
        <>
          <StatusSelect onStatusChange={value => setStatus(value)} />
          <StatementTable status={status} />
        </>
      )}
      {isMobile && (
        <Flex overflow="auto" height="inherit">
          <Flex
            padding="12px 0 20px 0"
            direction="row"
            gap={24}
            alignItems="center"
          >
            <ArrowLeftIcon
              onClick={() => onBackButtonClick && onBackButtonClick()}
              width="16px"
              height="16px"
              fill="blue"
            />
            <Flex direction="row" gap={30}>
              <Text text="Saldo em aberto" component="LabelTextStyle" />
              <Text
                color="blue"
                text={formatToBRL(balanceDue)}
                component="LabelTextStyle"
              />
            </Flex>
          </Flex>
          <StatusSelect onStatusChange={value => setStatus(value)} />
          <StatementTable status={status} />
        </Flex>
      )}
    </>
  );
}

const StatusSelect = ({ onStatusChange }: StatusSelectInterface) => {
  const options = [
    { label: 'Tudo', value: null },
    { label: 'Pago', value: 'PAID' },
    { label: 'Vencido', value: 'OVERDUE' },
    { label: 'Pendente', value: 'PENDING' },
  ] as Array<{ label: string; value: SAPaymentStatus | null }>;

  return (
    <Flex gap={6} margin="0 0 20px 0">
      <Text component="LabelTextStyle" text="Status" />
      <SelectStyle
        onChange={e => onStatusChange(e.value as SAPaymentStatus)}
        // value={{ label: 'Tudo', value: null }}
        options={options}
      />
    </Flex>
  );
};
