import styled from 'styled-components';
import { Button, DefaultTextStyle } from '@captalys-platform/core';

export const ButtonStyle = styled(Button)<{
  outline?: boolean;
}>`
  ${({ outline, theme }) =>
    !outline &&
    `
    ${DefaultTextStyle} {
      color: ${theme.colors.white}
    }
  `}

  padding: 10px 30px;
`;
