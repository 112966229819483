import styled from 'styled-components';
import { Flex } from '@captalys-platform/core';
import { loadingProps } from './props';
import { LoadingInterface } from './interface';

export const LoadingStyle = styled(Flex).attrs(loadingProps)<LoadingInterface>`
  width: 100%;
  height: 100%;

  ${({ theme, dark }) => dark && `background-color: ${theme.colors.white};`};

  ${({ overlay, theme }) =>
    overlay &&
    `
    position: fixed;
    z-index: 999999999;
    top: 0;
    left: 0;
    min-height: 100vh;

    background-color: ${theme.colors.primary};
    background-image: linear-gradient(to bottom,transparent,transparent,${theme.colors.primary});
  `}
`;
