import styled from 'styled-components';
import { Flex, DefaultTextStyle, Input } from '@captalys-platform/core';

export const SliderWrapperStyle = styled(Flex)`
  align-self: center;

  ${DefaultTextStyle} {
    color: ${({ theme }) => theme.colors[500]};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 600px;
  }
`;

export const ChosenValueStyle = styled(Input)`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors[500]};
  padding: 0;
  text-align: center;
  border: none;
  border-radius: 0;
  margin: 0 0 30px 0;
  width: 80%;
  align-self: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.success};

  &::after {
    content: '';
    display: block;
    margin: 4px auto 32px;
    width: 240px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.success};
  }
`;
