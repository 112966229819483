import React from 'react';
import {
  Flex,
  Text,
  createBreakpoints,
  Paper,
  Link,
} from '@captalys-platform/core';

import { formatToBRL } from 'brazilian-values';
import { useHistory } from 'react-router';
import {
  FlexStyle,
  RadioStyle,
  RadioContainerStyle,
  RadioInputStyle,
} from './style';
import { CardLineProps, OrderCardsProps } from './interface';

import { useWindowWidth } from '../../Utils';
import { ROUTES_PATH } from '../../constants';

export const OrderCards = ({
  chosenOffer,
  showAnotherOfferLink,
  selectedPaymentMethod,
  setPaymentMethod,
}: OrderCardsProps) => {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const {
    location: { pathname },
  } = useHistory();

  const hasSelectedPaymentMethod = selectedPaymentMethod !== null;

  const isThankYouPage = pathname.includes('thank-you');
  const isBoleto = selectedPaymentMethod === 'BOLETO';
  const isDebito = selectedPaymentMethod === 'DEBITO_AUTOMATICO';

  return (
    <>
      <Paper direction="column" width={isMobile ? '100%' : '50%'} shadow>
        <CardLine
          label="A pagar"
          value={formatToBRL(chosenOffer.amountDue)}
          textProps={{ component: 'H5TextStyle' }}
        />
        <CardLine
          label="- Juros"
          value={`${chosenOffer.tax}% ao mês`}
          textProps={{ component: 'DefaultTextStyle' }}
          hasLineBellow
        />
        <CardLine
          label="Valor a contratar"
          value={formatToBRL(chosenOffer.hireValue)}
        />
        <CardLine
          label="- IOF"
          value={formatToBRL(chosenOffer.iof)}
          textProps={{ component: 'DefaultTextStyle' }}
        />
        <CardLine
          label="- TAC"
          value={formatToBRL(chosenOffer.fixedCost)}
          textProps={{ component: 'DefaultTextStyle' }}
          hasLineBellow
        />
        <CardLine
          label="A Receber"
          value={formatToBRL(chosenOffer.creditValue)}
          textProps={{ component: 'H5TextStyle' }}
        />
      </Paper>
      <Flex
        direction="column"
        width={isMobile ? '100%' : '50%'}
        gap={12}
        alignItems={isMobile ? 'center' : 'baseline'}
      >
        <Paper direction="column" width="100%" shadow>
          <Text
            text="Forma de Pagamento"
            component="H5TextStyle"
            color="800"
            margin="0 0 12px 0"
          />
          <CardLine
            label="Parcela mensal"
            value={formatToBRL(chosenOffer.installmentValue)}
          />
          <CardLine
            label="Quantidade"
            value={`${chosenOffer.deadline} meses`}
          />
          <CardLine
            label="CET"
            value={`${chosenOffer.cet}% ao mês`}
            hasLineBellow={hasSelectedPaymentMethod}
          />
          {hasSelectedPaymentMethod ? (
            <>
              <Text
                text={
                  isThankYouPage
                    ? 'Forma de pagamento escolhida'
                    : 'Qual forma de pagamento você prefere?'
                }
                component="H5TextStyle"
                color="800"
                margin="0 0 12px 0"
              />
              <Flex direction="row">
                {isThankYouPage && !isBoleto ? null : (
                  <RadioContainerStyle
                    // @ts-ignore
                    disabled={isThankYouPage && isBoleto}
                    variant
                    htmlFor="boleto"
                  >
                    <RadioInputStyle
                      checked={isBoleto}
                      value=""
                      type="radio"
                      id="boleto"
                      onChange={() => setPaymentMethod('BOLETO')}
                    />
                    <RadioStyle />
                    Boleto
                  </RadioContainerStyle>
                )}
                {isThankYouPage && !isDebito ? null : (
                  <RadioContainerStyle
                    // @ts-ignore
                    disabled={isThankYouPage && isDebito}
                    variant
                    htmlFor="debito"
                  >
                    <RadioInputStyle
                      checked={isDebito}
                      value=""
                      type="radio"
                      id="debito"
                      onChange={() => setPaymentMethod('DEBITO_AUTOMATICO')}
                    />
                    <RadioStyle />
                    Débito em conta
                  </RadioContainerStyle>
                )}
              </Flex>
            </>
          ) : null}
        </Paper>
        {showAnotherOfferLink && (
          <Link
            to={ROUTES_PATH.CREDIT}
            text="Escolher outra proposta"
            color="blue"
          />
        )}
      </Flex>
    </>
  );
};

const CardLine = ({
  label,
  value,
  textProps,
  flexProps,
  hasLineBellow,
}: CardLineProps) => {
  return (
    <FlexStyle
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      hasLineBellow={hasLineBellow}
      {...flexProps}
    >
      <Flex width="auto" alignItems="flex-start">
        <Text
          text={label}
          component="StrongTextStyle"
          color="800"
          {...textProps}
        />
      </Flex>
      <Flex width="42%" alignItems="flex-start">
        <Text
          text={value}
          component="StrongTextStyle"
          color="800"
          {...textProps}
        />
      </Flex>
    </FlexStyle>
  );
};
