import React from 'react';
import { formatToBRL } from 'brazilian-values';
// import { Redirect } from 'react-router';

import {
  Flex,
  Text,
  Paper,
  DefaultTextInterface,
  createBreakpoints,
} from '@captalys-platform/core';

import { PaperProps } from './props';
import { DashboardSidebar_operationStatus as OperationStatusTypes } from '../../__generated__/DashboardSidebar_operationStatus.graphql';
import { useWindowWidth } from '../../Utils';
// import { ROUTES_PATH } from '../../constants';

const defaultOperationStatus = {
  operationStartDate: '',
  creditValue: 0,
  dueValue: 0,
  paidValue: 0,
  paidInstallments: 0,
  totalInstallments: 0,
};

export const DashboardSidebarFragment = ({
  operationStatus,
}: {
  operationStatus: OperationStatusTypes;
}) => {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;

  // if (!operationStatus) {
  //   return <Redirect to={ROUTES_PATH.THANK_YOU} />;
  // }

  const {
    creditValue,
    dueValue,
    operationStartDate,
    paidInstallments,
    paidValue,
    totalInstallments,
  } = operationStatus || defaultOperationStatus;

  const OutsideCardText = (cardTextProps: DefaultTextInterface) => (
    <Text {...cardTextProps} color={isMobile ? 'white' : 'primary'} />
  );

  const DataPaper = ({ title, data }: { title: string; data: string }) => (
    <Paper {...PaperProps}>
      <Text text={title} color="primary" />
      <Text text={data} component="H3TextStyle" color="primary" />
    </Paper>
  );

  return (
    <Flex area="leftsidebar" width="100%" gap={40}>
      <Flex direction="row" alignItems="center" gap={30}>
        <OutsideCardText text="Operação 1" component="H3TextStyle" />
        <OutsideCardText text={operationStartDate} />
      </Flex>
      <Flex gap={5}>
        <OutsideCardText text="Valor recebido" />
        <OutsideCardText
          text={formatToBRL(creditValue)}
          component="H2TextStyle"
        />
      </Flex>
      <Flex gap={20}>
        <DataPaper
          title="Pagamentos"
          data={`${paidInstallments}/${totalInstallments}`}
        />
        <DataPaper title="Valor pago" data={formatToBRL(paidValue)} />
        <DataPaper title="Valor total devido" data={formatToBRL(dueValue)} />
      </Flex>
    </Flex>
  );
};
