import { DefaultGridInterface } from '@captalys-platform/core';

export const PageGridProps: DefaultGridInterface = {
  medias: {
    md: {
      rows: '1fr',
      columns: '1fr 3fr',
      columnGap: 60,
      areas: `
        "leftsidebar table table table"
      `,
    },
    xs: {
      rows: '1fr 1fr',
      rowGap: 30,
      columns: '1fr',
      areas: `
        "leftsidebar"
        "table"
      `,
    },
  },
};
