import {
  CreateRelayEnvironment,
  ClientMutationID,
} from '@captalys-platform/relay';
import config from '../config';

export const MutationID = ClientMutationID;

export const { Environment } = new CreateRelayEnvironment({
  url: config.API_ATLAS_URL,
  useAuthorization: true,
  useCache: true,
  redirectOnError: false,
  timeout: 120000,
});
