import React, { useState } from 'react';
import {
  Text,
  Link,
  Flex,
  createBreakpoints,
  ArrowLeftIcon,
} from '@captalys-platform/core';
import { Route, useHistory } from 'react-router';

import { LabelAndValueProps } from './interface';
import { LinkButtonStyle } from './style';
import { getRepresentativeData } from './props';

import { MembersIcon } from '../../Icons';
import { ROUTES_PATH } from '../../constants';
import { useWindowWidth, useAccessFlow } from '../../Utils';
import { confirmData, saveCheckpoint } from '../../Relay/Mutations';
import { ButtonStyle } from '../Credit';
import { MutationID } from '../../Relay/enviroment';
import { OverlaySidebar } from '../../Components/OverlaySidebar';
import { InfoQueryResponse } from '../../__generated__/InfoQuery.graphql';
import { InfoIncorrect } from '../../Components/InfoIncorrect';
import { VerifyEmail } from '../../Components/VerifyEmail';
import { checkpointsIncludes } from '../../Helpers';

const formatToCPF = (value: string) => {
  if (value === '') return value;

  const firstValue = value.slice(0, 3);
  const secondValue = value.slice(3, 6);
  const thirdValue = value.slice(6, 9);
  const fourthValue = value.slice(9, 11);

  return `${firstValue}.${secondValue}.${thirdValue}-${fourthValue}`;
};

const getButtonText = (isDoneSobreEmpresa: boolean, isLoading: boolean) => {
  if (isDoneSobreEmpresa) return 'Continuar';

  return isLoading ? 'Confirmando...' : 'Confirmar';
};
export const InfoFragment = ({
  checkpoints,
  myCompany,
  me,
}: InfoQueryResponse['viewer']) => {
  const { push } = useHistory();
  const isDesktop = useWindowWidth() > createBreakpoints().values.md;
  const [isConfirmingData, toggleIsConfirmingData] = useState(false);

  useAccessFlow(checkpoints);

  const isDone = checkpointsIncludes(checkpoints);

  const handleConfirm = async () => {
    if (!checkpoints.includes('SOBRE_EMPRESA') && me.emailVerified) {
      await saveCheckpoint({
        clientMutationId: MutationID,
        checkpoint: 'SOBRE_EMPRESA',
      });
    }

    if (isDone('SOBRE_EMPRESA')) {
      push(ROUTES_PATH.ORDER_REVIEW);
      return;
    }

    try {
      toggleIsConfirmingData(true);

      const res = await confirmData({
        clientMutationId: MutationID,
      });

      if (!res.confirmData.valid) {
        push(ROUTES_PATH.INFO_INCORRECT, {
          text:
            'Sua contratação do SulAmérica SOSPrev não pôde ser concluída porque identificamos dados desatualizados. Por favor, ligue na Central para atualizá-los:',
        });

        return;
      }

      const targetRoute =
        ROUTES_PATH[me.emailVerified ? 'ORDER_REVIEW' : 'INFO_VERIFY_EMAIL'];

      if (!me.emailVerified) toggleIsConfirmingData(false);

      push(targetRoute);
    } catch (e) {
      toggleIsConfirmingData(false);
    }
  };

  const { name, cpf, cellphone, email, bankInfo } = getRepresentativeData(
    myCompany,
  );

  return (
    <>
      <Flex
        maxWidth="100%"
        gap={32}
        grow={2}
        alignItems="baseline"
        justifyContent={isDesktop ? 'flex-start' : 'space-between'}
      >
        <Flex gap={40}>
          <Flex direction="row" gap={20} alignItems="center">
            <Link to={ROUTES_PATH.CREDIT}>
              <ArrowLeftIcon width={15} height={15} fill="blue" />
            </Link>
            <MembersIcon width="40px" height="40px" fill="blue" />
            <Text component="H4TextStyle" text="Suas Informações" />
          </Flex>

          <Flex direction="column" gap={24}>
            <LabelAndValue label="Nome Completo" value={name} />
            <LabelAndValue label="CPF" value={formatToCPF(cpf)} />
            <LabelAndValue label="Celular" value={cellphone} />
            <LabelAndValue label="E-mail" value={email} />
            <LabelAndValue label="Banco" value={bankInfo.bankName} />
            <Flex width="100%" justifyContent="space-between" direction="row">
              <LabelAndValue label="Agência" value={bankInfo.agency} />
              <LabelAndValue label="Conta" value={bankInfo.account} />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          alignItems={isDesktop ? 'flex-start' : 'center'}
          width="100%"
          gap={16}
        >
          <ButtonStyle
            text={getButtonText(isDone('SOBRE_EMPRESA'), isConfirmingData)}
            onClick={handleConfirm}
            disabled={isConfirmingData}
          />
          <LinkButtonStyle
            text="Meus dados não estão corretos"
            onClick={() => push(ROUTES_PATH.INFO_INCORRECT)}
            color="blue"
          />
        </Flex>
        <Route path={ROUTES_PATH.INFO_INCORRECT}>
          <OverlaySidebar overlayedRoute="INFO">
            <InfoIncorrect />
          </OverlaySidebar>
        </Route>
        <Route path={ROUTES_PATH.INFO_VERIFY_EMAIL}>
          <OverlaySidebar
            overlayedRoute="INFO"
            closeOnOverlayClick={false}
            hideTopbarBars
          >
            <VerifyEmail />
          </OverlaySidebar>
        </Route>
      </Flex>
    </>
  );
};

const LabelAndValue = ({ label, value }: LabelAndValueProps) => (
  <Flex gap={8} direction="column">
    <Text color="black" text={label} component="StrongTextStyle" />
    <Text color="800" text={value} component="DefaultTextStyle" />
  </Flex>
);
