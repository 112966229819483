import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  VerifyCodeInput,
  VerifyCodeMutation,
} from '../../../__generated__/VerifyCodeMutation.graphql';

const mutation = graphql`
  mutation VerifyCodeMutation($input: VerifyCodeInput!) {
    verifyCode(input: $input) {
      clientMutationId
      response {
        status
        statusCode
        ...Response_message
      }
    }
  }
`;

export const verifyCode = async (input: VerifyCodeInput) =>
  CommitMutation<VerifyCodeMutation>({
    mutation,
    variables: { input },
  });
