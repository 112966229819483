import {
  MutationConfig,
  commitMutation,
  MutationParameters,
} from 'relay-runtime';
import { Environment } from '../enviroment';

export const CommitMutation = <
  T extends MutationParameters = MutationParameters
>(
  config: Omit<MutationConfig<T>, 'onCompleted' | 'onError'>,
) =>
  new Promise<T['response']>((resolve, reject) => {
    commitMutation(Environment, {
      ...config,
      onError: reject,
      onCompleted: resolve,
    });
  });
