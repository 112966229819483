import styled from 'styled-components';
import {
  Flex,
  LinkButtonStyle,
  Text,
  InputCheckbox,
} from '@captalys-platform/core';

export const FlexStyle = styled(Flex)<{ hasLineBellow?: boolean }>`
  padding: ${({ hasLineBellow }) =>
    hasLineBellow ? '0 0 12px 0' : '0 0 6px 0'};
  border-bottom: ${({ theme, hasLineBellow }) =>
    hasLineBellow && `1px solid ${theme.colors[300]}`};
  margin: ${({ hasLineBellow }) => hasLineBellow && `0 0 12px 0`};
`;

export const CheckboxStyle = styled(Text)`
  z-index: 9;

  ${LinkButtonStyle} {
    z-index: 10;
  }
`;

export const InputCheckboxStyle = styled(InputCheckbox)`
  align-items: center;
`;
