import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  SaveCheckpointInput,
  SaveCheckpointMutation,
} from '../../../__generated__/SaveCheckpointMutation.graphql';

const mutation = graphql`
  mutation SaveCheckpointMutation($input: SaveCheckpointInput!) {
    saveCheckpoint(input: $input) {
      response {
        status
        ...Response_message
      }
      clientMutationId
    }
  }
`;

export const saveCheckpoint = async (input: SaveCheckpointInput) =>
  CommitMutation<SaveCheckpointMutation>({
    mutation,
    variables: { input },
  });
