import styled from 'styled-components';
import { Flex, DefaultTextStyle, SvgStyle } from '@captalys-platform/core';
import { FooterProps } from './props';

export const FooterStyle = styled(Flex).attrs(FooterProps)`
  ${DefaultTextStyle} {
    text-align: center;
    color: ${({ theme }) => theme.colors[300]};

    ${({ theme }) => theme.breakpoints.up('md')} {
      color: ${({ theme }) => theme.colors[400]};
    }
  }

  ${SvgStyle} {
    > g {
      transform: scale(0.3);
    }
  }
`;
