import styled from 'styled-components';
import { InputRangeInterface } from './interface';

export const InputRangeStyle = styled.input<InputRangeInterface>`
  width: 100%;
  height: 8px;
  margin: 8px auto;
  border-radius: 16px;
  -webkit-appearance: none;

  background-image: ${({ percentBar, theme }) =>
    `
    -webkit-gradient(
      linear, 0% 0%, 100% 0%,
      color-stop(${percentBar},
      ${theme.colors.primary}),
      color-stop(${percentBar},
      ${theme.colors.withTransparency}))
    `};

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: grab;
    background: ${props => props.theme.colors.darker};
  }
`;
