import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { createBreakpoints } from '@captalys-platform/core';
import { Environment } from '../../Relay/enviroment';
import { LoadingComponent } from '../../Components/Loading';
import { ErrorComponent } from '../../Components/Error';
import { StatusFragment } from './component';
import { useWindowWidth } from '../../Utils';

import { StatusQuery } from '../../__generated__/StatusQuery.graphql';

export const StatusPage = () => {
  const isDesktop = useWindowWidth() > createBreakpoints().values.md;

  const statusQuery = graphql`
    query StatusQuery {
      viewer {
        checkpoints
        chosenOffer {
          id
          amountDue
          deadline
          tax
          cet
          installmentValue
        }
        me {
          name
          emailVerified
        }
      }
    }
  `;

  return (
    <QueryRenderer<StatusQuery>
      environment={Environment}
      fetchPolicy="store-and-network"
      query={statusQuery}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <ErrorComponent error={error} />;
        }
        if (!props || !props.viewer) {
          return <LoadingComponent dark={!isDesktop} />;
        }

        const checkpoints = props?.viewer?.checkpoints || [];
        const chosenOffer = props?.viewer?.chosenOffer || null;
        const me = props?.viewer?.me || null;

        return (
          <StatusFragment
            checkpoints={checkpoints}
            chosenOffer={chosenOffer}
            me={me}
          />
        );
      }}
    />
  );
};
