import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { SITE_NAME } from '../../constants';
import { HelmetType } from './interface';

export const HelmetComponent = (props: HelmetType) => {
  const { title, description = '' } = props;

  return (
    <ReactHelmet>
      <title>{`${title} | ${SITE_NAME}`}</title>
      <meta name="description" content={description} />
    </ReactHelmet>
  );
};
