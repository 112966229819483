import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  GenVerificationCodeInput,
  GenVerificationCodeMutation,
} from '../../../__generated__/GenVerificationCodeMutation.graphql';

const mutation = graphql`
  mutation GenVerificationCodeMutation($input: GenVerificationCodeInput!) {
    genVerificationCode(input: $input) {
      clientMutationId
      response {
        status
        ...Response_message
      }
      phone {
        phoneNumber
        longDistanceCode
        fullNumber
      }
    }
  }
`;

export const genVerificationCode = async (input: GenVerificationCodeInput) =>
  CommitMutation<GenVerificationCodeMutation>({
    mutation,
    variables: { input },
  });
