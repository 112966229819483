/* tslint:disable */
/* eslint-disable */
/* @relayHash 119972b5e91dea0c4d18a9e6aed2eb45 */

import { ConcreteRequest } from "relay-runtime";
export type CheckpointEnum = "ACEITE_PROPOSTA" | "ACEITE_TERMOS" | "ACOMPANHAMENTO" | "ANALISE" | "CONTRATO_GERADO" | "DECLINADO" | "DOCUMENTOS" | "PROPOSTA_ESCOLHIDA" | "SOBRE_EMPRESA" | "SOBRE_SOCIOS";
export type WelcomeQueryVariables = {};
export type WelcomeQueryResponse = {
    readonly viewer: {
        readonly checkpoints: ReadonlyArray<CheckpointEnum | null> | null;
        readonly me: {
            readonly name: string | null;
            readonly email: string | null;
        } | null;
    } | null;
};
export type WelcomeQuery = {
    readonly response: WelcomeQueryResponse;
    readonly variables: WelcomeQueryVariables;
};



/*
query WelcomeQuery {
  viewer {
    checkpoints
    me {
      name
      email
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "checkpoints",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WelcomeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WelcomeQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "WelcomeQuery",
    "id": null,
    "text": "query WelcomeQuery {\n  viewer {\n    checkpoints\n    me {\n      name\n      email\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '2830a954dd3801f611e640c2f4fee7d6';
export default node;
