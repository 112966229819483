import styled from 'styled-components';
import { InputText } from '@captalys-platform/core';

export const InputTextStyle = styled(InputText)`
  max-width: 50px;
  padding: 6px;
  text-align: center;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.blue};
`;
