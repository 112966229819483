import React from 'react';
import { formatToBRL } from 'brazilian-values';
import { Text, HeaderSourceType } from '@captalys-platform/core';

import { PAYMENT_STATUS } from '../../constants';

export const TableHeaderProps: HeaderSourceType[] = [
  {
    title: 'Nº',
    dataIndex: 'index',
    key: 'index',
    dataHandler: numb => (!numb ? '-' : String(numb)),
  },
  {
    title: 'Valor do pagamento',
    dataIndex: 'installmentValue',
    key: 'installmentValue',
    dataHandler: numb => formatToBRL(Number(numb)),
  },
  {
    title: 'Status',
    dataIndex: 'paymentStatus',
    key: 'paymentStatus',
    render: value => {
      switch (value) {
        case PAYMENT_STATUS.NO_FUNDS:
          return <Text color="danger">Saldo insuficiente</Text>;
        case PAYMENT_STATUS.PAID:
          return <Text color="success">Pago</Text>;
        case PAYMENT_STATUS.RESERVED:
          return <Text color="primary">Reserva</Text>;
        default:
          return <Text color="danger">{value}</Text>;
      }
    },
  },
  {
    title: 'Data',
    dataIndex: 'paymentDate',
    key: 'paymentDate',
  },
];
