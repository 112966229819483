import React from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import { StatementTableComponent } from './component';
import { StatementTableViewPaginationInterface } from './interface';

export const StatementTableView = createPaginationContainer<
  StatementTableViewPaginationInterface
>(
  props => (
    <StatementTableComponent
      relay={props.relay}
      installments={props.viewer.saOperation.installments}
    />
  ),
  {
    viewer: graphql`
      fragment StatementTableView_viewer on Viewer {
        saOperation {
          installments(first: $count, after: $after, filters: $input)
            @connection(
              key: "StatementTableView_installments"
              filters: ["filters"]
            ) {
            edges {
              cursor
              node {
                id
                closed
                dueDate
                status
                paymentStatus
                pmt
                nominalValue
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
              totalCount
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    query: graphql`
      query StatementTableViewFowardQuery(
        $after: String
        $count: Int!
        $input: SAOperationFilterInput
      ) {
        viewer {
          ...StatementTableView_viewer
        }
      }
    `,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConnectionFromProps(props: any) {
      return props.viewer.saOperation && props.viewer.saOperation.installments;
    },
    getFragmentVariables(previousVariables, totalCount) {
      return {
        ...previousVariables,
        count: totalCount,
      };
    },
    getVariables(_props, { count, cursor }, fragmentVariables) {
      return {
        count,
        after: cursor,
        input: fragmentVariables.input,
      };
    },
  },
);
