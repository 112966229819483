/* eslint-disable react/jsx-wrap-multilines, sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { useHistory, Route } from 'react-router';
import {
  Link,
  Flex,
  ArrowLeftIcon,
  Text,
  createBreakpoints,
} from '@captalys-platform/core';

import { formatToBRL } from 'brazilian-values';
import { SmsTokenConfirmation } from '../../Components/SmsTokenConfirmation';
import { OrderReviewQueryResponse } from '../../__generated__/OrderReviewQuery.graphql';
import { ROUTES_PATH, FINANCIAL_TERMS_PDF } from '../../constants';
import { FlexStyle, CheckboxStyle, InputCheckboxStyle } from './style';
import { ButtonStyle } from '../Credit';
import { useWindowWidth, useAccessFlow } from '../../Utils';
import { OverlaySidebar } from '../../Components/OverlaySidebar';
import { OrderCards } from '../../Components/OrderCards/component';
import { saveCheckpoint, confirmPaymentMethod } from '../../Relay/Mutations';
import { MutationID } from '../../Relay/enviroment';
import { PaymentMethod } from '../../__generated__/confirmPaymentMethodMutation.graphql';

export const OrderReviewComponent = ({
  chosenOffer,
  proposalData,
  me,
  checkpoints,
  paymentMethod,
}: OrderReviewQueryResponse['viewer']) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod
  >(paymentMethod.method || 'BOLETO');
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const { push } = useHistory();
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    false,
  );

  useAccessFlow(checkpoints);

  const onSubmitClick = async () => {
    try {
      await confirmPaymentMethod({
        clientMutationId: MutationID,
        paymentMethod: selectedPaymentMethod,
      });

      await saveCheckpoint({
        clientMutationId: MutationID,
        checkpoint: 'ACEITE_PROPOSTA',
      });

      push(ROUTES_PATH.ORDER_REVIEW_SMS);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Flex
      gap={isMobile ? 40 : 60}
      alignItems="center"
      width="100%"
      direction="column"
    >
      <Flex direction="row" gap={20} alignItems="center" width="100%">
        <ArrowLeftIcon
          width={15}
          height={15}
          fill="blue"
          onClick={() => push(ROUTES_PATH.STATUS)}
        />
        <Text component="H4TextStyle" text="Revise sua proposta" />
      </Flex>
      <Flex direction={isMobile ? 'column' : 'row'} gap={30} width="100%">
        <OrderCards
          chosenOffer={chosenOffer}
          showAnotherOfferLink
          selectedPaymentMethod={selectedPaymentMethod}
          setPaymentMethod={setSelectedPaymentMethod}
        />
      </Flex>
      <Flex
        width="100%"
        padding={isMobile ? '0 12px' : '0 70px'}
        direction="column"
        gap={20}
      >
        <Text component="ParagraphTextStyle" color="400">
          Ao prosseguir, você concorda em ceder o valor de&nbsp;
          {formatToBRL(proposalData.warranty)}
          &nbsp;como garantia para o empréstimo. Fique tranquilo, pois este
          dinheiro continuará rendendo no seu plano de Previdência. Contudo, não
          estará mais disponível para resgate e portabilidade externa e interna
          enquanto o contrato do SulAmérica SOSPrev estiver vigente.
        </Text>

        <FlexStyle
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={12}
          hasLineBellow
        >
          <Text
            width="20%"
            component="StrongTextStyle"
            color="700"
            text="Código Plano"
          />
          <Text
            width="40%"
            component="StrongTextStyle"
            color="700"
            text="Nome Plano"
          />
          <Text
            width="40%"
            component="StrongTextStyle"
            color="700"
            text="Valor em Garantia"
          />
        </FlexStyle>
        <FlexStyle
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={12}
        >
          <Text
            width="20%"
            component="StrongTextStyle"
            color="700"
            text={proposalData.planCode}
          />
          <Text
            width="40%"
            component="StrongTextStyle"
            color="700"
            text={proposalData.planName}
          />
          <Text
            width="40%"
            component="StrongTextStyle"
            color="700"
            text={formatToBRL(proposalData.warranty)}
          />
        </FlexStyle>
      </Flex>
      <Flex width="100%" padding={isMobile ? '0 12px' : '0 230px'}>
        <InputCheckboxStyle
          width="100%"
          onClick={() => {
            setTermsAndConditionsAccepted(!termsAndConditionsAccepted);
          }}
          label={
            <CheckboxStyle>
              {me.name}
              , concordo com as&nbsp;
              <Link
                target="_blank"
                href={FINANCIAL_TERMS_PDF}
                text="cláusulas e condições pactuadas no Contrato de Assistência Financeira."
              />
            </CheckboxStyle>
          }
        />
      </Flex>

      <ButtonStyle
        text="Quero contratar!"
        disabled={!termsAndConditionsAccepted}
        onClick={onSubmitClick}
      />

      <Route path={ROUTES_PATH.ORDER_REVIEW_SMS}>
        <OverlaySidebar overlayedRoute="ORDER_REVIEW">
          <SmsTokenConfirmation />
        </OverlaySidebar>
      </Route>
    </Flex>
  );
};
