import { InputSelect } from '@captalys-platform/core';
import styled from 'styled-components';

export const SelectStyle = styled(InputSelect)`
  max-width: 200px;
  .react-select__control {
    padding: 0 14px;
    color: ${({ theme }) => theme.colors['700']};
  }
`;
