import {
  DefaultTextStyle,
  TableHeader,
  Table,
  TableRowStyle,
  Flex,
  Button,
} from '@captalys-platform/core';
import styled from 'styled-components';

export const LoadMoreButtonStyle = styled(Button).attrs({
  outline: true,
  color: 'blue',
})`
  border-color: transparent;

  &:hover {
    background-color: transparent;
    ${DefaultTextStyle} {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export const LoadMoreRowStyle = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  height: 50px;
  border: solid 1px ${({ theme }) => `${theme.colors['300']}`};
  border-top: 0;
`;

export const TableHeaderStyle = styled(TableHeader)`
  background-color: ${({ theme }) => theme.colors.primary};

  ${DefaultTextStyle} {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const TableStyle = styled(Table)`
  display: table;
  border: solid 1px ${({ theme }) => `${theme.colors['300']}`};

  ${TableRowStyle} {
    border-bottom: solid 1px ${({ theme }) => `${theme.colors['300']}`};
  }
`;
