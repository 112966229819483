import React from 'react';
import { Link } from '@captalys-platform/core';
import { NotFoundPageStyle } from './style';
import { ROUTES_PATH } from '../../constants';

export const NotFoundPage = () => (
  <NotFoundPageStyle>
    <Link to={ROUTES_PATH.WELCOME} text="Retornar à página principal" />
  </NotFoundPageStyle>
);
