import React from 'react';
import { formatToBRL } from 'brazilian-values';
import { Text, Flex, CheckCircleIcon, Link } from '@captalys-platform/core';

import { CreditOfferItemStyle, CheckIconWrapper } from './style';
import { CreditOfferItemFragmentProps } from './interface';
import { ROUTES_PATH } from '../../constants';

export const CreditOfferItem = ({
  offer,
  showCheckIcon,
  showLinkToCreditPage,
}: CreditOfferItemFragmentProps) => (
  <CreditOfferItemStyle alignItems="center" padding="32px" margin="16px 0">
    {showCheckIcon && (
      <CheckIconWrapper direction="column" margin="0 0 32px 0" gap={20}>
        <CheckCircleIcon fill="success" />
        <Text component="H4TextStyle" text="PROPOSTA" />
      </CheckIconWrapper>
    )}
    <Text component="H3TextStyle" text={formatToBRL(offer.installmentValue)} />
    <Text text="Parcela Mensal" />

    <Flex gap={10} direction="column" width="100%">
      <CreditOfferItemLine
        title="Valor total"
        value={formatToBRL(offer.amountDue)}
      />
      <CreditOfferItemLine
        title="Quantidade"
        value={`${offer.deadline} meses`}
      />
      <CreditOfferItemLine title="Juros" value={`${offer.tax}% ao mês`} />
      <CreditOfferItemLine title="CET" value={`${offer.cet}% ao mês`} />

      {showLinkToCreditPage && (
        <Flex width="100%" alignItems="center" margin="8px 0">
          <Link
            to={ROUTES_PATH.CREDIT}
            text="Escolher outra proposta"
            color="blue"
          />
        </Flex>
      )}
    </Flex>
  </CreditOfferItemStyle>
);

const CreditOfferItemLine = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => (
  <Flex
    width="100%"
    justifyContent="space-between"
    alignItems="center"
    direction="row"
  >
    <Flex width="100%" alignItems="flex-start">
      <Text component="LabelTextStyle" text={title} />
    </Flex>
    <Flex width="100%" alignItems="flex-start">
      <Text component="H6TextStyle" text={value} />
    </Flex>
  </Flex>
);
