import { RouteProps } from 'react-router';
import { ROUTES_PATH } from './constants';
import { WelcomePage } from './Pages/Welcome';
import { CreditPage } from './Pages/Credit';
import { StatusPage } from './Pages/Status';
import { OrderReviewPage } from './Pages/OrderReview';
import { ThankYouPage } from './Pages/ThankYou';
import { InfoPage } from './Pages/Info';
import { DashboardPage } from './Pages/Dashboard';
import { StatementPage } from './Pages/Statement';
import { ErrorPage } from './Pages/Error';
import { LoginPage } from './Pages/LoginPage';
import { DeclinedPage } from './Pages/Declined';

export const ROUTES = [
  {
    path: ROUTES_PATH.ROOT,
    component: LoginPage,
    exact: true,
    title: 'Login',
    description: '',
  },
  {
    path: ROUTES_PATH.WELCOME,
    component: WelcomePage,
    exact: true,
    requireAuth: true,
    title: 'Bem vindo ao portal do cliente',
  },
  {
    path: ROUTES_PATH.STATEMENT,
    component: StatementPage,
    exact: true,
    requireAuth: true,
    title: 'Resumo do Extrato',
  },
  {
    path: ROUTES_PATH.ERROR,
    component: ErrorPage,
    exact: true,
    title: 'Erro',
  },
  {
    path: ROUTES_PATH.STATUS,
    component: StatusPage,
    exact: true,
    requireAuth: true,
    title: 'Andamento da contratação',
  },
  {
    path: ROUTES_PATH.DASHBOARD,
    component: DashboardPage,
    exact: true,
    requireAuth: true,
    title: 'Dashboard',
  },
  {
    path: ROUTES_PATH.THANK_YOU,
    component: ThankYouPage,
    exact: true,
    requireAuth: true,
    title: 'Proposta em análise. Obrigado',
  },
  {
    path: ROUTES_PATH.CREDIT,
    component: CreditPage,
    requireAuth: true,
    sidebar: true,
    title: 'Quanto você precisa?',
  },
  {
    path: ROUTES_PATH.ORDER_REVIEW,
    component: OrderReviewPage,
    requireAuth: true,
    sidebar: true,
    title: 'Revise sua proposta',
  },
  {
    path: ROUTES_PATH.INFO,
    component: InfoPage,
    requireAuth: true,
    sidebar: true,
    title: 'Suas Informações',
  },
  {
    path: ROUTES_PATH.DECLINED,
    component: DeclinedPage,
    exact: true,
    requireAuth: true,
    title: 'Proposta Declinada',
  },
] as Array<
  {
    requireAuth?: boolean;
    dark?: boolean;
    sidebar?: boolean;
    title: string;
  } & RouteProps
>;
