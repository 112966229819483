import React from 'react';
import { Flex, createBreakpoints } from '@captalys-platform/core';
import {
  TitleTextStyle,
  DescriptionTextStyle,
  TextStyle,
  LinkTextStyle,
  ContainerStyle,
} from './style';
import { useWindowWidth, useAccessFlow } from '../../Utils';

import { DeclinedQueryResponse } from '../../__generated__/DeclinedQuery.graphql';

export function DeclinedPageComponent({
  checkpoints,
}: DeclinedQueryResponse['viewer']) {
  useAccessFlow(checkpoints);
  const isDesktop = useWindowWidth() > createBreakpoints().values.md;

  return (
    <ContainerStyle gap={32}>
      <TitleTextStyle
        text="Neste momento não foi possível aprovar sua proposta em nossa análise de crédito."
        isDesktop={isDesktop}
      />
      <Flex padding="0 60px 0 0">
        <DescriptionTextStyle
          text="Sabemos que pode ser frustrante não ter sua proposta aprovada, mas estamos constantemente calibrando nossos modelos de análise e você poderá fazer uma nova simulação no futuro."
          isDesktop={isDesktop}
        />
      </Flex>
      <Flex gap={8}>
        <TextStyle
          text="Qualquer dúvida, fale com a gente!"
          isDesktop={isDesktop}
        />
        <Flex direction="row" gap={4}>
          <TextStyle isDesktop={isDesktop} text="E-mail:" />
          <LinkTextStyle
            isDesktop={isDesktop}
            text="contatopaypal@captalys.com.br"
            underline
          />
        </Flex>
        <Flex direction="row" gap={4}>
          <TextStyle isDesktop={isDesktop} text="Tel:" />
          <LinkTextStyle isDesktop={isDesktop} text="(11) 3330-6924" />
        </Flex>
      </Flex>
    </ContainerStyle>
  );
}
