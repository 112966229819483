import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  confirmPaymentMethodInput,
  confirmPaymentMethodMutation,
} from '../../../__generated__/confirmPaymentMethodMutation.graphql';

const mutation = graphql`
  mutation confirmPaymentMethodMutation($input: confirmPaymentMethodInput!) {
    confirmPaymentMethod(input: $input) {
      clientMutationId
      response {
        status
        ...Response_message
      }
    }
  }
`;

export const confirmPaymentMethod = async (input: confirmPaymentMethodInput) =>
  CommitMutation<confirmPaymentMethodMutation>({
    mutation,
    variables: { input },
  });
