import React, { useEffect, useState } from 'react';

import {
  Text,
  TableRow,
  TableCell,
  TableBody,
  Flex,
  createBreakpoints,
} from '@captalys-platform/core';

import { formatToBRL } from 'brazilian-values';

import {
  TableHeaderStyle,
  TableStyle,
  LoadMoreButtonStyle,
  LoadMoreRowStyle,
} from './style';
import { useWindowWidth } from '../../Utils';

import { getStatusInfo, formatDate } from './props';
import {
  StatementTableViewInterface,
  StatementTableDataInterface,
} from './interface';

import { Spinner } from '../Spinner';

export function StatementTableComponent({
  installments,
  relay,
}: StatementTableViewInterface) {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const [isLoadingMore, setIsLoadingMore] = useState(relay.isLoading());

  function loadMore() {
    if (relay.hasMore() && !relay.isLoading()) {
      setIsLoadingMore(true);

      relay.loadMore(
        10,
        () => {
          setIsLoadingMore(false);
        },
        {
          fetchPolicy: 'store-or-network',
        },
      );
    }
  }

  function getLoadMoreData() {
    const Container = isMobile ? Flex : LoadMoreRowStyle;

    const props = isMobile && {
      alignItems: 'center',
      justifyContent: 'center',
      padding: '12px 6px',
      direction: 'row',
    };

    return (
      (isLoadingMore || relay.hasMore()) && (
        <Container {...props}>
          {isLoadingMore ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner dark={false} />
            </Flex>
          ) : (
            <LoadMoreButtonStyle
              onClick={loadMore}
              color="blue"
              text="Ver mais lançamentos"
              outline
            />
          )}
        </Container>
      )
    );
  }

  return isMobile ? (
    <MobileTable installments={installments}>{getLoadMoreData()}</MobileTable>
  ) : (
    <DesktopTable installments={installments}>{getLoadMoreData()}</DesktopTable>
  );
}

const MobileTable = ({
  installments: { edges },
  children,
}: StatementTableDataInterface) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = null;
    };
  }, []);

  return (
    <Flex>
      {edges.map(({ node: { dueDate, nominalValue, pmt, paymentStatus } }) => {
        const statusData = getStatusInfo(paymentStatus);
        const Icon = statusData.icon;
        return (
          <Flex
            alignItems="flex-end"
            padding="12px 6px"
            direction="row"
            key={pmt}
          >
            <Flex width="50px" padding="0 0 6px 0" alignItems="flex-start">
              <Icon width="16px" height="16px" fill={statusData.color} />
            </Flex>
            <Flex width="60%">
              <Text text={`Boleto ${pmt}`} component="LabelTextStyle" />
              <Text text={formatDate(`${dueDate}`)} />
            </Flex>
            <Flex>
              <Text text={formatToBRL(nominalValue)} />
            </Flex>
          </Flex>
        );
      })}
      {edges.length === 0 && (
        <Flex alignItems="center" justifyContent="center" height="120px">
          <Text component="H5TextStyle" text="Ainda não há dados disponíveis" />
        </Flex>
      )}
      {children}
    </Flex>
  );
};

const DesktopTable = ({
  installments: { edges },
  children,
}: StatementTableDataInterface) => (
  <>
    <TableStyle>
      <TableHeaderStyle>
        <TableRow>
          <TableCell>Nº do Boleto</TableCell>
          <TableCell>Valor do pagamento</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Data</TableCell>
        </TableRow>
      </TableHeaderStyle>
      <TableBody>
        {edges.map(
          ({ node: { dueDate, nominalValue, pmt, paymentStatus } }) => {
            const statusData = getStatusInfo(paymentStatus);
            return (
              <TableRow key={pmt}>
                <TableCell>{pmt}</TableCell>
                <TableCell>{formatToBRL(nominalValue)}</TableCell>
                <TableCell>
                  <Text color={statusData.color} text={statusData.text} />
                </TableCell>
                <TableCell>{formatDate(`${dueDate}`)}</TableCell>
              </TableRow>
            );
          },
        )}
      </TableBody>
    </TableStyle>
    {edges.length === 0 && (
      <LoadMoreRowStyle>
        <Text component="H5TextStyle" text="Ainda não há dados disponíveis" />
      </LoadMoreRowStyle>
    )}
    {children}
  </>
);
