import React from 'react';
import { LoadingInterface } from './interface';
import { LoadingStyle } from './style';
import { Spinner } from '../Spinner';
import { LogoSulAmericaWhite } from '../../Icons';

export const LoadingComponent = (props: LoadingInterface) => (
  <LoadingStyle {...props}>
    {props.overlay ? (
      <LogoSulAmericaWhite width={300} height={150} />
    ) : (
      <Spinner dark={props.dark} />
    )}
  </LoadingStyle>
);
