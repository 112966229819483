/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardSidebar_operationStatus = {
    readonly operationStartDate: string | null;
    readonly creditValue: number | null;
    readonly dueValue: number | null;
    readonly paidValue: number | null;
    readonly paidInstallments: number | null;
    readonly totalInstallments: number | null;
    readonly " $refType": "DashboardSidebar_operationStatus";
};
export type DashboardSidebar_operationStatus$data = DashboardSidebar_operationStatus;
export type DashboardSidebar_operationStatus$key = {
    readonly " $data"?: DashboardSidebar_operationStatus$data;
    readonly " $fragmentRefs": FragmentRefs<"DashboardSidebar_operationStatus">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "DashboardSidebar_operationStatus",
  "type": "Operation",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "operationStartDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "creditValue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dueValue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paidValue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paidInstallments",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalInstallments",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'd20b0e6dabbd5bcbc57aa65a6c1c0014';
export default node;
