export const LogoSulAmericaWhiteProps = {
  viewbox: '0 0 30 20',
  width: 20,
  height: 20,
  paths: [
    {
      fill: '#fff',
      d:
        'M7.743,47.659c0.154,4.442,3.991,9.078,11.951,9.078c8.489,0,11.994-3.913,11.992-9.413c-0.003-5.557-6.125-7.477-12.318-9.414c-6.906-2.161-12.968-4.322-12.968-10.5c0-6.942,6.06-10.614,13.606-10.614c7.475,0,13.562,3.607,13.665,10.522h-2.445c-0.103-5.516-5.208-8.252-11.219-8.252c-6.074,0-11.136,2.864-11.132,8.21c0.004,4.607,5.086,6.38,11.328,8.279c7.442,2.22,13.954,4.875,13.954,11.765c0,6.646-4.061,11.716-14.395,11.716c-9.36,0-14.304-5.67-14.458-11.377L7.743,47.659z',
    },
    {
      fill: '#fff',
      d:
        'M70.081,46.205c0,9.595-7.382,12.833-13.985,12.833c-6.541,0-14.102-3.233-14.102-12.833V17.35h2.525v28.854c0,7.913,6.055,10.542,11.577,10.542c5.576,0,11.46-2.625,11.46-10.543V17.35h2.525V46.205z',
    },
    {
      fill: '#fff',
      d: 'M101.475,56.113v2.28h-20.78V17.399h2.524v38.714H101.475z',
    },
    {
      fill: '#fff',
      d:
        'M134.48,58.393l-5.347-15.272h-15.347l-5.399,15.272h-2.586l14.525-40.994h2.266l14.576,40.994H134.48z M128.423,40.841l-6.963-19.709l-6.963,19.709H128.423z',
    },
    {
      fill: '#fff',
      d:
        'M175.835,58.393V23.405L162.649,57.62h-2.024l-13.083-34.214v34.987h-2.466V17.399h2.502l14.111,36.503l14.11-36.503h2.502v40.994H175.835z',
    },
    {
      fill: '#fff',
      d:
        'M192.212,19.678v17.105h21.09v2.28h-21.09v17.049h23.127v2.28h-25.652V17.399h25.303v2.28L192.212,19.678z M201.083,12.394l3.01-6.699h3.026l-4.104,6.699H201.083z',
    },
    {
      fill: '#fff',
      d:
        'M239.699,17.399c7.247,0,11.192,4.909,11.192,10.66c0,6.887-3.592,10.713-10.138,11.054h-2.303l12.678,19.281h-3.021l-12.425-19.047h-8.92v19.047h-2.524V17.399H239.699z M226.762,37.062h12.801c5.747,0,8.871-3.09,8.871-8.992c0-4.602-3.068-8.392-8.76-8.392h-12.912V37.062z',
    },
    {
      fill: '#fff',
      d: 'M261.189,58.393V17.399h2.524v40.994H261.189z',
    },
    {
      fill: '#fff',
      d:
        'M274.23,29.946c0-9.11,5.72-13.562,14.125-13.562c5.604,0,9.605,2.154,11.761,5.751l-1.94,1.036c-1.866-2.874-5.243-4.507-9.686-4.507c-6.954,0-11.736,3.633-11.736,11.281v15.522c0,7.648,4.782,11.281,11.736,11.281c4.442,0,7.819-1.634,9.686-4.507l1.94,1.036c-2.155,3.598-6.157,5.76-11.761,5.76c-8.405,0-14.125-4.46-14.125-13.569V29.946z',
    },
    {
      fill: '#fff',
      d:
        'M333.929,58.393l-5.348-15.272h-15.346l-5.399,15.272h-2.586l14.525-40.994h2.266l14.576,40.994H333.929z M327.872,40.841l-6.964-19.709l-6.963,19.709H327.872z',
    },
    {
      fill: '#fff',
      d:
        'M12.37,123.231c0.201,6.601,5.419,13.072,16.941,13.072c11.768,0,16.795-5.474,16.788-13.302c-0.008-8.007-8.423-10.579-17.618-13.488C17.33,106.007,8.093,102.41,8.093,91.989c0-11.299,9.064-17.491,21.349-17.491c12.157,0,21.395,6.086,21.529,17.49h-5.838c-0.133-8.156-7.155-12.026-15.69-12.026c-8.639,0-15.431,4.183-15.422,11.612c0.014,6.592,6.688,9.241,16.496,12.145c12.489,3.576,21.496,8.352,21.496,19.273c0,10.45-6.291,18.867-22.495,18.867c-14.665,0-22.772-8.742-22.973-18.63L12.37,123.231z',
    },
    {
      fill: '#fff',
      d:
        'M63.081,96.003c0-14.666,9.13-21.951,22.51-21.951c14.287,0,22.157,8.687,22.157,22.039v25.048c0,15.56-11.866,20.814-22.245,20.814c-10.279,0-22.422-5.241-22.422-20.814V96.003z M101.664,96.09c0-10.41-5.553-16.544-16.073-16.544c-10.112,0-16.426,5.167-16.426,16.456v25.136c0,11.507,8.392,15.314,16.338,15.314c8.021,0,16.161-3.792,16.161-15.313V96.09z',
    },
    {
      fill: '#fff',
      d:
        'M124.586,123.231c0.201,6.601,5.419,13.072,16.941,13.072c11.768,0,16.795-5.474,16.788-13.302c-0.008-8.007-8.423-10.579-17.618-13.488c-11.151-3.505-20.388-7.103-20.388-17.523c0-11.299,9.064-17.491,21.349-17.491c12.157,0,21.395,6.086,21.529,17.49h-5.838c-0.133-8.156-7.155-12.026-15.69-12.026c-8.639,0-15.431,4.183-15.422,11.612c0.014,6.592,6.688,9.241,16.496,12.145c12.489,3.576,21.496,8.352,21.496,19.273c0,10.45-6.291,18.867-22.495,18.867c-14.665,0-22.772-8.742-22.973-18.63L124.586,123.231z',
    },
    {
      fill: '#fff',
      d:
        'M201.032,75.486c11.915,0,18.408,8.35,18.408,17.896c0,12.078-6.703,18.892-18.584,18.889h-17.873v28.562h-6.084V75.485L201.032,75.486z M182.983,106.762h17.812c8.273,0,12.771-4.578,12.771-13.333c0-6.827-4.417-12.449-12.61-12.449h-17.973V106.762z',
    },
    {
      fill: '#fff',
      d:
        'M257.606,75.485c11.915,0,18.408,8.274,18.408,17.74c0,10.662-5.3,16.888-14.939,17.971h-3.06l18.83,29.637h-7.162l-18.042-28.891h-12.083v28.891h-6.084V75.485H257.606z M239.558,106.432h17.812c8.272,0,12.77-4.529,12.77-13.169c0-6.737-4.417-12.287-12.61-12.283h-17.972V106.432z',
    },
    {
      fill: '#fff',
      d:
        'M296.973,80.981v24.475h31.479v5.496h-31.479v24.387h34.555v5.495h-40.639V75.485H331v5.496H296.973z',
    },
    {
      fill: '#fff',
      d:
        'M357.613,140.833l-20.16-65.347h6.255l18.103,58.623l18.102-58.623h6.258l-20.029,65.347H357.613z',
    },
  ],
};
