import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  RepresentativeUpdateInput,
  RepresentativeUpdateMutation,
} from '../../../__generated__/RepresentativeUpdateMutation.graphql';

const mutation = graphql`
  mutation RepresentativeUpdateMutation($input: RepresentativeUpdateInput!) {
    representativeUpdate(input: $input) {
      clientMutationId
      response {
        status
        ...Response_message
      }
    }
  }
`;

export const representativeUpdate = (input: RepresentativeUpdateInput) =>
  CommitMutation<RepresentativeUpdateMutation>({
    mutation,
    variables: { input },
  });
