/* tslint:disable */
/* eslint-disable */
/* @relayHash c0341b39b2bd69c81a8565bdc5a76672 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VerificationPlatform = "EMAIL" | "PHONE";
export type GenVerificationCodeInput = {
    platform: VerificationPlatform;
    clientMutationId: string;
};
export type GenVerificationCodeMutationVariables = {
    input: GenVerificationCodeInput;
};
export type GenVerificationCodeMutationResponse = {
    readonly genVerificationCode: {
        readonly clientMutationId: string;
        readonly response: {
            readonly status: boolean;
            readonly " $fragmentRefs": FragmentRefs<"Response_message">;
        };
        readonly phone: {
            readonly phoneNumber: string | null;
            readonly longDistanceCode: string | null;
            readonly fullNumber: string | null;
        } | null;
    } | null;
};
export type GenVerificationCodeMutation = {
    readonly response: GenVerificationCodeMutationResponse;
    readonly variables: GenVerificationCodeMutationVariables;
};



/*
mutation GenVerificationCodeMutation(
  $input: GenVerificationCodeInput!
) {
  genVerificationCode(input: $input) {
    clientMutationId
    response {
      status
      ...Response_message
    }
    phone {
      phoneNumber
      longDistanceCode
      fullNumber
    }
  }
}

fragment Response_message on Response {
  status
  statusCode
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "GenVerificationCodeInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "phone",
  "storageKey": null,
  "args": null,
  "concreteType": "Phone",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phoneNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "longDistanceCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullNumber",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GenVerificationCodeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "genVerificationCode",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "GenVerificationCodePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "Response_message",
                "args": null
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "GenVerificationCodeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "genVerificationCode",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "GenVerificationCodePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "statusCode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "GenVerificationCodeMutation",
    "id": null,
    "text": "mutation GenVerificationCodeMutation(\n  $input: GenVerificationCodeInput!\n) {\n  genVerificationCode(input: $input) {\n    clientMutationId\n    response {\n      status\n      ...Response_message\n    }\n    phone {\n      phoneNumber\n      longDistanceCode\n      fullNumber\n    }\n  }\n}\n\nfragment Response_message on Response {\n  status\n  statusCode\n  message\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b6c8e6302905106cbc1179c4ef489b0b';
export default node;
