import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  RepresentativeCreateInput,
  RepresentativeCreateMutation,
} from '../../../__generated__/RepresentativeCreateMutation.graphql';

const mutation = graphql`
  mutation RepresentativeCreateMutation($input: RepresentativeCreateInput!) {
    representativeCreate(input: $input) {
      clientMutationId
      response {
        status
        ...Response_message
      }
    }
  }
`;

export const representativeCreate = async (input: RepresentativeCreateInput) =>
  CommitMutation<RepresentativeCreateMutation>({
    mutation,
    variables: { input },
  });
