import React from 'react';
import { createGlobalStyle } from 'styled-components';
import TagManager from 'react-gtm-module';
import { GlobalStyle, ThemeProvider } from '@captalys-platform/core';

import { AppThemeProviderThemeProps } from './props';

import { Routes } from '../Routes';
import config from '../../config';
import LintelFont from '../../font/Lintel.otf';

const GlobalFont = createGlobalStyle`
  @font-face {
    font-family: Lintel;
    src: url('${LintelFont}') format('opentype');
  }

  *, body, input, button {
    font-family: Lintel;
  }
`;

TagManager.initialize({ gtmId: config.TRACKING_ID });

export const App = () => {
  const { colors, breakpoints } = AppThemeProviderThemeProps;

  return (
    <ThemeProvider colors={colors} breakpoints={breakpoints}>
      <>
        <GlobalFont />
        <GlobalStyle />
        <Routes />
      </>
    </ThemeProvider>
  );
};
