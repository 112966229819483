export const getValue = (value: string): number => {
  if (value.includes('R$')) {
    const newNumber = value.replace(/[^0-9]+/g, '');

    if (newNumber === '') return 0;

    const formattedCurrency = `${newNumber.slice(
      0,
      newNumber.length - 2,
    )}.${newNumber.slice(newNumber.length - 2)}`;

    return Number(formattedCurrency);
  }

  return Number(value);
};
