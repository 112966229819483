import styled from 'styled-components';
import { Flex, SvgStyle, DefaultTextStyle } from '@captalys-platform/core';

export const WrapperTopbarStyle = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: ${({ theme }) => theme.colors.primary};

    ${SvgStyle} {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  height: auto;
  width: 100%;
`;

export const WrapperLogoSulAmerica = styled(Flex)`
  ${SvgStyle} {
    > g {
      ${({ theme }) => theme.breakpoints.up('md')} {
        transform: scale(0.4);
      }

      ${({ theme }) => theme.breakpoints.down('md')} {
        transform: scale(0.3);
      }
    }
  }
`;

export const TopbarStyle = styled(Flex)`
  margin: 0 auto;
  height: 116px;

  ${SvgStyle} {
    cursor: pointer;
  }
`;

export const DesktopFlexStyle = styled(Flex)`
  ${DefaultTextStyle} {
    color: ${({ theme }) => theme.colors.white};
  }
`;
