import React, { useEffect } from 'react';
import {
  Text,
  createBreakpoints,
  CloseIconProps,
  Flex,
} from '@captalys-platform/core';
import { createPortal } from 'react-dom';

import { ToastStyle, CloseIconButtonStyle } from './style';
import { ToastInterface } from './interface';

import { useWindowWidth } from '../../Utils';

export function ToastComponent({
  isOpen,
  message,
  timeoutCountdown = 5000,
  toggle,
  color = 'success',
}: ToastInterface) {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;

  useEffect(() => {
    const timeout = setTimeout(() => {
      toggle(false);
    }, timeoutCountdown);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeoutCountdown, toggle]);

  return createPortal(
    isOpen && (
      <ToastStyle isMobile={isMobile} color={color}>
        <CloseIconButtonStyle
          onClick={() => toggle(false)}
          color="white"
          icon={{
            ...CloseIconProps,
            width: 10,
            height: 10,
          }}
        />

        <Flex gap={8}>
          <Text component="H6TextStyle" text={message.title} />
          {message.description && (
            <Text component="DefaultTextStyle" text={message.description} />
          )}
        </Flex>
      </ToastStyle>
    ),
    document.body,
  );
}
