import React from 'react';
import { Text, Paper } from '@captalys-platform/core';
import { errorSubtitleProps, errorTitleProps, errorPaperProps } from './props';
import { ErrorInterface } from './interface';
import { ErrorStyle } from './style';

export const ErrorComponent = ({ dark }: ErrorInterface) => (
  <ErrorStyle dark={dark}>
    <Paper {...errorPaperProps}>
      <Text {...errorTitleProps} text="Eita!" />
      <Text {...errorSubtitleProps} text="Algo inesperado aconteceu aqui." />
    </Paper>
  </ErrorStyle>
);
