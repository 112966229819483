import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { Link } from '@captalys-platform/core';
import { LoadingComponent } from '../../Components/Loading';
import { FeedbackComponent } from '../../Components/Feedback';
import { ErrorComponent } from '../../Components/Error';
import { CreditSubscriptionComponent } from '../../Components/CreditSubscription';
import { Environment } from '../../Relay/enviroment';
import { CreditPageComponent } from './component';
import { ROUTES_PATH } from '../../constants';
import { CreditQuery } from '../../__generated__/CreditQuery.graphql';

export * from './style';

const creditQuery = graphql`
  query CreditQuery {
    viewer {
      checkpoints
      simulation {
        minVolume
        maxVolume
      }
    }
  }
`;

export const CreditPage = () => {
  return (
    <QueryRenderer<CreditQuery>
      environment={Environment}
      fetchPolicy="store-and-network"
      query={creditQuery}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <ErrorComponent error={error} />;
        }

        if (!props || !props.viewer) {
          return <LoadingComponent />;
        }

        const simulation = props?.viewer?.simulation || null;
        const checkpoints = props?.viewer?.checkpoints || [];

        if (simulation && simulation.maxVolume === 0) {
          return (
            <FeedbackComponent
              title="Ops!"
              subtitle="Por enquanto, não conseguimos liberar um limite de crédito para sua empresa. No entanto, essa situação pode mudar nos próximos meses e, se isso acontecer, entraremos em contato com novidades."
            />
          );
        }

        const simulationMinVolume = simulation?.minVolume || 0;
        const simulationMaxVolume = simulation?.maxVolume || 0;

        return (
          <CreditPageComponent
            minVolume={simulationMinVolume}
            maxVolume={simulationMaxVolume}
            checkpoints={checkpoints}
          />
        );
      }}
    />
  );
};
