/* tslint:disable */
/* eslint-disable */
/* @relayHash c70670c66333507364c30366128570b0 */

import { ConcreteRequest } from "relay-runtime";
export type CheckpointEnum = "ACEITE_PROPOSTA" | "ACEITE_TERMOS" | "ACOMPANHAMENTO" | "ANALISE" | "CONTRATO_GERADO" | "DECLINADO" | "DOCUMENTOS" | "PROPOSTA_ESCOLHIDA" | "SOBRE_EMPRESA" | "SOBRE_SOCIOS";
export type SidebarQueryVariables = {};
export type SidebarQueryResponse = {
    readonly viewer: {
        readonly chosenOffer: {
            readonly amountDue: number | null;
            readonly deadline: number | null;
            readonly tax: number | null;
            readonly cet: number | null;
            readonly installmentValue: number | null;
        } | null;
        readonly checkpoints: ReadonlyArray<CheckpointEnum | null> | null;
    } | null;
};
export type SidebarQuery = {
    readonly response: SidebarQueryResponse;
    readonly variables: SidebarQueryVariables;
};



/*
query SidebarQuery {
  viewer {
    chosenOffer {
      amountDue
      deadline
      tax
      cet
      installmentValue
      id
    }
    checkpoints
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amountDue",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deadline",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tax",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cet",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "installmentValue",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "checkpoints",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SidebarQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "chosenOffer",
            "storageKey": null,
            "args": null,
            "concreteType": "Offer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SidebarQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "chosenOffer",
            "storageKey": null,
            "args": null,
            "concreteType": "Offer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ]
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SidebarQuery",
    "id": null,
    "text": "query SidebarQuery {\n  viewer {\n    chosenOffer {\n      amountDue\n      deadline\n      tax\n      cet\n      installmentValue\n      id\n    }\n    checkpoints\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ec78b1045075f363e898475ed71d47df';
export default node;
