import React, { useState } from 'react';
import { Route, useHistory } from 'react-router';
import { formatToBRL } from 'brazilian-values';

import { CreditPageComponentProps } from './interface';
import { ROUTES_PATH, SESSION_STORAGE_KEYS } from '../../constants';
import { Slider } from '../../Components/Slider';
import { CreditChooseInstallments } from '../CreditChooseInstallments';
import { CreditChooseVolume } from '../CreditChooseVolume';
import { getItemOnSessionStorage, checkpointsIncludes } from '../../Helpers';
import { useAccessFlow } from '../../Utils/useAccessFlow';

export const CreditPageComponent = ({
  checkpoints,
  minVolume,
  maxVolume,
}: CreditPageComponentProps) => {
  const { push } = useHistory();

  useAccessFlow(checkpoints);

  const sessionStorageChosenVolume: number = getItemOnSessionStorage(
    SESSION_STORAGE_KEYS.offer.chosenVolume,
  );

  const [chosenVolume, setChosenVolme] = useState(
    sessionStorageChosenVolume ||
      Number(((minVolume + maxVolume) / 2).toFixed(2)),
  );

  if (!checkpointsIncludes(checkpoints || [])('ACEITE_TERMOS')) {
    push(ROUTES_PATH.WELCOME);
  }

  return (
    <>
      <Route path={ROUTES_PATH.CREDIT} exact>
        <CreditChooseVolume chosenVolume={chosenVolume}>
          <Slider
            minVolume={minVolume}
            maxVolume={maxVolume}
            onChangeRangeEnd={setChosenVolme}
            starterValue={chosenVolume}
            formatter={formatToBRL}
          />
        </CreditChooseVolume>
      </Route>
      <Route path={ROUTES_PATH.CREDIT_INSTALLMENTS}>
        <CreditChooseInstallments chosenVolume={chosenVolume} />
      </Route>
    </>
  );
};
