import { useEffect, useState, useCallback } from 'react';
import { fetchQuery, Environment, GraphQLTaggedNode } from 'relay-runtime';

import { USER_SESSION_TOKEN } from '../constants';

export type UseQueryResponseType<QueryResponse> = {
  isLoading: boolean;
  error: any;
  data: QueryResponse;
  refetch: () => Promise<void>;
};

export function useQuery<QueryResponse, Variables = Record<string, unknown>>(
  query: GraphQLTaggedNode,
  env: Environment,
  variablesArg?: Variables,
): UseQueryResponseType<QueryResponse> {
  const variables = variablesArg || {};

  const [content, setContent] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const fetch = useCallback(() => {
    setContent(c => ({ ...c, isLoading: true }));

    return fetchQuery(env, query, variables)
      .then(data => {
        setContent(c => ({ ...c, isLoading: false, data }));
      })
      .catch(error => {
        setContent(c => ({ ...c, isLoading: false, error }));
      });
  }, [env, query, variables]);

  useEffect(() => {
    if (window.localStorage.getItem(USER_SESSION_TOKEN)) {
      fetch();
    } else {
      setContent(c => ({
        ...c,
        isLoading: false,
        error: true,
        data: { viewer: { checkpoints: [] } },
      }));
    }
  }, []);

  return {
    ...content,
    refetch: fetch,
  };
}
