import styled from 'styled-components';
import { Button, Paper, DefaultTextStyle, Link } from '@captalys-platform/core';

export const CloseLinkStyle = styled(Link)`
  position: absolute;
  right: 13px;
  top: 13px;
`;

export const ButtonStyle = styled(Button)<{
  outline?: boolean;
}>`
  ${({ outline, theme }) =>
    !outline &&
    `
    ${DefaultTextStyle} {
      color: ${theme.colors.white}
    }
  `}

  padding: 10px 30px;
`;

export const PaperStyle = styled(Paper)`
  position: relative;
  border-radius: 8px;
  padding: 32px;
  height: 100%;
`;
