import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router';
import { createBreakpoints } from '@captalys-platform/core';

import { ContainerStyle, OverlayStyle, FlexStyle } from './style';
import { OverlaySidebarProps } from './interface';

import { useWindowWidth } from '../../Utils';
import { ROUTES_PATH } from '../../constants';
import { TopbarComponent } from '../Topbar';

export const OverlaySidebar = ({
  overlayedRoute,
  closeOnOverlayClick = true,
  hideTopbarBars = false,
  children,
}: OverlaySidebarProps) => {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const { push } = useHistory();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = null;
    };
  }, []);

  const closeDialog = () => {
    push(ROUTES_PATH[overlayedRoute]);
  };

  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (e.target === e.currentTarget && closeOnOverlayClick) closeDialog();
  };

  return createPortal(
    <FlexStyle position="fixed" width="100%" height="100%">
      <OverlayStyle
        position="absolute"
        height="100%"
        width="100%"
        bgColor="primary"
        onClick={onOverlayClick}
      />
      <TopbarComponent hideBars={hideTopbarBars} />
      <ContainerStyle
        padding={isMobile ? 0 : '32px'}
        height="100%"
        position="absolute"
        bgColor="white"
        overflow="hidden auto"
        isMobile={isMobile}
      >
        {children}
      </ContainerStyle>
    </FlexStyle>,
    document.body,
  );
};
