import {
  DefaultGridInterface,
  DefaultFlexInterface,
} from '@captalys-platform/core';

export const appRoutesProps: DefaultGridInterface = {
  medias: {
    xs: {
      columns: '100%',
      rowGap: 16,
      areas: `
        "Header"
        "Content"
        "Footer"
      `,
      rows: 'auto 1fr 100px',
    },
  },
};

export const contentRoutesProps: DefaultFlexInterface = {
  container: true,
  direction: 'row',
  width: '100%',
  area: 'Content',
  gap: 4,
  justifyContent: 'center',
};

export const mainRoutesProps: DefaultFlexInterface & { as: string } = {
  as: 'main',
  grow: 2,
  maxWidth: '100%',
};
