export const BarsIconProps = {
  viewBox: '0 0 103 82',
  width: 30,
  height: 30,
  paths: [
    {
      d:
        'M98,10H5A5,5,0,0,1,0,5H0A5,5,0,0,1,5,0H98a5,5,0,0,1,5,5h0A5,5,0,0,1,98,10Zm5,31h0a5,5,0,0,0-5-5H5a5,5,0,0,0-5,5H0a5,5,0,0,0,5,5H98A5,5,0,0,0,103,41ZM69,77h0a5,5,0,0,0-5-5H5a5,5,0,0,0-5,5H0a5,5,0,0,0,5,5H64A5,5,0,0,0,69,77Z',
    },
  ],
};
