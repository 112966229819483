import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { graphql } from 'relay-runtime';
import { createBreakpoints, Flex, Text } from '@captalys-platform/core';

import { ButtonStyle } from './style';
import { StatusCodeType } from './interface';
import {
  getResendCodeButtonText,
  getSubmitButtonText,
  getStatusCodeData,
  getToastData,
} from './props';

import { VerifyEmailQueryResponse } from '../../__generated__/VerifyEmailQuery.graphql';
import { Environment, MutationID } from '../../Relay/enviroment';
import {
  genVerificationCode,
  saveCheckpoint,
  verifyCode,
} from '../../Relay/Mutations';
import { useWindowWidth, useQuery } from '../../Utils';
import { ROUTES_PATH } from '../../constants';
import { Spinner } from '../Spinner';
import { InputConfirmationCode } from '../InputConfirmationCode';
import { ToastComponent } from '../Toast';

const textFeedback = 'Enviamos para você um token de confirmação em seu email';

const isCodeComplete = (inputCode: string) =>
  inputCode && inputCode.length === 6;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const VerifyEmail = () => {
  const { isLoading, data } = useQuery<VerifyEmailQueryResponse>(
    graphql`
      query VerifyEmailQuery {
        viewer {
          me {
            emailVerified
            email
          }
        }
      }
    `,
    Environment,
  );

  const { push } = useHistory();
  const [isGeneratingEmailToken, setIsGeneratingSmsToken] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusCode, setStatusCode] = useState<StatusCodeType>(null);
  const [toastData, setToastData] = useState(() =>
    getToastData(data, statusCode),
  );
  const [inputCode, setInputCode] = useState<string>(null);

  const generateSmsCode = async (isResending = false) => {
    try {
      setIsGeneratingSmsToken(true);

      await genVerificationCode({
        clientMutationId: MutationID,
        platform: 'EMAIL',
      });
    } finally {
      setIsGeneratingSmsToken(false);
      setStatusCode(null);
      setIsToastOpen(isResending);
    }
  };

  useEffect(() => {
    if (!isLoading && data.viewer) {
      if (data.viewer.me.emailVerified) {
        push(ROUTES_PATH.ORDER_REVIEW);
        return;
      }

      generateSmsCode();
    }
  }, [data, isLoading]);

  useEffect(() => {
    setToastData(() => getToastData(data, statusCode));
  }, [statusCode]);

  const isDesktop = useWindowWidth() > createBreakpoints().values.md;

  const onSubmitClick = async () => {
    if (isSubmitting) {
      return;
    }

    try {
      setIsSubmitting(true);

      const {
        verifyCode: { response },
      } = await verifyCode({
        clientMutationId: MutationID,
        code: inputCode,
        platform: 'EMAIL',
      });

      const statusCodeData = getStatusCodeData(response.statusCode);

      if (statusCodeData.hasError) {
        setStatusCode(statusCodeData.errorType);
        setIsToastOpen(true);
        setIsSubmitting(false);

        return;
      }

      saveCheckpoint({
        clientMutationId: MutationID,
        checkpoint: 'SOBRE_EMPRESA',
      });

      push(ROUTES_PATH.ORDER_REVIEW);
    } catch (err) {
      setIsToastOpen(true);
      setIsSubmitting(false);
    }
  };

  if (isLoading || isGeneratingEmailToken) {
    return (
      <Flex height="70vh" alignItems="center" justifyContent="center">
        <Spinner dark={false} />
      </Flex>
    );
  }

  return (
    <>
      <Flex direction="column" gap={30}>
        <Flex gap={8} direction="column">
          <Text component="H4TextStyle" text={textFeedback} />
        </Flex>
        <InputConfirmationCode onCodeChange={setInputCode} />

        <Flex gap={8} margin={!isDesktop ? '0 0 60px 0' : '60px 0 0 0'}>
          <ButtonStyle
            onClick={onSubmitClick}
            disabled={!isCodeComplete(inputCode) || isSubmitting}
            text={getSubmitButtonText(isSubmitting)}
            full={!isDesktop}
            color="primary"
          />
          <ButtonStyle
            disabled={isGeneratingEmailToken}
            onClick={() => generateSmsCode(true)}
            text={getResendCodeButtonText(isGeneratingEmailToken)}
            outline
            full={!isDesktop}
            color="primary"
          />
        </Flex>
      </Flex>
      <ToastComponent
        message={{
          title: toastData.message.title,
          description: toastData.message.description,
        }}
        color={toastData.color}
        isOpen={isToastOpen}
        toggle={() => {
          setIsToastOpen(false);
        }}
      />
    </>
  );
};
