/* tslint:disable */
/* eslint-disable */
/* @relayHash df16ac2abe8eeb3c96b54834e2d7aec8 */

import { ConcreteRequest } from "relay-runtime";
export type VerifyEmailQueryVariables = {};
export type VerifyEmailQueryResponse = {
    readonly viewer: {
        readonly me: {
            readonly emailVerified: boolean | null;
            readonly email: string | null;
        } | null;
    } | null;
};
export type VerifyEmailQuery = {
    readonly response: VerifyEmailQueryResponse;
    readonly variables: VerifyEmailQueryVariables;
};



/*
query VerifyEmailQuery {
  viewer {
    me {
      emailVerified
      email
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "emailVerified",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VerifyEmailQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VerifyEmailQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VerifyEmailQuery",
    "id": null,
    "text": "query VerifyEmailQuery {\n  viewer {\n    me {\n      emailVerified\n      email\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b6ae2e1567b36ffb83e4b8a2e2ef80eb';
export default node;
