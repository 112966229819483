export const ArrowLeftIconProps = {
  viewBox: '0 0 305 303',
  width: 30,
  height: 30,
  paths: [
    {
      d: 'M0,152,152,0l27,27L75,132H305v40L75,171,179,277l-27,26Z',
    },
  ],
};
