import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { createBreakpoints } from '@captalys-platform/core';

import { Environment } from '../../Relay/enviroment';
import { LoadingComponent } from '../../Components/Loading';
import { ErrorComponent } from '../../Components/Error';
import { InfoFragment } from './component';
import { useWindowWidth } from '../../Utils';
import { InfoQuery } from '../../__generated__/InfoQuery.graphql';

export const InfoPage = () => {
  const isDesktop = useWindowWidth() > createBreakpoints().values.md;

  const infoQuery = graphql`
    query InfoQuery {
      viewer {
        checkpoints
        me {
          emailVerified
        }
        myCompany {
          bank {
            account
            bankAgency
            bankName
          }
          phone {
            fullNumber
          }
          representatives {
            id
            cpf
            name
            email
          }
        }
      }
    }
  `;

  return (
    <QueryRenderer<InfoQuery>
      environment={Environment}
      fetchPolicy="store-and-network"
      query={infoQuery}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <ErrorComponent error={error} />;
        }
        if (!props || !props.viewer) {
          return <LoadingComponent dark={!isDesktop} />;
        }

        const checkpoints = props?.viewer?.checkpoints || [];
        const myCompany = props?.viewer?.myCompany || null;
        const me = props?.viewer?.me || null;

        return (
          <InfoFragment
            checkpoints={checkpoints}
            me={me}
            myCompany={myCompany}
          />
        );
      }}
    />
  );
};
