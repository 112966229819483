import styled from 'styled-components';
import {
  H5TextStyle,
  DefaultTextStyle,
  H3TextStyle,
  Paper,
  Flex,
} from '@captalys-platform/core';

export const CheckIconWrapper = styled(Flex)`
  > ${H5TextStyle} {
    color: ${({ theme }) => theme.colors[600]} !important;
  }
`;

export const CreditOfferItemStyle = styled(Paper)`
  overflow: hidden;
  transition: background-color 0.3s;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.darker};
  background-color: transparent;
  width: 60vw;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 260px;
    width: auto;
    min-width: 220px;
  }

  ${DefaultTextStyle} {
    color: ${({ theme }) => theme.colors[800]};
    white-space: nowrap;
    text-align: center;
  }

  ${H3TextStyle} {
    color: ${({ theme }) => theme.colors[800]};

    + ${DefaultTextStyle} {
      position: relative;
      width: 100%;

      &::after {
        content: '';
        display: block;
        position: relative;
        width: calc(100% + 64px);
        left: -32px;
        height: 1px;
        margin: 16px 0;
        background-color: ${({ theme }) => theme.colors[300]};
      }
    }
  }
`;
