/* tslint:disable */
/* eslint-disable */
/* @relayHash 74bf7f7c8c3b90414d967abfb8a36e3b */

import { ConcreteRequest } from "relay-runtime";
export type CheckpointEnum = "ACEITE_PROPOSTA" | "ACEITE_TERMOS" | "ACOMPANHAMENTO" | "ANALISE" | "CONTRATO_GERADO" | "DECLINADO" | "DOCUMENTOS" | "PROPOSTA_ESCOLHIDA" | "SOBRE_EMPRESA" | "SOBRE_SOCIOS";
export type SAPaymentStatus = "OVERDUE" | "PAID" | "PENDING";
export type StatementQueryVariables = {};
export type StatementQueryResponse = {
    readonly viewer: {
        readonly checkpoints: ReadonlyArray<CheckpointEnum | null> | null;
        readonly saOperation: {
            readonly futureValue: number | null;
            readonly balanceDue: number | null;
            readonly installmentsTotalCount: number | null;
            readonly amountPaid: number | null;
            readonly installmentsPaidCount: number | null;
            readonly installments: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly closed: boolean | null;
                        readonly dueDate: unknown | null;
                        readonly status: string | null;
                        readonly paymentStatus: SAPaymentStatus | null;
                        readonly pmt: number | null;
                        readonly nominalValue: number | null;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};
export type StatementQuery = {
    readonly response: StatementQueryResponse;
    readonly variables: StatementQueryVariables;
};



/*
query StatementQuery {
  viewer {
    checkpoints
    saOperation {
      futureValue
      balanceDue
      installmentsTotalCount
      amountPaid
      installmentsPaidCount
      installments {
        edges {
          node {
            id
            closed
            dueDate
            status
            paymentStatus
            pmt
            nominalValue
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "checkpoints",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "saOperation",
  "storageKey": null,
  "args": null,
  "concreteType": "SAOperation",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "futureValue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "balanceDue",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "installmentsTotalCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amountPaid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "installmentsPaidCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "installments",
      "storageKey": null,
      "args": null,
      "concreteType": "SAInstallmentConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SAInstallmentEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SAInstallment",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "closed",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "dueDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "paymentStatus",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "pmt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "nominalValue",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StatementQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StatementQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StatementQuery",
    "id": null,
    "text": "query StatementQuery {\n  viewer {\n    checkpoints\n    saOperation {\n      futureValue\n      balanceDue\n      installmentsTotalCount\n      amountPaid\n      installmentsPaidCount\n      installments {\n        edges {\n          node {\n            id\n            closed\n            dueDate\n            status\n            paymentStatus\n            pmt\n            nominalValue\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '674ca75b5ba091f16ec274b69305b281';
export default node;
