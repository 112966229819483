import { Button, DefaultTextStyle } from '@captalys-platform/core';
import styled from 'styled-components';

export const LinkButtonStyle = styled(Button)`
  background: none;
  padding: 0;
  outline: none;
  border: none;

  &:hover {
    background: none;

    ${DefaultTextStyle} {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  ${DefaultTextStyle} {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.blue};
  }
`;
