/* tslint:disable */
/* eslint-disable */
/* @relayHash c1a4c5ca6b98f9b657086fe2efa13f63 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VerificationPlatform = "EMAIL" | "PHONE";
export type VerifyCodeInput = {
    platform: VerificationPlatform;
    code: string;
    clientMutationId: string;
};
export type VerifyCodeMutationVariables = {
    input: VerifyCodeInput;
};
export type VerifyCodeMutationResponse = {
    readonly verifyCode: {
        readonly clientMutationId: string;
        readonly response: {
            readonly status: boolean;
            readonly statusCode: number;
            readonly " $fragmentRefs": FragmentRefs<"Response_message">;
        } | null;
    };
};
export type VerifyCodeMutation = {
    readonly response: VerifyCodeMutationResponse;
    readonly variables: VerifyCodeMutationVariables;
};



/*
mutation VerifyCodeMutation(
  $input: VerifyCodeInput!
) {
  verifyCode(input: $input) {
    clientMutationId
    response {
      status
      statusCode
      ...Response_message
    }
  }
}

fragment Response_message on Response {
  status
  statusCode
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "VerifyCodeInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusCode",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VerifyCodeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "verifyCode",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyCodePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "Response_message",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VerifyCodeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "verifyCode",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyCodePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "VerifyCodeMutation",
    "id": null,
    "text": "mutation VerifyCodeMutation(\n  $input: VerifyCodeInput!\n) {\n  verifyCode(input: $input) {\n    clientMutationId\n    response {\n      status\n      statusCode\n      ...Response_message\n    }\n  }\n}\n\nfragment Response_message on Response {\n  status\n  statusCode\n  message\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4d10cbf5a83db449e63a7081ba96d5a2';
export default node;
