import React from 'react';
import { Flex, CheckCircleIcon, AngleRightIcon } from '@captalys-platform/core';
import { SidebarStepsItemBarStyle, SidebarStepsItemLinkStyle } from './style';
import { SidebarStepsItemComponentProps } from './interface';

export const SidebarStepsItemComponent = ({
  completed,
  onClick,
  children,
  text,
  disabled,
}: SidebarStepsItemComponentProps) => (
  <Flex
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    gap={8}
  >
    <SidebarStepsItemBarStyle completed={completed} alignItems="center">
      <CheckCircleIcon width="20px" height="20px" />
    </SidebarStepsItemBarStyle>

    <Flex>
      <Flex direction="row" gap={8}>
        <SidebarStepsItemLinkStyle
          component="H5TextStyle"
          disabled={disabled}
          onClick={disabled ? () => null : onClick}
          text={text}
          color={disabled ? '300' : 'primary'}
        />
        {completed && (
          <AngleRightIcon fill="primary" width="12px" height="12px" />
        )}
      </Flex>

      {completed && (
        <Flex margin="8px 0 0 0" gap={8}>
          {children}
        </Flex>
      )}
    </Flex>
  </Flex>
);
