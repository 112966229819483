import styled from 'styled-components';
import { Grid, Flex } from '@captalys-platform/core';
import { appRoutesProps, mainRoutesProps } from './props';

export const AppRoutesStyle = styled(Grid).attrs(appRoutesProps)<{
  dark: boolean;
}>`
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ dark, theme }) =>
    !dark &&
    `
    ${theme.breakpoints.up('md')} {
      background-color: ${theme.colors.white};
    }
  `}
`;

export const MainRoutesStyle = styled(Flex).attrs(mainRoutesProps)<any>``;
