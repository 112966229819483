import React from 'react';
import { Flex } from '@captalys-platform/core';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'relay-runtime';
import { Environment } from '../../Relay/enviroment';
import { ErrorComponent } from '../Error';
import { LoadingComponent } from '../Loading';

import { StatementTableQuery } from '../../__generated__/StatementTableQuery.graphql';

import { StatementTableView } from '../StatementTableView';

import { StatementTableInteface } from './interface';

const statementTableQuery = graphql`
  query StatementTableQuery(
    $count: Int!
    $after: String
    $input: SAOperationFilterInput
  ) {
    viewer {
      ...StatementTableView_viewer
    }
  }
`;

export const StatementTable = ({ status }: StatementTableInteface) => {
  const input = status ? { paymentStatus: status } : null;

  return (
    <QueryRenderer<StatementTableQuery>
      environment={Environment}
      fetchPolicy="store-and-network"
      variables={{
        input,
        count: 3,
      }}
      query={statementTableQuery}
      render={({ props, error }) => {
        if (error) return <ErrorComponent error={error} />;

        if (!props || !props.viewer)
          return (
            <Flex height="170px">
              <LoadingComponent />
            </Flex>
          );

        const viewer = props?.viewer || null;

        return <StatementTableView viewer={viewer} />;
      }}
    />
  );
};
