import React, { useState } from 'react';

import {
  Grid,
  Flex,
  Text,
  Paper,
  createBreakpoints,
  Button,
  Modal,
} from '@captalys-platform/core';

import { formatToBRL } from 'brazilian-values';

import { gridMedia, isInstallmentFromCurrentMonth, formatDate } from './props';
import { useWindowWidth, useAccessFlow } from '../../Utils';

import {
  BankSlipInterface,
  OperationInterface,
  BankSlipListInterface,
  PaymentsInterface,
} from './interface';

import { TextStyle } from './styles';
import { ButtonStyle } from '../Credit';

import { StatementList } from '../../Components/StatementList';
import { StatementQueryResponse } from '../../__generated__/StatementQuery.graphql';

const medias = gridMedia();

export function StatementComponent({
  checkpoints,
  saOperation,
}: StatementQueryResponse['viewer']) {
  const [isStatementListOpen, setIsStatementListOpen] = useState(false);
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;

  useAccessFlow(checkpoints);

  return (
    <Flex>
      <Flex>
        <Grid medias={medias}>
          {!isMobile && (
            <>
              <Flex area="table">
                <StatementList balanceDue={saOperation.balanceDue} />
              </Flex>
              <Operation balanceDue={saOperation.balanceDue} />
            </>
          )}
          <Payments
            futureValue={saOperation.futureValue}
            amountPaid={saOperation.amountPaid}
            installmentsPaidCount={saOperation.installmentsPaidCount}
            installmentsTotalCount={saOperation.installmentsTotalCount}
          />
          <BankSlipList installments={saOperation.installments.edges} />
        </Grid>
        {isMobile && (
          <>
            <Modal
              hideCloseButton
              toggle={() => setIsStatementListOpen(false)}
              isOpen={isStatementListOpen}
            >
              {isStatementListOpen && (
                <StatementList
                  onBackButtonClick={() => setIsStatementListOpen(false)}
                  balanceDue={saOperation.balanceDue}
                />
              )}
            </Modal>
            <Flex
              justifyContent="center"
              alignItems="center"
              padding="0 30px"
              margin="32px 0 0 0"
            >
              <Button
                text="Ver Extrato"
                color="primary"
                outline
                full
                onClick={() => setIsStatementListOpen(true)}
              />
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}

const Payments = ({
  futureValue,
  installmentsPaidCount,
  installmentsTotalCount,
  amountPaid,
}: PaymentsInterface) => {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;

  const payments = `${installmentsPaidCount} / ${installmentsTotalCount}`;

  return (
    <>
      <Flex area="payment">
        <Paper shadow>
          <Flex
            padding="0 20px"
            gap={isMobile ? 6 : 12}
            direction={isMobile ? 'row' : 'column'}
            alignItems={isMobile ? 'center' : 'flex-start'}
            justifyContent={isMobile ? 'space-between' : 'flex-start'}
          >
            <Text component="DefaultTextStyle" text="Pagamentos" />
            <TextStyle component="H3TextStyle" text={payments} />
          </Flex>
        </Paper>
      </Flex>
      <Flex area="paidValue">
        <Paper shadow>
          <Flex gap={isMobile ? 6 : 12} padding={!isMobile && '0 20px'}>
            <Text
              component="DefaultTextStyle"
              text="Valor pago"
              align={isMobile ? 'center' : 'left'}
            />
            <TextStyle
              component="H3TextStyle"
              text={formatToBRL(amountPaid)}
              align={isMobile ? 'center' : 'left'}
            />
          </Flex>
        </Paper>
      </Flex>
      <Flex area="amountInDebt">
        <Paper shadow>
          <Flex gap={isMobile ? 6 : 12} padding={!isMobile && '0 20px'}>
            <Text
              component="DefaultTextStyle"
              text="Valor total devido"
              align={isMobile ? 'center' : 'left'}
            />
            <TextStyle
              component="H3TextStyle"
              text={formatToBRL(futureValue)}
              align={isMobile ? 'center' : 'left'}
            />
          </Flex>
        </Paper>
      </Flex>
    </>
  );
};

const BankSlipList = ({ installments }: BankSlipListInterface) => {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;

  const isExpiredOrCurrentMonth = ({
    node: { dueDate, closed, paymentStatus },
  }: BankSlipListInterface['installments'][number]) => {
    return (
      (paymentStatus === 'OVERDUE' && closed) ||
      (paymentStatus === 'PENDING' &&
        isInstallmentFromCurrentMonth(`${dueDate}`))
    );
  };

  return (
    <Flex
      area="currentBankSlip"
      padding={!isMobile && '48px 100px 0 0'}
      gap={24}
    >
      {installments
        .slice()
        .filter(installment => isExpiredOrCurrentMonth(installment))
        .map(({ node }) => (
          <Paper shadow key={node.id}>
            <BankSlip {...node} />
          </Paper>
        ))}
    </Flex>
  );
};

const Operation = ({ balanceDue }: OperationInterface) => {
  return (
    <Flex area="operation" gap={6}>
      <Text component="DefaultTextStyle" text="Saldo em aberto" />
      <TextStyle component="H4TextStyle" text={formatToBRL(balanceDue)} />
    </Flex>
  );
};

const BankSlip = ({
  dueDate,
  nominalValue,
  paymentStatus,
  pmt,
}: BankSlipInterface) => {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const isExpired = paymentStatus === 'OVERDUE';

  return (
    <Flex padding="12px">
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        margin={isMobile ? '0 0 20px 0' : '0 0 32px 0'}
      >
        <Text component="H5TextStyle" text={`Boleto ${pmt}`} />

        <Flex>
          <Text component="LabelTextStyle" text="Data lançada" />
          <Text component="LabelTextStyle" text={formatDate(`${dueDate}`)} />
        </Flex>
      </Flex>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Flex direction="row" gap={4}>
          <Text
            color={isExpired ? 'danger' : null}
            component={isExpired ? 'StrongTextStyle' : 'DefaultTextStyle'}
            text={isExpired ? 'Vencido' : 'Validade:'}
          />
          <Text
            color={isExpired ? 'danger' : null}
            component="StrongTextStyle"
            text={formatDate(`${dueDate}`)}
          />
        </Flex>
        <TextStyle component="H2TextStyle" text={formatToBRL(nominalValue)} />
      </Flex>
    </Flex>
  );
};
