import React from 'react';
import { Flex, createBreakpoints } from '@captalys-platform/core';
import { useWindowWidth } from '../../Utils';
import { FlexStyle, WrapperLogoSulAmerica } from './style';
import { NavbarInterface } from './interface';
import { BarsIcon, LogoSulAmerica } from '../../Icons';

export const NavbarComponent = ({
  children,
  onCloseMenu,
}: NavbarInterface & any) => {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const closeMenu = () => onCloseMenu(false);

  return (
    <FlexStyle bgColor="white">
      <Flex
        justifyContent="center"
        alignItems="flex-end"
        height="116px"
        padding="0 16px"
      >
        {isMobile && <BarsIcon fill="primary" onClick={() => closeMenu()} />}
      </Flex>
      <Flex direction="column" alignItems="center">
        <WrapperLogoSulAmerica
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          onClick={closeMenu}
        >
          <LogoSulAmerica width="155px" height="70px" />
        </WrapperLogoSulAmerica>
        <Flex alignItems="center" gap={20}>
          {children}
        </Flex>
      </Flex>
    </FlexStyle>
  );
};
