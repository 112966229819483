import styled from 'styled-components';

import {
  DefaultButtonStyle,
  SvgStyle,
  Flex,
  Text,
  InputCheckboxLabelStyle,
  LinkButtonStyle,
  InputCheckbox,
} from '@captalys-platform/core';

export const WelcomeStyle = styled(Flex)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
  }
`;

export const ItemWelcomeStyle = styled(Flex)`
  ${DefaultButtonStyle} {
    width: 150px;
  }

  /**
   * First Child must be Text White color
   */
  &:first-child {
    ${InputCheckboxLabelStyle} {
      width: 100%;
    }

    /**
     * Must draw Vertical Line on Desktop Layout
     */
    ${({ theme }) => theme.breakpoints.up('md')} {
      width: 340px;
    }
  }

  /**
   * Last Child Must be Text Lighter
   */
  &:last-child {
    ${({ theme }) => theme.breakpoints.up('md')} {
      width: 340px;
      margin-left: 60px;
    }

    ${SvgStyle} {
      fill: ${({ theme }) => theme.colors[100]};
    }
  }
`;

export const CheckboxLabelWelcomeStyle = styled(Text)`
  z-index: 9;

  ${LinkButtonStyle} {
    z-index: 10;
  }
`;

export const InputCheckboxStyle = styled(InputCheckbox)`
  align-items: center;
`;
