import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { createBreakpoints } from '@captalys-platform/core';
import { OrderReviewComponent } from './component';
import { LoadingComponent } from '../../Components/Loading';
import { ErrorComponent } from '../../Components/Error';
import { Environment } from '../../Relay/enviroment';
import { useWindowWidth } from '../../Utils';

import { OrderReviewQuery } from '../../__generated__/OrderReviewQuery.graphql';

export const OrderReviewPage = () => {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;

  const orderReviewQuery = graphql`
    query OrderReviewQuery {
      viewer {
        checkpoints
        paymentMethod {
          method
        }
        me {
          name
        }
        chosenOffer {
          amountDue
          tax
          hireValue
          iof
          fixedCost
          creditValue
          installmentValue
          deadline
          cet
        }
        proposalData {
          planCode
          planName
          productCode
          warranty
        }
      }
    }
  `;

  return (
    <QueryRenderer<OrderReviewQuery>
      environment={Environment}
      fetchPolicy="store-and-network"
      variables={{}}
      query={orderReviewQuery}
      render={({ error, props }) => {
        if (error) {
          return <ErrorComponent error={error} />;
        }

        if (!props || !props.viewer)
          return <LoadingComponent dark={isMobile} />;

        const chosenOffer = props?.viewer?.chosenOffer || null;
        const proposalData = props?.viewer?.proposalData || null;
        const checkpoints = props?.viewer?.checkpoints || [];
        const me = props?.viewer?.me || null;
        const paymentMethod = props?.viewer?.paymentMethod || null;

        return (
          <OrderReviewComponent
            chosenOffer={chosenOffer}
            proposalData={proposalData}
            checkpoints={checkpoints}
            me={me}
            paymentMethod={paymentMethod}
          />
        );
      }}
    />
  );
};
