import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Flex, Text, Link } from '@captalys-platform/core';

import { WelcomeInterface } from './interface';

import {
  ItemWelcomeStyle,
  WelcomeStyle,
  CheckboxLabelWelcomeStyle,
  InputCheckboxStyle,
} from './style';
import { TERMS_PDF, ROUTES_PATH, LGPD_TERMS_PDF } from '../../constants';

import { ButtonStyle } from '../Credit';

import { saveCheckpoint } from '../../Relay/Mutations';
import { MutationID } from '../../Relay/enviroment';
import { checkpointsIncludes } from '../../Helpers';

export const WelcomePageView = ({ me, checkpoints }: WelcomeInterface) => {
  const { push } = useHistory();

  const alreadyChecked = checkpointsIncludes(checkpoints);
  const alreadyAcceptedTerms = alreadyChecked('ACEITE_TERMOS');
  const alreadyChoosedPropposal = alreadyChecked('PROPOSTA_ESCOLHIDA');

  const [isTermsAndConditionsAccepted, toggleTermsAndConditions] = useState(
    false,
  );

  const userName = me?.name
    ? `${me.name.split(' ')[0]} ${me.name.split(' ').pop()}`
    : '';

  /**
   * Define witch action should perform
   * based on isMobileDevice and step
   */
  const continueAction = async () => {
    if (!isTermsAndConditionsAccepted) return;

    try {
      // await createRecord({
      //   clientMutationId: MutationID,
      //   sourceUrl: SALESFORCE_SOURCE_URLS.TERMS,
      //   destination: 'TERMS',
      //   integration: 'PAYPAL',
      // });

      // sendEmail({
      //   templateName: 'BEM_VINDO_PAYPAL',
      //   userEmail: me.email,
      //   userName: me.name,
      //   subject: 'Conta criada com sucesso',
      //   clientMutationId: MutationID,
      // });

      await saveCheckpoint({
        clientMutationId: MutationID,
        checkpoint: 'ACEITE_TERMOS',
      });

      push(ROUTES_PATH.CREDIT);
    } catch (e) {
      console.log(e);
    }
  };

  if (alreadyAcceptedTerms) {
    return (
      <Redirect
        to={ROUTES_PATH[alreadyChoosedPropposal ? 'STATUS' : 'CREDIT']}
      />
    );
  }

  return (
    <WelcomeStyle
      container
      direction="row"
      justifyContent="center"
      minHeight="400px"
      height="100%"
      alignItems="center"
    >
      <ItemWelcomeStyle
        justifyContent="space-evenly"
        height="100%"
        minHeight="300px"
        maxHeight="380px"
        padding="0 32px"
        alignItems="center"
        gap={50}
      >
        <Flex as="hgroup" width="100%" alignItems="flex-start">
          <Text component="H4TextStyle" text="Boas Vindas," weight={200} />
          <Text
            capitalize
            component="H1TextStyle"
            text={userName}
            color="700"
          />
        </Flex>
        <InputCheckboxStyle
          width="100%"
          onClick={() => {
            toggleTermsAndConditions(!isTermsAndConditionsAccepted);
          }}
          label={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <CheckboxLabelWelcomeStyle>
              Li, confirmo e aceito os&nbsp;
              <Link
                target="_blank"
                href={TERMS_PDF}
                text="Termos de uso e política de privacidade"
              />
              &nbsp;, &nbsp;
              <Link
                target="_blank"
                href={LGPD_TERMS_PDF}
                text="disposições de proteção de dados."
              />
            </CheckboxLabelWelcomeStyle>
          }
        />

        <Flex direction="row" gap={16}>
          <ButtonStyle
            disabled={!isTermsAndConditionsAccepted}
            onClick={continueAction}
            text="Continuar"
          />
        </Flex>
      </ItemWelcomeStyle>
    </WelcomeStyle>
  );
};
