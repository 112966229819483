export type StatusCodeType = null | 'invalid' | 'expired';

export type StatusCodeDataType = {
  hasError: boolean;
  errorType: StatusCodeType;
};

export enum StatusCodeEnum {
  EXPIRED = 406,
  INVALID = 400,
}
