/* tslint:disable */
/* eslint-disable */
/* @relayHash 2d7019382149808166c02f72ea862cc2 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PaymentMethod = "BOLETO" | "DEBITO_AUTOMATICO";
export type confirmPaymentMethodInput = {
    paymentMethod: PaymentMethod;
    clientMutationId: string;
};
export type confirmPaymentMethodMutationVariables = {
    input: confirmPaymentMethodInput;
};
export type confirmPaymentMethodMutationResponse = {
    readonly confirmPaymentMethod: {
        readonly clientMutationId: string;
        readonly response: {
            readonly status: boolean;
            readonly " $fragmentRefs": FragmentRefs<"Response_message">;
        };
    };
};
export type confirmPaymentMethodMutation = {
    readonly response: confirmPaymentMethodMutationResponse;
    readonly variables: confirmPaymentMethodMutationVariables;
};



/*
mutation confirmPaymentMethodMutation(
  $input: confirmPaymentMethodInput!
) {
  confirmPaymentMethod(input: $input) {
    clientMutationId
    response {
      status
      ...Response_message
    }
  }
}

fragment Response_message on Response {
  status
  statusCode
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "confirmPaymentMethodInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "confirmPaymentMethodMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "confirmPaymentMethod",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "confirmPaymentMethodPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "Response_message",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "confirmPaymentMethodMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "confirmPaymentMethod",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "confirmPaymentMethodPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "statusCode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "confirmPaymentMethodMutation",
    "id": null,
    "text": "mutation confirmPaymentMethodMutation(\n  $input: confirmPaymentMethodInput!\n) {\n  confirmPaymentMethod(input: $input) {\n    clientMutationId\n    response {\n      status\n      ...Response_message\n    }\n  }\n}\n\nfragment Response_message on Response {\n  status\n  statusCode\n  message\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '3b672d0ff3a980251eb515876f7f6133';
export default node;
