import styled from 'styled-components';
import { Flex, Text, SvgStyle } from '@captalys-platform/core';

export const SidebarStepsItemBarStyle = styled(Flex)<{ completed: boolean }>`
  height: 100%;

  ${SvgStyle} {
    fill: ${({ theme, completed }) =>
      theme.colors[completed ? 'success' : 300]};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: calc(100% - 11px);
    width: 1px;
    height: 100%;
    background-color: ${({ theme, completed }) =>
      theme.colors[completed ? 'success' : 300]};
  }
`;

export const SidebarStepsItemLinkStyle = styled(Text)<{ disabled: boolean }>`
  cursor: auto;

  ${({ disabled }) =>
    !disabled &&
    `
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  `}
`;
