import { datadogRum } from '@datadog/browser-rum';
import { version, name as service } from '../../../package.json';

class DatadogClass {
  protected environment?: string;

  protected site?: string;

  protected allowedEnvs: string[] = [
    'local',
    'development',
    'staging',
    'sandbox',
    'production',
  ];

  constructor() {
    this.setEnv();
    this.environment && this.mountDatadogEnvironment();
  }

  protected mountDatadogEnvironment(): void {
    const params = {
      applicationId: '0d56a81d-4151-4258-a8de-d8315cbd6dfc',
      clientToken: 'pub885084d62e13add9125346406cf4484a',
      service,
      version,
      env: this.environment,
      sampleRate: 100,
      trackInteractions: true,
    };

    datadogRum.init(params);
  }

  protected setEnv(): void {
    if (this.allowedEnvs.includes(process.env.NODE_ENV)) {
      this.environment = process.env.NODE_ENV;
    }
  }
}

export default new DatadogClass();
