export const LogoSulAmericaAlternativeProps = {
  viewbox: '0 0 425.197 198.425',
  width: 'auto',
  height: 'auto',
  paths: [
    {
      fill: '#C05131',
      d:
        'M302.609,31.669c38.092-7.907,86.287,15.398,114.143,7.048c1.8-0.54,2.275,0.356-0.431,1.423 c-9.744,3.842-26.364,3.092-52.992,0.377c-55.018-5.604-59.107,5.792-88.449,14.286c-22.999,6.661-36.32,6.404-36.32,6.404 s2.356-1.222,3.606-1.979C242.259,59.175,277.635,36.855,302.609,31.669z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M7.546,40.238c-2.921,0.936,17.315,13.491,66.993,5.068c32.641-5.534,73.415-15.362,102.127,6.903 c36.385,28.215,66.839,6.158,66.69,6.28c0,0-5.78,1.579-34.605-0.766c-42.274-3.438-48.79-40.004-92.378-30.675 c-19.785,4.236-27.295,7.761-41.494,11.798c-38.885,11.052-57.418,4.05-66.845,1.419C7.842,40.211,7.609,40.217,7.546,40.238z',
    },
    {
      fill: '#1B365D',
      d:
        'M330.972,130.204h-11.797l0.012-39.119c0-1.571,0.424-3.108,1.547-4.235 c1.123-1.12,2.554-1.493,4.391-1.493h5.862L330.972,130.204z',
    },
    {
      fill: '#1B365D',
      d:
        'M211.752,130.192h-11.42l-0.004-25.425v-2.285c0-2.613-0.677-4.755-2.277-5.954 c-1.6-1.199-3.392-1.795-5.379-1.795c-1.987,0-3.753,0.596-5.3,1.795c-1.545,1.199-2.44,3.341-2.44,5.954l0.024,27.738 l-11.585-0.007l-0.015-26.327c0-7.401,2.423-12.416,6.121-15.229c3.485-2.646,8.035-4.142,12.78-4.36 c2.539,0,4.64,0.24,7.228,1.022c3.536,1.069,6.804,4.591,6.804,4.591s3.642-5.51,12.988-5.51c4.852,0,8.788,1.356,12.589,4.256 c3.804,2.903,7.16,7.657,7.16,15.275l-0.005,26.286l-5.768-0.005c-1.656,0-3.319-0.49-4.365-1.528 c-1.05-1.032-1.575-2.367-1.575-3.999v-22.203c0-2.613-0.94-4.681-2.542-5.879c-1.596-1.199-3.393-1.795-5.376-1.795 c-1.987,0-3.782,0.596-5.382,1.795c-1.6,1.199-2.261,3.266-2.261,5.879v2.285V130.192z',
    },
    {
      fill: '#1B365D',
      d:
        'M276.098,116.412l8.16,0.005c-1.268,4.718-3.952,8.452-7.516,10.931 c-3.449,2.399-7.848,3.501-13.263,3.501c-6.259,0-10.828-2.393-14.644-6.453c-3.76-4.007-5.615-9.693-5.615-16.793 c0-7.191,1.539-12.416,5.172-16.408c3.727-4.089,8.932-6.857,15.496-6.857c6.064,0,11.339,2.107,15.067,6.221 c4.27,4.718,5.816,9.72,5.947,17.489c0.01,0.499-0.069,2.428-0.069,2.497l-31.465-0.017c0.49,6.3,4.755,10.782,11.082,10.782 c5.427,0,6.498-1.822,8.569-3.704C273.712,116.976,274.492,116.412,276.098,116.412z M253.537,102.922l20.193,0.037 c0-4.554-3.693-9.393-9.768-9.393C258.012,93.566,254.002,98.007,253.537,102.922z',
    },
    {
      fill: '#1B365D',
      d:
        'M289.325,130.172l-0.015-19.483c0-0.557-0.413-7.443,0.995-11.801 c4.725-14.624,23.765-13.462,23.748-13.482l-0.025,11.367c0,0-13.058-0.97-13.058,8.152l-0.042,25.23L289.325,130.172z',
    },
    {
      fill: '#1B365D',
      d:
        'M5.269,111.776l11.267,0.027c0.702,3.668,1.585,4.846,3.55,6.232c2.411,1.7,4.569,2.306,8.735,2.257 c8.606-0.104,9.651-4.856,9.628-6.59c-0.149-11.717-32.005-4.167-32.91-24.731c-0.226-5.149,2.016-9.798,5.642-12.791 c3.626-2.885,7.791-5.311,14.797-5.391c6.569-0.082,11.579,1.305,15.357,3.972c4.447,3.14,7.723,8.706,8.005,13.965l-6.471,0.016 c-2.604,0-4.399-1.069-5.25-2.989c-0.468-1.057-1.221-2.165-2.369-2.988c-1.921-1.387-5.094-2.268-8.987-2.268 c-9.216,0-11.297,8.585-6.997,11.206c10.285,6.264,29.803,4.072,30.971,20.558c0.255,3.612-1.232,18.588-21.17,18.588 c-7.329,0-12.468-1.83-16.167-4.839C8.873,123.009,5.744,118.048,5.269,111.776z',
    },
    {
      fill: '#1B365D',
      d:
        'M93.276,85.377v24.558c0,12.604-5.363,20.778-19.064,20.778c-13.461,0-19.396-7.9-19.396-19.957 l0.033-25.335l11.611-0.04l0.024,26.426c0,6.622,3.504,8.433,7.221,8.433c3.625,0,8.284-2.061,8.284-8.48L81.955,91.11 c0-1.555,0.419-2.952,1.469-4.066c1.049-1.109,2.812-1.71,4.766-1.668H93.276z',
    },
    {
      fill: '#1B365D',
      d:
        'M98.734,78.889c0.007-2.088,0.595-6.012,5.604-5.991c0.142,0.001,6.147-0.007,6.147-0.007 l-0.011,57.35h-11.74V78.889z',
    },
    {
      fill: '#1B365D',
      d:
        'M140.08,72.916c-3.209,0-5.449,0.659-6.739,4.028c-0.539,1.519-18.972,53.288-18.972,53.288h12.041 l4.232-11.854l22.232-0.032l4.203,11.879h12.162l-20.508-57.308H140.08z M134.275,108.207l7.534-21.099l6.457,18.171l1.037,2.937 L134.275,108.207z',
    },
    {
      fill: '#1B365D',
      d:
        'M418.234,98.693c0-3.968-1.652-8.377-4.949-10.582c-3.192-2.09-7.139-3.778-13.516-3.778 c-5.698,0-9.9,1.632-13.268,4.319c-2.997,2.394-4.508,4.933-5.135,8.893l11.175,0.007c0.271-1.195,1.685-3.967,7.282-3.967 c2.588,0,7.197,0.857,7.197,4.382c0,2.31-2.565,3.694-7.573,4.355l-4.834,0.774c-4.329,0.659-6.906,1.481-9.716,3.729 c-2.676,2.14-4.874,5.018-4.874,9.648c0,4.956,2.188,8.772,5.262,10.92c3.074,2.149,7.501,3.878,16.366,3.204 c2.213-0.172,4.892-0.964,6.863-2.116c0.356,0.58,1.179,1.919,2.962,1.919c0.731,0,6.791,0,6.791,0L418.234,98.693z  M397.988,122.124c-1.504,0-4.155-0.589-5.486-1.894c-1.017-0.997-1.594-2.427-1.594-3.692c0-1.211,0.336-2.794,1.315-3.798 c1.104-1.142,2.23-1.459,4.33-1.913l3.918-0.714c3.369-0.61,5.277-1.021,6.794-2.246v4.492 C407.266,115.445,408.293,122.124,397.988,122.124z',
    },
    {
      fill: '#1B365D',
      d:
        'M276.219,72.819l-7.821,7.81c0,0-0.752,0.883-2.483,0.883c-2.863,0-5.261,0-5.261,0l4.392-6.596 c0.594-0.802,0.823-1.263,1.328-1.591c0.584-0.382,1.002-0.507,1.994-0.507H276.219z',
    },
    {
      fill: '#1B365D',
      d:
        'M331.002,81.618h-11.764l0.005-3.289c0-1.516,0.558-2.815,1.68-3.898 c1.12-1.078,2.465-1.622,4.028-1.622h6.053L331.002,81.618z',
    },
    {
      fill: '#1B365D',
      d:
        'M369.909,116.347l6.988-0.002c-0.88,4.422-5.709,14.423-20.158,14.423 c-15.331,0-21.091-13.09-21.091-23.051c0-14.997,10.357-23.557,21.237-23.557c6.007,0,11.357,2.028,15.181,6.046 c2.67,2.801,3.969,7.219,3.969,7.219l-10.907,0.019c0,0-1.569-3.967-7.697-3.967c-4.813,0-11.699,1.997-11.699,13.738 c0,3.098,1.044,13.926,11.555,13.926c1.919,0,4.309-0.087,6.663-1.991C366.321,117.233,365.941,116.332,369.909,116.347z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M239.274,148.105c5.943,0,9.187,4.203,9.187,8.81c0,5.795-3.391,9.245-9.261,9.242h-6.18v11.396 h-4.685v-29.449H239.274z M233.019,161.99h6.126c3.123,0,4.821-1.727,4.821-5.032c0-2.578-1.669-4.7-4.761-4.7h-6.186V161.99z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M254.732,158.796c1.373-1.977,3.211-2.682,5.743-2.682c0.763,0,1.496,0.072,2.18,0.25l-0.091,3.645 c-0.878-0.199-1.655-0.32-2.332-0.32c-0.288,0-0.559,0.022-0.81,0.069c-2.52,0.184-3.849,1.806-3.849,4.876v12.921h-4.492v-21.339 h3.467L254.732,158.796z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M264.446,164.162c0-5.006,3.106-8.057,8.615-8.057c5.492,0,8.028,3.214,8.028,8.002v4.534h-12.277 v2.224c0,2.606,1.726,3.677,4.167,3.677c1.787,0,3.454-0.557,4.697-1.517l2.489,1.991c-1.632,1.738-4.046,2.957-7.536,2.957 c-5.694,0-8.183-3.445-8.183-7.582V164.162z M276.882,163.97c0-3.201-1.821-4.339-3.887-4.339c-2.278,0-4.054,1.158-4.054,4.393 v1.742h7.942V163.97z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M289.454,177.551l-7.035-21.071h4.574l5.003,16.126l5.022-16.126h4.563l-7.025,21.071H289.454z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M309.011,150.098c0,1.253-0.886,2.414-2.378,2.414c-1.371,0-2.415-1.043-2.415-2.414 c0-1.493,1.161-2.378,2.415-2.378C308.003,147.72,309.011,148.727,309.011,150.098z M304.366,177.551V156.48h4.495v21.071H304.366z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M326.117,177.553l-0.11-1.094c-1.509,0.947-3.503,1.43-5.467,1.43c-3.994,0-8.046-1.991-8.046-7.326 v-6.982c0-5.618,3.952-7.432,7.714-7.432c1.722,0,3.524,0.417,4.88,0.994v-9.039h4.496v29.449H326.117z M325.087,160.369 c-1.031-0.413-2.546-0.746-4.048-0.746c-2.664,0-4.051,1.183-4.051,4.266v6.713c0.023,2.46,1.513,3.782,4.237,3.782 c1.386,0,2.744-0.288,3.861-0.803V160.369z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M333.067,164.162c0-5.006,3.105-8.057,8.616-8.057c5.492,0,8.029,3.214,8.029,8.002v4.534h-12.277 v2.224c0,2.606,1.726,3.677,4.167,3.677c1.787,0,3.454-0.557,4.697-1.517l2.489,1.991c-1.632,1.738-4.047,2.957-7.536,2.957 c-5.695,0-8.184-3.445-8.184-7.582V164.162z M345.091,153.446l-3.541-4.036l-3.623,4.036h-3.646l5.203-5.968h4.161l5.102,5.968 H345.091z M345.503,163.97c0-3.201-1.82-4.339-3.887-4.339c-2.278,0-4.053,1.158-4.053,4.393v1.742h7.941V163.97z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M356.558,157.571c1.634-0.949,3.639-1.42,5.569-1.42c4.037,0,7.944,2.071,7.944,7.376v14.027h-4.494 v-13.737c0-2.92-1.424-4.193-4.143-4.193c-1.41,0-2.817,0.296-3.957,0.801v17.13h-4.496v-21.076h3.467L356.558,157.571z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M373.332,164.725c0-5.459,3.204-8.62,8.601-8.62c3.545,0,5.737,1.537,6.999,3.872l-3.593,1.417 c-0.887-1.091-2.056-1.643-3.406-1.643c-2.531,0-4.108,1.521-4.108,4.974v4.581c0,3.454,1.576,4.975,4.108,4.975 c1.35,0,2.519-0.552,3.406-1.644l3.593,1.417c-1.262,2.337-3.454,3.879-6.999,3.879c-5.397,0-8.601-3.166-8.601-8.626V164.725z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M397.238,150.098c0,1.253-0.885,2.414-2.377,2.414c-1.371,0-2.415-1.043-2.415-2.414 c0-1.493,1.16-2.378,2.415-2.378C396.231,147.72,397.238,148.727,397.238,150.098z M392.595,177.551V156.48h4.495v21.071H392.595z',
    },
    {
      fill: '#DC6B2F',
      d:
        'M401.635,158.865c1.45-1.474,3.663-2.742,7.051-2.742c7.233,0,8.937,4.103,8.937,8.783v12.689 h-3.467l-0.11-1.081c-1.509,0.946-3.503,1.418-5.467,1.418c-4.204-0.042-8.04-1.956-8.04-7.265c0-5.565,4.359-6.914,8.506-6.914 c1.314,0,2.75,0.152,4.083,0.346c-0.037-2.369-0.888-4.338-4.859-4.338c-1.631,0-3.123,0.473-4.374,1.285L401.635,158.865z  M413.127,167.164c-1.118-0.147-2.286-0.268-3.381-0.268c-2.749,0-5.061,0.755-5.061,3.771c0,2.872,2.093,3.772,4.673,3.772 c1.337,0,2.726-0.342,3.77-0.901V167.164z',
    },
  ],
};
