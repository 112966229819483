import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  ConfirmDataInput,
  ConfirmDataMutation,
} from '../../../__generated__/ConfirmDataMutation.graphql';

const mutation = graphql`
  mutation ConfirmDataMutation($input: ConfirmDataInput!) {
    confirmData(input: $input) {
      valid
      response {
        status
        ...Response_message
      }
      clientMutationId
    }
  }
`;

export const confirmData = async (input: ConfirmDataInput) =>
  CommitMutation<ConfirmDataMutation>({
    mutation,
    variables: { input },
  });
