import styled from 'styled-components';
import { Flex, SvgStyle } from '@captalys-platform/core';

export const FlexStyle = styled(Flex)`
  background-color: ${props => props.theme.colors.white};
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.blue};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    z-index: 1032;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

export const WrapperLogoSulAmerica = styled(Flex)`
  ${SvgStyle} {
    > g {
      transform: scale(0.4);
    }
  }
`;
