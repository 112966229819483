import {
  DefaultTextInterface,
  DefaultFlexInterface,
} from '@captalys-platform/core';

export const loadingProps: DefaultFlexInterface = {
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
};

export const loadingTextProps: DefaultTextInterface = {
  component: 'H1TextStyle',
  color: 'white',
};
