import styled from 'styled-components';
import { Flex, Paper, DefaultTextStyle } from '@captalys-platform/core';
import { feedbackProps } from './props';
import { FeedbackInterface } from './interface';

export const FeedbackStyle = styled(Flex).attrs(feedbackProps)<
  FeedbackInterface
>`
  background-color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: ${({ theme, dark }) =>
      dark ? theme.colors.primary : theme.colors.white};
  }

  ${Paper} {
    ${DefaultTextStyle} {
      text-align: center;
    }
  }
`;
