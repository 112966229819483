import React from 'react';
import { StyledSpinner } from './style';

export const Spinner = ({ dark }: { dark: boolean }) => (
  <StyledSpinner dark={dark} viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
);
