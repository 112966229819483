import React from 'react';
import { InputRangeStyle } from './style';
import { InputRangeInterface } from './interface';

export const InputRangeComponent = ({
  onChangeEnd: handleChangeEnd,
  onChange: handleChange,
  value,
  min,
  max,
  step,
}: InputRangeInterface) => {
  const convertToInt = React.useCallback((n?: number | string) => {
    if (!n) {
      return 0;
    }

    if (typeof n === 'number') {
      return n;
    }

    return +n;
  }, []);

  const getPercentage = React.useCallback(
    (val: number | string) =>
      (convertToInt(val) - convertToInt(min)) /
      (convertToInt(max) - convertToInt(min)),
    [min, max],
  );

  return (
    <InputRangeStyle
      type="range"
      min={min}
      max={max}
      percentBar={getPercentage(value)}
      value={value}
      step={step}
      onChange={event => handleChange(event.target.value)}
      onMouseUp={event => handleChangeEnd(Number(event.currentTarget.value))}
      onTouchEnd={event => handleChangeEnd(Number(event.currentTarget.value))}
    />
  );
};
