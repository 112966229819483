import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { login } from '../../Relay/Mutations';
import { MutationID } from '../../Relay/enviroment';
import {
  USER_SESSION_TOKEN,
  USER_REFRESH_TOKEN,
  ROUTES_PATH,
} from '../../constants';
import { LoadingComponent } from '../../Components/Loading';

export const LoginPage = () => {
  const { push } = useHistory();

  const [isLoadingLogin, setIsLoadingLogin] = useState(true);
  const authenticationCode = useLocation().search;

  const makeLogin = async () => {
    try {
      const {
        integratedLogin: { response, user },
      } = await login({
        code: authenticationCode.slice(6),
        clientMutationId: MutationID,
        integration: 'SULAMERICA',
      });

      if (response.statusCode === 510) {
        push(ROUTES_PATH.ERROR, {
          text:
            'Sua contratação do SulAmérica SOSPrev não pôde ser concluída porque identificamos dados desatualizados. Por favor, ligue na Central para atualizá-los:',
        });
        return;
      }

      if (response.status) {
        window.localStorage.setItem(USER_SESSION_TOKEN, user.sessionToken);
        window.localStorage.setItem(USER_REFRESH_TOKEN, user.refreshToken);

        push(ROUTES_PATH.WELCOME);

        setIsLoadingLogin(false);
      } else {
        push(ROUTES_PATH.ERROR);
      }
    } catch (err) {
      push(ROUTES_PATH.ERROR);
    }
  };

  useEffect(() => {
    /**
     * Antes de tudo, consideramos se existe o parâmetro code.
     * Se existir, então desconsideramos o localstorage e realizamos
     * uma nova sessão
     */
    if (authenticationCode.includes('code')) {
      window.localStorage.removeItem(USER_SESSION_TOKEN);
      window.localStorage.removeItem(USER_REFRESH_TOKEN);
      makeLogin();
      return undefined;
    }
    /**
     * Caso o navegador já tenha credenciais gravadas,
     * tentamos o login com essas credenciais.
     *
     * Caso o usuário não tenha nem credencial nem 'code'
     * então enviamos para a tela de erro.
     */
    if (
      window.localStorage.getItem(USER_SESSION_TOKEN) &&
      window.localStorage.getItem(USER_REFRESH_TOKEN)
    ) {
      push(ROUTES_PATH.CREDIT);
    } else {
      push(ROUTES_PATH.ERROR);
    }
    return undefined;
  }, [authenticationCode]);

  if (isLoadingLogin) {
    return <LoadingComponent />;
  }

  return <></>;
};
