/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PaymentStatusEnum = "ERROR" | "NO_FUNDS" | "PAID" | "RESERVED";
export type Table_operationStatus = {
    readonly installments: ReadonlyArray<{
        readonly index: number | null;
        readonly paymentStatus: PaymentStatusEnum | null;
        readonly paymentDate: string | null;
        readonly installmentValue: number | null;
    } | null> | null;
    readonly " $refType": "Table_operationStatus";
};
export type Table_operationStatus$data = Table_operationStatus;
export type Table_operationStatus$key = {
    readonly " $data"?: Table_operationStatus$data;
    readonly " $fragmentRefs": FragmentRefs<"Table_operationStatus">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "Table_operationStatus",
  "type": "Operation",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "installments",
      "storageKey": null,
      "args": null,
      "concreteType": "Installment",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "index",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "paymentStatus",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "paymentDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "installmentValue",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '4c06dad370e5769cb7767857b09039d6';
export default node;
