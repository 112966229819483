import React, { useState } from 'react';
import { Flex, Text } from '@captalys-platform/core';

import { ChosenValueStyle, SliderWrapperStyle } from './style';
import { SliderProps } from './interface';
import { getValue } from './props';

import { InputRangeComponent } from '../InputRange';

export const Slider = ({
  minVolume,
  maxVolume,
  onChangeRangeEnd,
  starterValue,
  step,
  formatter,
}: SliderProps) => {
  const [localSliderValue, setlocalSliderValue] = useState(
    starterValue || (minVolume + maxVolume) / 2,
  );

  return (
    <SliderWrapperStyle justifyContent="center" width="100%" padding="0 32px">
      <ChosenValueStyle
        value={
          formatter ? formatter(localSliderValue) : String(localSliderValue)
        }
        onChange={e => {
          const { value } = e.target;

          const formattedValue = getValue(value);

          setlocalSliderValue(formattedValue);
          onChangeRangeEnd(formattedValue);
        }}
        onBlur={() => {
          const isAboveMax = localSliderValue > maxVolume;
          const isBellowMin = localSliderValue < minVolume;

          if (isAboveMax) {
            setlocalSliderValue(maxVolume);
            onChangeRangeEnd(maxVolume);
          }

          if (isBellowMin) {
            setlocalSliderValue(minVolume);
            onChangeRangeEnd(minVolume);
          }
        }}
        type="tel"
      />

      <InputRangeComponent
        min={minVolume}
        max={maxVolume}
        step={step || (maxVolume - minVolume) / 100}
        value={localSliderValue}
        onChange={setlocalSliderValue}
        onChangeEnd={onChangeRangeEnd}
      />

      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="8px 0"
      >
        <Text text={formatter ? formatter(minVolume) : String(minVolume)} />
        <Text text={formatter ? formatter(maxVolume) : String(maxVolume)} />
      </Flex>
    </SliderWrapperStyle>
  );
};
