import { CloseIcon } from '@captalys-platform/core';

import { SAPaymentStatus } from '../../__generated__/StatementQuery.graphql';
import { SuccessIcon, ExclamationIcon } from '../../Icons';

import { GetStatusInfoType } from './interface';

export const getStatusInfo = (status: SAPaymentStatus) => {
  const colors: Record<SAPaymentStatus, GetStatusInfoType> = {
    PENDING: {
      color: 'warning',
      text: 'Pendente',
      icon: ExclamationIcon,
    },
    PAID: { color: 'success', text: 'Pago', icon: SuccessIcon },
    OVERDUE: { color: 'danger', text: 'Vencido', icon: CloseIcon },
  };

  return colors[status];
};

export const formatDate = (date: string) => {
  const splitDate = date.split('-');

  return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
};
