import React, { useState } from 'react';
import { graphql } from 'relay-runtime';
import { useHistory } from 'react-router-dom';
import { createBreakpoints, Text } from '@captalys-platform/core';

import {
  TopbarStyle,
  WrapperTopbarStyle,
  WrapperLogoSulAmerica,
  DesktopFlexStyle,
} from './style';

import { NavbarComponent } from '../Navbar';
import { ROUTES_PATH } from '../../constants';
import { BarsIcon, LogoSulAmerica, LogoSulAmericaWhite } from '../../Icons';
import { Environment } from '../../Relay/enviroment';
import { useWindowWidth, useQuery } from '../../Utils';
import { checkpointsIncludes } from '../../Helpers';
import { componentTopbarQueryResponse } from '../../__generated__/componentTopbarQuery.graphql';

type AuthenticatedHeaderProps = {
  hideBars: boolean;
  menu: boolean;
  setMenu: (status: boolean) => void;
};

const AuthenticatedHeader = ({
  hideBars,
  menu,
  setMenu,
}: AuthenticatedHeaderProps) => {
  const { data, isLoading } = useQuery<componentTopbarQueryResponse>(
    graphql`
      query componentTopbarQuery {
        viewer {
          checkpoints
        }
      }
    `,
    Environment,
  );

  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const closeMenu = (value: boolean) => setMenu(value);
  const { push } = useHistory();

  const shouldDisableProfileButton = () => {
    if (isLoading) return false;

    if (data.viewer === null) return false;

    const {
      viewer: { checkpoints },
    } = data;

    const checkIsDone = checkpointsIncludes(checkpoints);

    if (checkIsDone('PROPOSTA_ESCOLHIDA')) return true;

    return false;
  };

  return (
    <>
      {!hideBars && isMobile && menu && (
        <NavbarComponent onCloseMenu={closeMenu}>
          {shouldDisableProfileButton() && (
            <Text
              style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              color="blue"
              onClick={() => push(ROUTES_PATH.INFO)}
              text="Meus Dados"
            />
          )}
        </NavbarComponent>
      )}
      {!hideBars && !isMobile && (
        <DesktopFlexStyle gap={20} direction="row" alignItems="center">
          {shouldDisableProfileButton() && (
            <Text
              style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              color="blue"
              onClick={() => push(ROUTES_PATH.INFO)}
              text="Meus Dados"
            />
          )}
        </DesktopFlexStyle>
      )}
    </>
  );
};

type TopbarComponentProps = {
  hideBars?: boolean;
  authenticated?: boolean;
};

export const TopbarComponent = ({
  hideBars,
  authenticated,
}: TopbarComponentProps): JSX.Element => {
  const [menu, setMenu] = useState(false);
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;

  return (
    <WrapperTopbarStyle
      area="Header"
      as="header"
      width="100%"
      height="auto"
      alignItems="center"
    >
      <TopbarStyle
        direction={isMobile ? 'row-reverse' : 'row'}
        alignItems="center"
        justifyContent="space-between"
        padding="0 16px"
        width="100%"
        container
        margin="0 auto"
      >
        {!hideBars && isMobile && (
          <BarsIcon fill="primary" onClick={() => setMenu(true)} />
        )}
        <WrapperLogoSulAmerica
          width="100%"
          height="100%"
          alignItems={isMobile ? 'center' : 'baseline'}
          justifyContent="center"
        >
          {isMobile ? (
            <LogoSulAmerica width="115px" height="60px" />
          ) : (
            <LogoSulAmericaWhite width="190px" height="70px" />
          )}
        </WrapperLogoSulAmerica>

        {authenticated ? (
          <AuthenticatedHeader
            hideBars={hideBars}
            menu={menu}
            setMenu={setMenu}
          />
        ) : null}
      </TopbarStyle>
    </WrapperTopbarStyle>
  );
};

TopbarComponent.defaultProps = {
  hideBars: false,
  authenticated: false,
};
