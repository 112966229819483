/* tslint:disable */
/* eslint-disable */
/* @relayHash c4af9aa5f6e0f1c042166d48b524aa8b */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DestinationEnum = "INFO_DOCS" | "PROPOSAL" | "SIMULATION" | "TERMS";
export type Partner = "ALELO" | "B2W" | "GOOGLE" | "MICROSOFT" | "PAYPAL" | "SULAMERICA";
export type CreateRecordInput = {
    destination: DestinationEnum;
    integration: Partner;
    sourceUrl: string;
    phone?: string | null;
    simulation?: SimulationInput | null;
    offer?: OfferInput | null;
    clientMutationId: string;
};
export type SimulationInput = {
    chosenVolume: number;
    values: Array<SimulationValuesInput>;
};
export type SimulationValuesInput = {
    amountDue: number;
    dailyCost: number;
    deadline: number;
};
export type OfferInput = {
    dailyCost: number;
    chosenVolume: number;
    deadline: number;
    amountDue: number;
    processingCost?: number | null;
    fixedCost?: number | null;
};
export type CreateRecordMutationVariables = {
    input: CreateRecordInput;
};
export type CreateRecordMutationResponse = {
    readonly createRecord: {
        readonly clientMutationId: string;
        readonly response: {
            readonly status: boolean;
            readonly " $fragmentRefs": FragmentRefs<"Response_message">;
        } | null;
    } | null;
};
export type CreateRecordMutation = {
    readonly response: CreateRecordMutationResponse;
    readonly variables: CreateRecordMutationVariables;
};



/*
mutation CreateRecordMutation(
  $input: CreateRecordInput!
) {
  createRecord(input: $input) {
    clientMutationId
    response {
      status
      ...Response_message
    }
  }
}

fragment Response_message on Response {
  status
  statusCode
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateRecordInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateRecordMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createRecord",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRecordPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "Response_message",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateRecordMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createRecord",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRecordPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "statusCode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateRecordMutation",
    "id": null,
    "text": "mutation CreateRecordMutation(\n  $input: CreateRecordInput!\n) {\n  createRecord(input: $input) {\n    clientMutationId\n    response {\n      status\n      ...Response_message\n    }\n  }\n}\n\nfragment Response_message on Response {\n  status\n  statusCode\n  message\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ae5b4e9f8553811433e96968a3f2aa78';
export default node;
