import React from 'react';
import { useHistory } from 'react-router';
import { QueryRenderer } from 'react-relay';

import { graphql } from 'relay-runtime';
import { CreditChooseInstallmentsComponent } from './component';
import { getItemOnSessionStorage } from '../../Helpers';
import { SESSION_STORAGE_KEYS, ROUTES_PATH } from '../../constants';
import { CreditChooseInstallmentsProps } from './interface';
import { Environment } from '../../Relay/enviroment';
import { ErrorComponent } from '../../Components/Error';
import { LoadingComponent } from '../../Components/Loading';

import { CreditChooseInstallmentsQuery } from '../../__generated__/CreditChooseInstallmentsQuery.graphql';

export * from './interface';

export const CreditChooseInstallments = ({
  chosenVolume,
}: CreditChooseInstallmentsProps) => {
  const { push } = useHistory();

  const sessionStorageChosenVolume: number = getItemOnSessionStorage(
    SESSION_STORAGE_KEYS.offer.chosenVolume,
  );

  if (!sessionStorageChosenVolume) push(ROUTES_PATH.CREDIT);

  const getChosenVolume = () => sessionStorageChosenVolume || chosenVolume;

  const creditChooseInstallQuery = graphql`
    query CreditChooseInstallmentsQuery($chosenVolume: Int) {
      viewer {
        checkpoints
        simulation {
          offers(chosenVolume: $chosenVolume) {
            id
            amountDue
            deadline
            tax
            cet
            installmentValue
          }
        }
      }
    }
  `;

  return (
    <QueryRenderer<CreditChooseInstallmentsQuery>
      environment={Environment}
      fetchPolicy="store-and-network"
      variables={{
        chosenVolume: Math.floor(getChosenVolume()),
      }}
      query={creditChooseInstallQuery}
      render={({ props, error }) => {
        if (error) return <ErrorComponent error={error} />;

        if (!props || !props?.viewer) return <LoadingComponent />;

        const simulation = props?.viewer?.simulation;

        if (!simulation) return <ErrorComponent error={{}} />;

        const checkpoints = props?.viewer?.checkpoints || [];
        const offers = simulation?.offers || [];
        const minInstallments = offers?.[0].deadline || 0;
        const maxInstallments = offers.slice(-1)?.[0].deadline || 127;

        return (
          <CreditChooseInstallmentsComponent
            chosenVolume={getChosenVolume()}
            checkpoints={checkpoints}
            minInstallments={minInstallments}
            maxInstallments={maxInstallments}
            offers={offers}
          />
        );
      }}
    />
  );
};
