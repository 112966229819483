/* tslint:disable */
/* eslint-disable */
/* @relayHash 084e4dee63726ab809ec687fd22459c5 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Partner = "ALELO" | "B2W" | "GOOGLE" | "MICROSOFT" | "PAYPAL" | "SULAMERICA";
export type IntegratedLoginInput = {
    code: string;
    integration: Partner;
    clientMutationId: string;
};
export type LoginMutationVariables = {
    input: IntegratedLoginInput;
};
export type LoginMutationResponse = {
    readonly integratedLogin: {
        readonly user: {
            readonly sessionToken: string | null;
            readonly refreshToken: string | null;
            readonly apiToken: string | null;
            readonly email: string | null;
            readonly id: string;
            readonly internalId: string | null;
            readonly name: string | null;
        } | null;
        readonly response: {
            readonly status: boolean;
            readonly statusCode: number;
            readonly " $fragmentRefs": FragmentRefs<"Response_message">;
        };
    } | null;
};
export type LoginMutation = {
    readonly response: LoginMutationResponse;
    readonly variables: LoginMutationVariables;
};



/*
mutation LoginMutation(
  $input: IntegratedLoginInput!
) {
  integratedLogin(input: $input) {
    user {
      sessionToken
      refreshToken
      apiToken
      email
      id
      internalId
      name
    }
    response {
      status
      statusCode
      ...Response_message
    }
  }
}

fragment Response_message on Response {
  status
  statusCode
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "IntegratedLoginInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "user",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sessionToken",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "refreshToken",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "apiToken",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "internalId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusCode",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LoginMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "integratedLogin",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "IntegratedLoginPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "Response_message",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LoginMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "integratedLogin",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "IntegratedLoginPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "LoginMutation",
    "id": null,
    "text": "mutation LoginMutation(\n  $input: IntegratedLoginInput!\n) {\n  integratedLogin(input: $input) {\n    user {\n      sessionToken\n      refreshToken\n      apiToken\n      email\n      id\n      internalId\n      name\n    }\n    response {\n      status\n      statusCode\n      ...Response_message\n    }\n  }\n}\n\nfragment Response_message on Response {\n  status\n  statusCode\n  message\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '5ccdf523fd0f52a5fdf95e74ab534f95';
export default node;
