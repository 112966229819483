import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { formatToPhone } from 'brazilian-values';
import {
  Text,
  Flex,
  createBreakpoints,
  Link,
  ArrowLeftIcon,
  CloseIcon,
} from '@captalys-platform/core';

import { ButtonStyle, PaperStyle, CloseLinkStyle } from './style';

import { useWindowWidth, useLockBody } from '../../Utils';
import { ROUTES_PATH } from '../../constants';
import { InputConfirmationCode } from '../InputConfirmationCode';
import { ToastComponent } from '../Toast';
import {
  genVerificationCode,
  verifyCode,
  saveCheckpoint,
} from '../../Relay/Mutations';
import { MutationID } from '../../Relay/enviroment';
import { Spinner } from '../Spinner';

const getToastData = (cellphone: string, isCodeInvalid = false) => {
  const invalidToast = {
    message: {
      title: 'Código Inválido',
      description: 'O código passado não é válido',
    },
    color: 'danger',
  };

  const resendToast = {
    message: {
      title: 'SMS Reenviado',
      description: `Reenviamos um SMS para ${cellphone}`,
    },
    color: 'success',
  };
  return isCodeInvalid ? invalidToast : resendToast;
};

export function SmsTokenConfirmation() {
  useLockBody();

  const isMobile = useWindowWidth() <= createBreakpoints().values.md;
  const { push } = useHistory();
  const [cellphone, setCellphone] = useState<string>(null);
  const [inputCode, setInputCode] = useState<string>(null);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [isGeneratingSmsCode, setIsGeneratingSmsCode] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);

  const formattedCellphone = formatToPhone(`${cellphone}`);
  const toastData = getToastData(formattedCellphone, isCodeInvalid);

  const generateSmsCode = async (isResending = false) => {
    try {
      setIsGeneratingSmsCode(true);

      const {
        genVerificationCode: { phone },
      } = await genVerificationCode({
        clientMutationId: MutationID,
        platform: 'PHONE',
      });

      setCellphone(`${phone.fullNumber}`);
    } finally {
      setIsGeneratingSmsCode(false);
      setIsToastOpen(isResending);
    }
  };

  useEffect(() => {
    generateSmsCode();
  }, []);

  const onSubmitClick = async () => {
    try {
      setIsSubmitting(true);

      const {
        verifyCode: {
          response: { status },
        },
      } = await verifyCode({
        clientMutationId: MutationID,
        code: inputCode,
        platform: 'PHONE',
      });

      if (!status) {
        // We don't use on finally because of error "don't update component after unmount"
        setIsCodeInvalid(true);
        setIsToastOpen(true);
        setIsSubmitting(false);

        return;
      }

      await saveCheckpoint({
        clientMutationId: MutationID,
        checkpoint: 'ANALISE',
      });

      push(ROUTES_PATH.THANK_YOU);
    } catch (err) {
      setIsToastOpen(true);
      setIsSubmitting(false);
    }
  };

  const onInputCodeChange = (code: string) => {
    setInputCode(code);
  };

  const isCodeComplete = () => inputCode && inputCode.length === 6;

  if (isGeneratingSmsCode) {
    return (
      <Flex height="70vh" alignItems="center" justifyContent="center">
        <Spinner dark={false} />
      </Flex>
    );
  }

  return (
    <>
      <Flex
        margin="0 0 32px 0"
        direction="row"
        alignItems="center"
        justifyContent={!isMobile ? 'flex-start' : 'center'}
        gap={12}
      >
        {!isMobile && (
          <Link color="blue" to={ROUTES_PATH.ORDER_REVIEW}>
            <ArrowLeftIcon width={14} height={14} fill="blue" />
          </Link>
        )}
        <Text component="H4TextStyle" text="Assinatura via Token SMS" />
      </Flex>
      <PaperStyle
        shadow={isMobile}
        justifyContent={isMobile ? 'space-between' : 'flex-start'}
        margin="0 0 32px 0"
      >
        {isMobile && (
          <CloseLinkStyle to={ROUTES_PATH.ORDER_REVIEW}>
            <CloseIcon width={18} height={18} fill="500" />
          </CloseLinkStyle>
        )}
        <Flex>
          <Text margin="0 0 10px 0">
            Enviamos um SMS para&nbsp;
            <Text component="StrongTextStyle">{formattedCellphone}</Text>
            &nbsp;insira o código recebido nos campos abaixo.
          </Text>
          <Text margin="0 0 30px 0">
            Caso não tenha recebido, procure em Mensagens Bloqueadas no menu
            Configurações do seu celular.
          </Text>
          <Text
            component="StrongTextStyle"
            text="Código de Confirmação"
            margin="0 0 10px 0"
            color="blue"
          />
          <InputConfirmationCode onCodeChange={onInputCodeChange} />

          <Text
            margin="12px 0 0 0"
            text="Se o seu telefone estiver incorreto, entrar em contato com a SulAmérica"
          />
        </Flex>

        <Flex gap={8} margin={isMobile ? '0 0 60px 0' : '60px 0 0 0'}>
          <ButtonStyle
            onClick={onSubmitClick}
            disabled={!isCodeComplete() || isSubmitting}
            text={isSubmitting ? 'Verificando token...' : 'Avançar'}
            full={isMobile}
            color="primary"
          />
          <ButtonStyle
            disabled={isGeneratingSmsCode}
            onClick={() => generateSmsCode(true)}
            text={isGeneratingSmsCode ? 'Reenviando...' : 'Reenviar SMS'}
            outline
            full={isMobile}
            color="primary"
          />
        </Flex>
      </PaperStyle>

      <ToastComponent
        message={{
          title: toastData.message.title,
          description: toastData.message.description,
        }}
        color={toastData.color}
        isOpen={isToastOpen}
        toggle={() => {
          setIsToastOpen(false);
        }}
      />
    </>
  );
}
