/* tslint:disable */
/* eslint-disable */
/* @relayHash f295731d092aacaae972fbf54c7530d6 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SexEnum = "FEMALE" | "MALE";
export type RepresentativeUpdateInput = {
    id: string;
    cpf: string;
    rg?: string | null;
    name?: string | null;
    email: string;
    participation?: number | null;
    birthDate?: string | null;
    maritalStatus?: string | null;
    sex?: SexEnum | null;
    motherName?: string | null;
    cellphone?: PhoneInput | null;
    address?: AddressInput | null;
    job?: string | null;
    clientMutationId: string;
};
export type PhoneInput = {
    phoneNumber: string;
    longDistanceCode: string;
};
export type AddressInput = {
    addressMain?: string | null;
    number?: number | null;
    complement?: string | null;
    typology?: string | null;
    zipcode?: string | null;
    neighborhood?: string | null;
    city?: string | null;
    stateInitials?: string | null;
    country?: string | null;
};
export type RepresentativeUpdateMutationVariables = {
    input: RepresentativeUpdateInput;
};
export type RepresentativeUpdateMutationResponse = {
    readonly representativeUpdate: {
        readonly clientMutationId: string;
        readonly response: {
            readonly status: boolean;
            readonly " $fragmentRefs": FragmentRefs<"Response_message">;
        } | null;
    } | null;
};
export type RepresentativeUpdateMutation = {
    readonly response: RepresentativeUpdateMutationResponse;
    readonly variables: RepresentativeUpdateMutationVariables;
};



/*
mutation RepresentativeUpdateMutation(
  $input: RepresentativeUpdateInput!
) {
  representativeUpdate(input: $input) {
    clientMutationId
    response {
      status
      ...Response_message
    }
  }
}

fragment Response_message on Response {
  status
  statusCode
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RepresentativeUpdateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientMutationId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RepresentativeUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "representativeUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RepresentativeUpdatePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "Response_message",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RepresentativeUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "representativeUpdate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RepresentativeUpdatePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "statusCode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "RepresentativeUpdateMutation",
    "id": null,
    "text": "mutation RepresentativeUpdateMutation(\n  $input: RepresentativeUpdateInput!\n) {\n  representativeUpdate(input: $input) {\n    clientMutationId\n    response {\n      status\n      ...Response_message\n    }\n  }\n}\n\nfragment Response_message on Response {\n  status\n  statusCode\n  message\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4457afe9da57b58712c32a1bd44ef4b8';
export default node;
