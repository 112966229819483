/* tslint:disable */
/* eslint-disable */
/* @relayHash 5718aae014f621471957fd7d2226d997 */

import { ConcreteRequest } from "relay-runtime";
export type CheckpointEnum = "ACEITE_PROPOSTA" | "ACEITE_TERMOS" | "ACOMPANHAMENTO" | "ANALISE" | "CONTRATO_GERADO" | "DECLINADO" | "DOCUMENTOS" | "PROPOSTA_ESCOLHIDA" | "SOBRE_EMPRESA" | "SOBRE_SOCIOS";
export type PaymentMethod = "BOLETO" | "DEBITO_AUTOMATICO";
export type OrderReviewQueryVariables = {};
export type OrderReviewQueryResponse = {
    readonly viewer: {
        readonly checkpoints: ReadonlyArray<CheckpointEnum | null> | null;
        readonly paymentMethod: {
            readonly method: PaymentMethod | null;
        };
        readonly me: {
            readonly name: string | null;
        } | null;
        readonly chosenOffer: {
            readonly amountDue: number | null;
            readonly tax: number | null;
            readonly hireValue: number | null;
            readonly iof: number | null;
            readonly fixedCost: number | null;
            readonly creditValue: number | null;
            readonly installmentValue: number | null;
            readonly deadline: number | null;
            readonly cet: number | null;
        } | null;
        readonly proposalData: {
            readonly planCode: string | null;
            readonly planName: string | null;
            readonly productCode: string | null;
            readonly warranty: number | null;
        } | null;
    } | null;
};
export type OrderReviewQuery = {
    readonly response: OrderReviewQueryResponse;
    readonly variables: OrderReviewQueryVariables;
};



/*
query OrderReviewQuery {
  viewer {
    checkpoints
    paymentMethod {
      method
    }
    me {
      name
      id
    }
    chosenOffer {
      amountDue
      tax
      hireValue
      iof
      fixedCost
      creditValue
      installmentValue
      deadline
      cet
      id
    }
    proposalData {
      planCode
      planName
      productCode
      warranty
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "checkpoints",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "paymentMethod",
  "storageKey": null,
  "args": null,
  "concreteType": "PaymentMethodPayload",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "method",
      "args": null,
      "storageKey": null
    }
  ]
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amountDue",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tax",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hireValue",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "iof",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fixedCost",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "creditValue",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "installmentValue",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deadline",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cet",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "proposalData",
  "storageKey": null,
  "args": null,
  "concreteType": "ProposalDataPayload",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "planCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "planName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "productCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "warranty",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrderReviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "chosenOffer",
            "storageKey": null,
            "args": null,
            "concreteType": "Offer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ]
          },
          (v12/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrderReviewQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v13/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "chosenOffer",
            "storageKey": null,
            "args": null,
            "concreteType": "Offer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/)
            ]
          },
          (v12/*: any*/),
          (v13/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrderReviewQuery",
    "id": null,
    "text": "query OrderReviewQuery {\n  viewer {\n    checkpoints\n    paymentMethod {\n      method\n    }\n    me {\n      name\n      id\n    }\n    chosenOffer {\n      amountDue\n      tax\n      hireValue\n      iof\n      fixedCost\n      creditValue\n      installmentValue\n      deadline\n      cet\n      id\n    }\n    proposalData {\n      planCode\n      planName\n      productCode\n      warranty\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd7e885b1602fefb7bdf7cb6f3645b769';
export default node;
