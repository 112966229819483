import { GridBreakpoints } from '@captalys-platform/core';
import moment from 'moment';

import { BankSlipListInterface } from './interface';

export const gridMedia = (): GridBreakpoints => {
  const xsArea = `
    "payment payment"
    "paidValue amountInDebt"
    "currentBankSlip currentBankSlip"
    "expiredBankSlip expiredBankSlip"
  `;

  const mdArea = `
    "operation payment paidValue amountInDebt ."
    "table table table . ."
    "table table table currentBankSlip currentBankSlip"
    "table table table expiredBankSlip expiredBankSlip"
    "table table table . ."
  `;

  return {
    xs: {
      rows: 'auto',
      columns: '50fr 50fr',
      columnGap: 16,
      rowGap: 16,
      areas: xsArea,
    },
    md: {
      columns: '20fr 20fr 20fr 20fr 20fr',
      rows: 'auto',
      columnGap: 32,
      rowGap: 32,
      areas: mdArea,
    },
  };
};

export const isInstallmentFromCurrentMonth = (dueDate: string) => {
  const date = moment(dueDate);
  return date.isSame(moment(), 'month') && date.isSame(moment(), 'year');
};

export const formatDate = (date: string) => {
  const splitDate = date.split('-');

  return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
};
