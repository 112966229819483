import { Text, Flex } from '@captalys-platform/core';
import styled from 'styled-components';

export const ContainerStyle = styled(Flex)`
  max-width: 425px;
  flex-direction: column;
`;

export const TitleTextStyle = styled(Text).attrs({
  component: 'H2TextStyle',
})<{
  isDesktop: boolean;
}>`
  color: ${({ theme, isDesktop }) =>
    isDesktop ? theme.colors.primary : theme.colors[300]};
`;

export const DescriptionTextStyle = styled(Text).attrs({
  component: 'H5TextStyle',
})<{
  isDesktop: boolean;
}>`
  color: ${({ theme, isDesktop }) =>
    isDesktop ? theme.colors.primary : theme.colors[300]};
  font-weight: normal;
`;

export const TextStyle = styled(Text)<{
  isDesktop: boolean;
}>`
  color: ${({ isDesktop, theme }) =>
    isDesktop ? theme.colors.primary : theme.colors[300]};
`;

export const LinkTextStyle = styled(Text)<{
  isDesktop: boolean;
  underline?: boolean;
}>`
  color: ${({ isDesktop, theme }) =>
    isDesktop ? theme.colors.information : theme.colors[300]};
  cursor: pointer;
  ${({ underline }) =>
    underline &&
    `
    text-decoration: underline;
  `}
`;
