import styled, { css } from 'styled-components';
import { Flex } from '@captalys-platform/core';

export const FlexStyle = styled(Flex)`
  left: 0;
  z-index: 99;
  top: 0;
`;

export const OverlayStyle = styled(Flex)`
  opacity: 0.8;
  left: 0;
  top: 0;
  z-index: 1;
  top: 100px;
`;

const mobileContainerStyleCss = css`
  left: 0;
  width: 100%;
  top: 99px;
`;

const desktopContainerStyleCss = css`
  right: 0;
  width: 500px;
  top: 116px;
`;

export const ContainerStyle = styled(Flex)<{
  isMobile: boolean;
}>`
  ${({ isMobile }) =>
    isMobile ? mobileContainerStyleCss : desktopContainerStyleCss}

  z-index: 1;
`;
