import {
  StatusCodeDataType,
  StatusCodeType,
  StatusCodeEnum,
} from './interface';

import { VerifyEmailQueryResponse } from '../../__generated__/VerifyEmailQuery.graphql';

export const getToastData = (
  data: VerifyEmailQueryResponse,
  statusCode: StatusCodeType = null,
) => {
  const email = data && data.viewer && data.viewer.me.email;

  const invalidToast = {
    message: {
      title: `Código ${statusCode === 'expired' ? 'Expirado' : 'Inválido'}`,
      description: `O código passado ${
        statusCode === 'expired' ? 'está expirado' : 'não é válido'
      }`,
    },
    color: 'danger',
  };

  const resendToast = {
    message: {
      title: 'Código Reenviado',
      description: `Reenviamos um código para ${email}`,
    },
    color: 'success',
  };
  return statusCode === null ? resendToast : invalidToast;
};

export const getSubmitButtonText = (isSubmitting: boolean) => {
  return isSubmitting ? 'Verificando código...' : 'Avançar';
};

export const getResendCodeButtonText = (isResending: boolean) => {
  return isResending ? 'Reenviando...' : 'Reenviar código';
};

export const getStatusCodeData = (statusCode: number): StatusCodeDataType => {
  const isExpired = statusCode === StatusCodeEnum.EXPIRED;

  const isInvalid = statusCode === StatusCodeEnum.INVALID;

  return {
    hasError: isExpired || isInvalid,
    errorType: isExpired ? 'expired' : 'invalid',
  };
};
