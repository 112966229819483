import React from 'react';
import { FooterStyle } from './style';

import { LogoSulAmericaAlternative } from '../../Icons';

export const FooterComponent = () => (
  <FooterStyle>
    <LogoSulAmericaAlternative width="126" height="53" />
  </FooterStyle>
);
