import React from 'react';
import { useHistory } from 'react-router';
import { Text, Flex } from '@captalys-platform/core';

import { CreditChooseVolumeProps } from './interface';
import { setItemOnSessionStorage } from '../../Helpers';
import { SESSION_STORAGE_KEYS, ROUTES_PATH } from '../../constants';
import { ButtonStyle } from '../Credit/style';

export const CreditChooseVolume = ({
  children,
  chosenVolume,
}: CreditChooseVolumeProps) => {
  const { push } = useHistory();

  setItemOnSessionStorage(
    SESSION_STORAGE_KEYS.offer.chosenVolume,
    chosenVolume,
  );

  return (
    <Flex height="100%" direction="column" justifyContent="space-between">
      <Flex>
        <Text
          align="center"
          component="H4TextStyle"
          text="Quanto você precisa?"
          margin="30px 0"
        />
        {children}
      </Flex>
      <Flex alignItems="center" gap={16}>
        <ButtonStyle
          color="primary"
          onClick={() => push(ROUTES_PATH.CREDIT_INSTALLMENTS)}
          text="Continuar"
        />
      </Flex>
    </Flex>
  );
};
