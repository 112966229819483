import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import { ErrorComponent } from '../../Components/Error';
import { LoadingComponent } from '../../Components/Loading';
import { Environment } from '../../Relay/enviroment';
import { DeclinedPageComponent } from './component';
import { DeclinedQuery } from '../../__generated__/DeclinedQuery.graphql';

export * from './component';

const declinedQuery = graphql`
  query DeclinedQuery {
    viewer {
      checkpoints
    }
  }
`;

export const DeclinedPage = () => {
  return (
    <QueryRenderer<DeclinedQuery>
      query={declinedQuery}
      // @ts-ignore
      environment={Environment}
      fetchPolicy="store-and-network"
      render={({ props, error }) => {
        if (error) {
          return <ErrorComponent error={error} />;
        }

        if (!props) {
          return <LoadingComponent />;
        }

        const checkpoints = props?.viewer?.checkpoints;

        return <DeclinedPageComponent checkpoints={checkpoints} />;
      }}
    />
  );
};
