import { breakpointsProps } from '@captalys-platform/core';

export const AppThemeProviderThemeProps = {
  colors: {
    primary: '#dc6b2f',
    secondary: '#d0541f',
    darker: '#c05131',
    withTransparency: 'rgba(220, 107, 47, 0.09)',
    blue: '#1b3671',
  },
  breakpoints: breakpointsProps,
};
