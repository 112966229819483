import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { ThankYouComponent } from './component';
import { Environment } from '../../Relay/enviroment';
import { LoadingComponent } from '../../Components/Loading';

import { ThankYouQuery } from '../../__generated__/ThankYouQuery.graphql';

const thankYouQuery = graphql`
  query ThankYouQuery {
    viewer {
      paymentMethod {
        method
      }
      checkpoints
      chosenOffer {
        amountDue
        tax
        hireValue
        iof
        fixedCost
        creditValue
        installmentValue
        deadline
        cet
      }
    }
  }
`;

export const ThankYouPage = () => (
  <QueryRenderer<ThankYouQuery>
    environment={Environment}
    fetchPolicy="store-and-network"
    variables={{}}
    query={thankYouQuery}
    render={({ error, props }) => {
      if (error || (props && !props.viewer)) {
        return null;
      }

      if (!props || !props.viewer) return <LoadingComponent />;

      const chosenOffer = props?.viewer?.chosenOffer || null;
      const paymentMethod = props?.viewer?.paymentMethod || null;
      const checkpoints = props?.viewer?.checkpoints || [];

      return (
        <ThankYouComponent
          chosenOffer={chosenOffer}
          checkpoints={checkpoints}
          paymentMethod={paymentMethod}
        />
      );
    }}
  />
);
