/* tslint:disable */
/* eslint-disable */
/* @relayHash 7b0ef8f63419697c9e6ec72df7704ddf */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DisbursementStatusEnum = "ACCOMPLISHED" | "AUTHORIZED" | "REFUSED";
export type VerifyOperationInput = {
    clientMutationId: string;
};
export type VerifyOperationMutationVariables = {
    input: VerifyOperationInput;
};
export type VerifyOperationMutationResponse = {
    readonly verifyOperation: {
        readonly disbursementStatus: DisbursementStatusEnum | null;
        readonly response: {
            readonly status: boolean;
            readonly statusCode: number;
            readonly " $fragmentRefs": FragmentRefs<"Response_message">;
        };
    };
};
export type VerifyOperationMutation = {
    readonly response: VerifyOperationMutationResponse;
    readonly variables: VerifyOperationMutationVariables;
};



/*
mutation VerifyOperationMutation(
  $input: VerifyOperationInput!
) {
  verifyOperation(input: $input) {
    disbursementStatus
    response {
      status
      statusCode
      ...Response_message
    }
  }
}

fragment Response_message on Response {
  status
  statusCode
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "VerifyOperationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "disbursementStatus",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusCode",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VerifyOperationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "verifyOperation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyOperationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "Response_message",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VerifyOperationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "verifyOperation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyOperationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "response",
            "storageKey": null,
            "args": null,
            "concreteType": "Response",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "VerifyOperationMutation",
    "id": null,
    "text": "mutation VerifyOperationMutation(\n  $input: VerifyOperationInput!\n) {\n  verifyOperation(input: $input) {\n    disbursementStatus\n    response {\n      status\n      statusCode\n      ...Response_message\n    }\n  }\n}\n\nfragment Response_message on Response {\n  status\n  statusCode\n  message\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'f84208c4c24e1300b5d6f2fb37f346f7';
export default node;
