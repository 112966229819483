import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import ContentLoader from 'react-content-loader';

import { ErrorComponent } from '../Error';
import { Environment } from '../../Relay/enviroment';
import { SidebarFragment } from './component';
import { SidebarStyle } from './style';

import { SidebarQuery } from '../../__generated__/SidebarQuery.graphql';

export * from './props';
export * from './interface';
export * from './style';

const sidebarQuery = graphql`
  query SidebarQuery {
    viewer {
      chosenOffer {
        amountDue
        deadline
        tax
        cet
        installmentValue
      }
      checkpoints
    }
  }
`;

export const SidebarComponent = () => (
  <QueryRenderer<SidebarQuery>
    environment={Environment}
    fetchPolicy="store-and-network"
    query={sidebarQuery}
    variables={{}}
    render={({ error, props }) => {
      if (error) {
        return <ErrorComponent error={error} />;
      }

      if (!props || !props.viewer) {
        return (
          <SidebarStyle>
            <ContentLoader
              height={326}
              width={300}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect
                x="30px"
                y="0px"
                rx="3"
                ry="3"
                width="140px"
                height="30px"
              />
              <rect
                x="40px"
                y="36px"
                rx="3"
                ry="3"
                width="222px"
                height="18px"
              />
              <rect
                x="40px"
                y="60px"
                rx="3"
                ry="3"
                width="222px"
                height="18px"
              />
              <rect
                x="40px"
                y="84px"
                rx="3"
                ry="3"
                width="222px"
                height="18px"
              />

              <rect
                x="30px"
                y="130px"
                rx="3"
                ry="3"
                width="140px"
                height="30px"
              />
              <rect
                x="40px"
                y="166px"
                rx="3"
                ry="3"
                width="222px"
                height="18px"
              />
              <rect
                x="40px"
                y="190px"
                rx="3"
                ry="3"
                width="222px"
                height="18px"
              />

              <rect
                x="30px"
                y="235px"
                rx="3"
                ry="3"
                width="140px"
                height="30px"
              />
            </ContentLoader>
          </SidebarStyle>
        );
      }

      const checkpoints = props?.viewer?.checkpoints || [];
      const chosenOffer = props?.viewer?.chosenOffer || null;

      return (
        <SidebarFragment checkpoints={checkpoints} chosenOffer={chosenOffer} />
      );
    }}
  />
);
