import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  CreateRecordInput,
  CreateRecordMutation,
} from '../../../__generated__/CreateRecordMutation.graphql';

const mutation = graphql`
  mutation CreateRecordMutation($input: CreateRecordInput!) {
    createRecord(input: $input) {
      clientMutationId
      response {
        status
        ...Response_message
      }
    }
  }
`;

export const createRecord = async (input: CreateRecordInput) =>
  CommitMutation<CreateRecordMutation>({
    mutation,
    variables: { input },
  });
