export const USER_SESSION_TOKEN = 'USER_SESSION_TOKEN';
export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN';
export const USER_IDENTIFIER = 'USER_IDENTIFIER';
export const SITE_NAME = 'Sulamerica';

export const REQUIRED_FIELD_ERROR = 'O campo é necessário';

export const ROUTES_PATH = {
  // LOGIN AND WELCOME
  ROOT: '/',
  WELCOME: '/welcome',

  // DECLINED
  DECLINED: '/declined',

  // CREDIT
  CREDIT: '/credit',
  CREDIT_INSTALLMENTS: '/credit/choose-installments',

  // STATEMENT
  STATEMENT: '/statement',

  // ORDER
  ORDER_REVIEW: '/order/review',
  ORDER_REVIEW_SMS: '/order/review/sms',

  // INFO
  INFO: '/info',
  INFO_INCORRECT: '/info/incorrect',
  INFO_VERIFY_EMAIL: '/info/verify-email',

  // OTHERS
  STATUS: '/status',
  THANK_YOU: '/thank-you',
  DASHBOARD: '/dashboard',
  ERROR: '/error',

  // NOT FOUND
  NOT_FOUND: '*',
};

export const CHECKPOINTS_REDIRECT = {
  ACEITE_TERMOS: ROUTES_PATH.CREDIT,
  PROPOSTA_ESCOLHIDA: ROUTES_PATH.STATUS,
  SOBRE_EMPRESA: ROUTES_PATH.STATUS,
  SOBRE_SOCIOS: ROUTES_PATH.STATUS,
  DOCUMENTOS: ROUTES_PATH.STATUS,
  ANALISE: ROUTES_PATH.THANK_YOU,
} as { [name: string]: string };

export const SESSION_STORAGE_KEYS = {
  offer: {
    chosenVolume: 'offer:chosenVolume',
  },
};

export const PAYMENT_STATUS = {
  PAID: 'PAID',
  NO_FUNDS: 'NO_FUNDS',
  RESERVED: 'RESERVED',
  ERROR: 'ERROR',
};

export const SALESFORCE_SOURCE_URLS = {
  CREDIT: 'https://pp.captalys.com.br/credit',
  INFO: 'https://pp.captalys.com.br/info',
  TERMS: 'https://pp.captalys.com.br/welcome',
};

export const TERMS_PDF =
  'https://front-jovian.s3.sa-east-1.amazonaws.com/files/Pol%C3%ADtica%20de%20Privacidade.pdf';

export const FINANCIAL_TERMS_PDF =
'https://front-jovian-whitelabel.s3-sa-east-1.amazonaws.com/sulamerica/GEJCP_Contrato+Assist%C3%AAncia+Financeira.pdf';

export const LGPD_TERMS_PDF =
'https://front-jovian.s3.sa-east-1.amazonaws.com/files/TC_Cl%C3%A1usula%20LGPD.pdf';
