import React from 'react';
import { Flex } from '@captalys-platform/core';

import { ErrorPage } from '../../Pages/Error';
import { TopbarComponent } from '../Topbar';
import { FooterComponent } from '../Footer';

export class ErrorBoundary extends React.Component<
  Record<string, unknown>,
  { hasError: boolean }
> {
  constructor(props: Record<string, unknown>) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex height="100vh">
          <TopbarComponent />
          <ErrorPage />
          <FooterComponent />
        </Flex>
      );
    }

    return this.props.children;
  }
}
