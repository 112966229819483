import React from 'react';
import { Flex, Text, createBreakpoints } from '@captalys-platform/core';
import { useLocation } from 'react-router';
import { useWindowWidth } from '../../Utils';

export function ErrorPage() {
  const isMobile = useWindowWidth() <= createBreakpoints().values.md;

  const { state } = useLocation<{ text: string }>();

  const phoneLocationCapital = '4004-4935 (capitais e metrópoles)';
  const phoneLocationInland = '0800 726 4935 (demais localidades)';

  return (
    <Flex
      width="100%"
      height="100%"
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent="center"
      gap={32}
      padding="32px"
    >
      {state && state.text ? (
        <Flex alignItems="flex-start" justifyContent="flex-start">
          <Text margin="0 0 20px 0" component="H4TextStyle" text={state.text} />
          <Text
            margin="0 0 6px 0"
            component="H4TextStyle"
            text={phoneLocationCapital}
          />
          <Text component="H4TextStyle" text={phoneLocationInland} />
        </Flex>
      ) : (
        <>
          <Text
            align={isMobile ? 'left' : 'center'}
            text="Encontramos um problema"
            component="H2TextStyle"
          />
          <Text
            align={isMobile ? 'left' : 'center'}
            text="Por favor tente entrar novamente através do link disponibilizado no portal da SulAmérica ou entre em contato com a SulAmérica."
          />
        </>
      )}
    </Flex>
  );
}
