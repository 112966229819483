import { graphql } from 'relay-runtime';
import { CommitMutation } from '../commitMutation';
import {
  SendEmailInput,
  welcomeMailMutation,
} from '../../../__generated__/welcomeMailMutation.graphql';

const mutation = graphql`
  mutation welcomeMailMutation($input: SendEmailInput!) {
    sendEmail(input: $input) {
      response {
        status
        ...Response_message
      }
      clientMutationId
    }
  }
`;

export const sendEmail = (input: SendEmailInput) =>
  CommitMutation<welcomeMailMutation>({
    mutation,
    variables: { input },
  });
