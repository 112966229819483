import styled, { css } from 'styled-components';
import { Flex, Input } from '@captalys-platform/core';

export const FlexStyle = styled(Flex)<{ hasLineBellow?: boolean }>`
  padding: ${({ hasLineBellow }) =>
    hasLineBellow ? '0 0 12px 0' : '0 0 6px 0'};
  border-bottom: ${({ theme, hasLineBellow }) =>
    hasLineBellow && `1px solid ${theme.colors[300]}`};
  margin: ${({ hasLineBellow }) => hasLineBellow && `0 0 12px 0`};
`;

export const RadioInputStyle = styled(Input)`
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  z-index: 1;
  opacity: 0;
`;

RadioInputStyle.displayName = 'input';

export const RadioStyle = styled(Flex)`
  --size: 20px;
  --checked-size: calc(var(--size) * 0.5);

  width: var(--size);
  height: var(--size);
  flex-shrink: 0;

  margin-right: 0.5rem;

  &::before {
    content: '';
    border-radius: 50%;
    display: inline-block;
    width: var(--checked-size);
    height: var(--checked-size);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 0;
  }
`;

function inputVariantStyle({ variant, theme }) {
  let color = theme.colors.information;

  if (variant === 'success') {
    color = theme.colors.success;
  }
  if (variant === 'error') {
    color = theme.colors.danger;
  }

  return css`
    color: ${color.dark};

    & > ${RadioStyle} {
      border: 2px solid ${color.dark};
      border-radius: 50%;

      &::before {
        background: ${color.base};
      }
    }

    &:hover > ${RadioStyle}, &:focus > ${RadioStyle} {
      border-color: ${color.action};
    }

    & > ${RadioInputStyle}:checked ~ ${RadioStyle} {
      border-color: ${color.base};
    }
  `;
}

function getDisabledStyle(theme) {
  return css`
    color: ${theme.colors['600']};

    & > ${RadioInputStyle}:disabled {
      cursor: default;

      & ~ ${RadioStyle} {
        background-color: ${theme.colors['200']};
        border-color: ${theme.colors['200']};

        &::before {
          background: ${theme.colors['300']};
        }
      }
    }
  `;
}

/**
 * I can't extend TypographyStyle here because "variant" prop conflicts
 * between CheckboxInterface & TypographyInterface
 */
export const RadioContainerStyle = styled.label<{ disabled: boolean }>`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0 2rem 4px 0;

  ${inputVariantStyle}
  ${({ theme, disabled }) => disabled && getDisabledStyle(theme)}

  /* Shows the checkmark when checked */
  &  ${RadioInputStyle}:checked ~ ${RadioStyle}::before {
    opacity: 1;
    background-color: ${({ theme, disabled }) =>
      theme.colors[disabled ? '600' : 'primary']}
  }
`;
